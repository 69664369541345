import * as tslib_1 from "tslib";
import { PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
var DatetimePipe = /** @class */ (function (_super) {
    tslib_1.__extends(DatetimePipe, _super);
    function DatetimePipe() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DatetimePipe.prototype.transform = function (value, args) {
        if (value == null)
            return "-";
        return _super.prototype.transform.call(this, value, "dd.MM.yyyy") + (args == null ? " kl. " + _super.prototype.transform.call(this, value, "HH:mm") : "");
    };
    return DatetimePipe;
}(DatePipe));
export { DatetimePipe };
