﻿// ********************************************************************
// ***                                                              ***
// ***            DO NOT MODIFY THIS FILE MANUALLY!                 ***
// *** This file was generated by the API2ANGULAR Service Generator ***
// ***                                                              ***
// ********************************************************************

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpParameterCodec } from '@angular/common/http';
import { environment } from '../../environments/environment';


export class HentMenyDto {
    public Tellere: TellereDto;
    public Profil: LoginCommandResult;
    public Hovedkontrakter: HovedkontraktDto[] = [];
}

export class TellereDto {
    public Alleantall: number;
    public Utkastantall: number;
    public Innboksantall: number;
    public Mineantall: number;
    public Maalebrevantall: number;
    public MenyClass: string;
    public InnboksDelt: any[] = [];
    public AlleDelt: any[] = [];
    public InnboksFristUtgaatt: boolean;
    public InnboksFristUtgaarIdag: boolean;
    public AlleMineAntall: number;
    public StartetAvBrukerAntall: number;
    public BehandletAvBrukerAntall: number;
}

export class LoginCommandResult {
    public Success: boolean;
    public Token: string;
    public Username: string;
    public Brukernavn: string;
    public Role: number;
    public Admin: boolean;
    public Leder: boolean;
    public OppdelInnboks: boolean;
    public OppdelAlle: boolean;
    public Impersonating: boolean;
    public LoggPaViaByggeweblogin: boolean;
    public VisRapportMalebrev: boolean;
    public VisRapportOppforinger: boolean;
    public VisRapportProsessdiagrammer: boolean;
    public VisRapportOversiktKravUE: boolean;
    public VisRapportOversikt: boolean;
    public Login: string;
    public Epost: string;
    public Prosjektadmin: boolean;
}

export class HovedkontraktDto {
    public id: number;
    public text: string;
}

export class WorkingEncoder implements HttpParameterCodec {
    encodeKey(key: string): string { return encodeURIComponent(key); }
    encodeValue(value: string): string { return encodeURIComponent(value); }
    decodeKey(key: string): string { return decodeURIComponent(key); }
    decodeValue(value: string): string { return decodeURIComponent(value); }
}

@Injectable({
   providedIn: "root"
})
export class MenuService {
    private serviceUrl = environment.APIUrl + 'Menu/';

    constructor(private http: HttpClient) { } 

    private addArray(params: HttpParams, key: string, value: any): HttpParams {
        if (typeof value === "undefined" || value === null) return params;
        if (Array.isArray(value) && value.length === 0) return params;
        for (let i = 0; i < value.length; i++) params = params.append(key + "[]", value[i].toString());
        return params;
    }

    public ChangeLanguage(lang: string): Promise<any> {
        let params = new HttpParams({ encoder: new WorkingEncoder() })
        params = params.set("lang",lang != null ? lang.toString() : null);
        return <Promise<any>>this.http.get(this.serviceUrl + "ChangeLanguage", { params, withCredentials: true }).toPromise();
    }

    public Hent(filterHovedkontraktId: number): Promise<HentMenyDto> {
        let params = new HttpParams({ encoder: new WorkingEncoder() })
        params = params.set("filterHovedkontraktId",filterHovedkontraktId != null ? filterHovedkontraktId.toString() : null);
        return <Promise<HentMenyDto>>this.http.get(this.serviceUrl + "Hent", { params, withCredentials: true }).toPromise();
    }

    public LogOut2(): Promise<any> {
        return <Promise<any>>this.http.get(this.serviceUrl + "LogOut2", { withCredentials: true }).toPromise();
    }
}
