﻿// ********************************************************************
// ***                                                              ***
// ***            DO NOT MODIFY THIS FILE MANUALLY!                 ***
// *** This file was generated by the API2ANGULAR Service Generator ***
// ***                                                              ***
// ********************************************************************

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpParameterCodec } from '@angular/common/http';
import { environment } from '../../environments/environment';


export class HentBrukerkontoDto {
    public Brukerkonto: BrukerkontoDto;
    public Brukergrupper: BrukergruppeDto[] = [];
    public Hovedkontrakter: HovedkontraktDto[] = [];
    public Firmaer: FirmaDto[] = [];
    public Hovedprosjekter: HovedprosjektDto[] = [];
    public Admin: boolean;
}

export class BrukerkontoDto {
    public Id: number;
    public Login: string;
    public Passord: string;
    public Navn: string;
    public Epost: string;
    public Epostvarsel: boolean;
    public KrevNyttPassord: boolean;
    public MottaEpostkvittering: boolean;
    public LoggPaViaByggeweblogin: boolean;
    public DagligEpostSamlerapport: boolean;
    public Opprettet: string;
    public Slettet: string;
    public Brukergrupper: number[] = [];
    public Kontrakter: KontraktDto[] = [];
    public FirmaId: number;
    public DagligVarslingUbehandlede: boolean;
    public Prosjektadmin: ProsjektadminDto[] = [];
    public SistAktiv: string;
}

export class BrukergruppeDto {
    public Id: number;
    public Navn: string;
    public Slettet: boolean;
}

export class HovedkontraktDto {
    public id: number;
    public text: string;
    public Kontraktsnr: string;
    public Kontraktstittel: string;
    public HovedprosjektId: number;
    public Roller: Select2Dto[] = [];
}

export class Select2Dto {
    public id: number;
    public text: string;
}

export class FirmaDto {
    public id: number;
    public text: string;
}

export class HovedprosjektDto {
    public Id: number;
    public Navn: string;
}

export class KontraktDto {
    public Id: number;
    public Kontraktsnr: string;
    public Tittel: string;
    public HovedkontraktId: number;
    public HovedkontraktKontraktsnr: string;
    public HovedkontraktKontraktstittel: string;
    public Opprettet: string;
    public Oppstart: string;
    public Utlopsdato: string;
    public RolleId: number;
    public Innboks: number;
    public Utlopt: boolean;
    public Brukerkonto: string;
    public Brukergruppe: string;
    public BrukergruppeId: number;
    public BrukerkontoId: number;
    public HovedprosjektId: number;
    public Hovedkontrakter: HovedkontraktDto[] = [];
    public GjeldendeKontraktsnr: string;
    public GjeldendeTittel: string;
    public IBruk: boolean;
    public OrginalHovedkontraktId: number;
    public OrginalGjeldendeKontraktsnr: string;
    public OrginalGjeldendeTittel: string;
    public OrginalOppstart: string;
    public OrginalUtlopsdato: string;
    public OrginalRolleId: number;
}

export class ProsjektadminDto {
    public Id: number;
    public HovedprosjektId: number;
    public Oppstart: string;
    public Utlopsdato: string;
    public Utlopt: boolean;
    public HovedprosjektListe: HovedprosjektDto[] = [];
}

export class WorkingEncoder implements HttpParameterCodec {
    encodeKey(key: string): string { return encodeURIComponent(key); }
    encodeValue(value: string): string { return encodeURIComponent(value); }
    decodeKey(key: string): string { return decodeURIComponent(key); }
    decodeValue(value: string): string { return decodeURIComponent(value); }
}

@Injectable({
   providedIn: "root"
})
export class AdminBrukerkontoRedigerService {
    private serviceUrl = environment.APIUrl + 'AdminBrukerkontoRediger/';

    constructor(private http: HttpClient) { } 

    private addArray(params: HttpParams, key: string, value: any): HttpParams {
        if (typeof value === "undefined" || value === null) return params;
        if (Array.isArray(value) && value.length === 0) return params;
        for (let i = 0; i < value.length; i++) params = params.append(key + "[]", value[i].toString());
        return params;
    }

    public Hent(brukerkontoId: number): Promise<HentBrukerkontoDto> {
        let params = new HttpParams({ encoder: new WorkingEncoder() })
        params = params.set("brukerkontoId",brukerkontoId != null ? brukerkontoId.toString() : null);
        return <Promise<HentBrukerkontoDto>>this.http.get(this.serviceUrl + "Hent", { params, withCredentials: true }).toPromise();
    }

    public Lagre(brukerkonto: BrukerkontoDto): Promise<number> {
        return <Promise<number>>this.http.post(this.serviceUrl + "Lagre", brukerkonto, { withCredentials: true }).toPromise();
    }

    public Slett(id: number): Promise<boolean> {
        let params = new HttpParams({ encoder: new WorkingEncoder() })
        params = params.set("id",id != null ? id.toString() : null);
        return <Promise<boolean>>this.http.get(this.serviceUrl + "Slett", { params, withCredentials: true }).toPromise();
    }
}
