<div>

    <div class="container" [ng-busy]="busy">
        <div class="panel panel-default">
            <div class="panel-heading">
                <div class="pull-right">
                    <label style="margin-right:0.5em">
                        <input type="checkbox" [(ngModel)]="slettede" id="VisSlettede" (change)="oppdater()" />&nbsp;<span i18n="@@app01Admin01FirmaOversikt1">Vis slettede</span>
                    </label>
                    <button id="BtnSearch" class="btn btn-default" type="button" style="margin-right:0.5em" (click)="sok()"><span class="glyphicon glyphicon-search"></span>&nbsp;<span i18n="@@app01Admin01FirmaOversikt15">Søk</span></button>
                    <button class="btn btn-primary pull-right" (click)="rediger(0, null)">
                        <span class="glyphicon glyphicon-plus-sign"></span>&nbsp;<span i18n="@@app01Admin01FirmaOversikt2">Opprett nytt</span>
                    </button>
                </div>
                <h1 i18n="@@app01Admin01FirmaOversikt3">Firmaer</h1>
            </div>
            <table class="table table-striped table-bordered" *ngIf="firmaer != null">
                <thead>
                    <tr class="sortable">
                        <th data-s="1" (click)="sort(1)" [class.sortrev]="sortering == 1 &amp;&amp; reversert"><span i18n="@@app01Admin01FirmaOversikt8">Id</span> <span class="glyphicon glyphicon-triangle-top" *ngIf="sortering == 1 &amp;&amp; !reversert"></span><span class="glyphicon glyphicon-triangle-bottom" *ngIf="sortering == 1 &amp;&amp; reversert"></span></th>
                        <th data-s="2" (click)="sort(2)" [class.sortrev]="sortering == 2 &amp;&amp; reversert"><span i18n="@@app01Admin01FirmaOversikt4">Navn</span> <span class="glyphicon glyphicon-triangle-top" *ngIf="sortering == 2 &amp;&amp; !reversert"></span><span class="glyphicon glyphicon-triangle-bottom" *ngIf="sortering == 2 &amp;&amp; reversert"></span></th>
                        <th data-s="3" (click)="sort(3)" [class.sortrev]="sortering == 3 &amp;&amp; reversert"><span i18n="@@app01Admin01FirmaOversikt5">Admin</span> <span class="glyphicon glyphicon-triangle-top" *ngIf="sortering == 3 &amp;&amp; !reversert"></span><span class="glyphicon glyphicon-triangle-bottom" *ngIf="sortering == 3 &amp;&amp; reversert"></span></th>
                        <th data-s="5" (click)="sort(5)" [class.sortrev]="sortering == 5 &amp;&amp; reversert" class="text-center"><span i18n="@@app01Admin01FirmaOversikt7">Brukere</span> <span class="glyphicon glyphicon-triangle-top" *ngIf="sortering == 5 &amp;&amp; !reversert"></span><span class="glyphicon glyphicon-triangle-bottom" *ngIf="sortering == 5 &amp;&amp; reversert"></span></th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody id="Liste" i18n-data-placeholder="@@app01Admin01FirmaOversikt9" data-placeholder="(ingen)">
                    <tr *ngFor="let firma of firmaer" tabindex="0" (dblclick)="rediger(firma.Id, firma)" [class.text-danger]="firma.Slettet != null">
                        <td>{{firma.Id}}</td>
                        <td><span class="fa fa-building text-muted"></span> {{firma.Navn}}</td>
                        <td>{{firma.Admin}}</td>
                        <td class="text-center">{{firma.Brukere}}</td>
                        <td class="nopadd tight">
                            <button *ngIf="firma.Slettet == null" class="btn btn-default slett" (click)="slett(firma.Id, false)" [disabled]="firma.InnloggetBrukersFirma" [attr.title]="firma.InnloggetBrukersFirma ? EgetFirmaMelding : &#39;&#39;"><span class="glyphicon glyphicon-trash"></span></button>
                            <button *ngIf="firma.Slettet != null" class="btn btn-default" (click)="slett(firma.Id, true)"><span class="fa fa-undo"></span></button>
                        </td>
                        <td class="nopadd tight"><button class="btn btn-default" (click)="rediger(firma.Id, firma)"><span class="glyphicon glyphicon-pencil"></span></button></td>
                    </tr>

                </tbody>
            </table>
        </div>
    </div>

    <div style="display:none">
        <div #Tittel="" i18n="@@app01Admin01FirmaOversikt12">BIM2Share - Firmaer</div>
        <div #HentTilbakeMelding="" i18n="@@app01Admin01FirmaOversikt10">Vil du hente tilbake firmaet?</div>
        <div #SletteMelding="" i18n="@@app01Admin01FirmaOversikt11">Vil du slette firmaet?</div>
        <div #EgetFirmaMeldingTekst="" i18n="@@app01Admin01FirmaOversikt13">Du kan ikke slette ditt eget firma</div>
        <div #KanIkkeRedigereSletteFirmaMelding="" i18n="@@app01Admin01FirmaOversikt14">Kan ikke redigere et slettet firma</div>
    </div>
</div>