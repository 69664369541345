import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var DriftsmeldingDto = /** @class */ (function () {
    function DriftsmeldingDto() {
    }
    return DriftsmeldingDto;
}());
export { DriftsmeldingDto };
var WorkingEncoder = /** @class */ (function () {
    function WorkingEncoder() {
    }
    WorkingEncoder.prototype.encodeKey = function (key) { return encodeURIComponent(key); };
    WorkingEncoder.prototype.encodeValue = function (value) { return encodeURIComponent(value); };
    WorkingEncoder.prototype.decodeKey = function (key) { return decodeURIComponent(key); };
    WorkingEncoder.prototype.decodeValue = function (value) { return decodeURIComponent(value); };
    return WorkingEncoder;
}());
export { WorkingEncoder };
var AdminDriftsmeldingOversiktService = /** @class */ (function () {
    function AdminDriftsmeldingOversiktService(http) {
        this.http = http;
        this.serviceUrl = environment.APIUrl + 'AdminDriftsmeldingOversikt/';
    }
    AdminDriftsmeldingOversiktService.prototype.addArray = function (params, key, value) {
        if (typeof value === "undefined" || value === null)
            return params;
        if (Array.isArray(value) && value.length === 0)
            return params;
        for (var i = 0; i < value.length; i++)
            params = params.append(key + "[]", value[i].toString());
        return params;
    };
    AdminDriftsmeldingOversiktService.prototype.Hent = function () {
        return this.http.get(this.serviceUrl + "Hent", { withCredentials: true }).toPromise();
    };
    AdminDriftsmeldingOversiktService.prototype.Lagre = function (data) {
        return this.http.post(this.serviceUrl + "Lagre", data, { withCredentials: true }).toPromise();
    };
    AdminDriftsmeldingOversiktService.ngInjectableDef = i0.defineInjectable({ factory: function AdminDriftsmeldingOversiktService_Factory() { return new AdminDriftsmeldingOversiktService(i0.inject(i1.HttpClient)); }, token: AdminDriftsmeldingOversiktService, providedIn: "root" });
    return AdminDriftsmeldingOversiktService;
}());
export { AdminDriftsmeldingOversiktService };
