<div autocomplete="off">

    <div class="container" [ng-busy]="busy">
        <div class="pull-right">
            <button type="button" class="btn btn-primary" (click)="lastNedExcel()">
                <span class="glyphicon glyphicon-export"></span>
                <span i18n="@@app02ProsjektadminRediger15">Eksportér til Excel</span>
            </button>
            &nbsp;
            <a onclick="return navback();" class="btn btn-default" tabindex="-1">
                <span class="glyphicon glyphicon-menu-left"></span>
                <span i18n="@@app02ProsjektadminRediger2">Gå tilbake</span>
            </a>
        </div>

        <div *ngIf="hovedprosjekt != null">
            <h1 i18n="@@app02ProsjektadminRediger3">Rediger prosjekt</h1>

            <div class="row">
                <div class="col-lg-3 col-xs-12">
                    <div class="form-group">
                        <b i18n="@@app02ProsjektadminRediger4">Navn</b><br />
                        <label>{{hovedprosjekt.Navn}}</label>
                    </div>
                </div>
                <div class="col-lg-3 col-xs-12">
                    <div class="form-group">
                        <b i18n="@@app02ProsjektadminRediger5">Prosjektnr</b><br />
                        <label>{{hovedprosjekt.Id}}</label>
                    </div>
                </div>
                <div class="col-lg-3 col-xs-12">
                    <div class="form-group">
                        <b i18n="@@app02ProsjektadminRediger6">Prosjektnr i byggeweb</b><br />
                        <label>{{hovedprosjekt.Prosjektnr}}</label>
                    </div>
                </div>
            </div>

            <div>
                <h2 i18n="@@app02ProsjektadminRediger7">Hovedkontrakter</h2>

                <table class="table table-striped table-bordered" style="width:auto">
                    <thead>
                        <tr>
                            <th i18n="@@app02ProsjektadminRediger8">Id</th>
                            <th i18n="@@app02ProsjektadminRediger9">Navn</th>
                            <th i18n="@@app02ProsjektadminRediger31">Kontraktstittel</th>
                            <th i18n="@@app02ProsjektadminRediger10">Kontraktsnr</th>
                            <th i18n="@@app02ProsjektadminRediger11">Kontraktstype</th>
                            <th i18n="@@app02ProsjektadminRediger12">Kontrakter</th>
                            <th i18n="@@app02ProsjektadminRediger13" *ngIf="manglerRoller()">Manglende roller</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody *ngIf="hovedprosjekt.Hovedkontrakter.length == 0">
                        <tr>
                            <td colspan="100"><span i18n="@@app02ProsjektadminRediger14">(ingen)</span></td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr *ngFor="let h of hovedprosjekt.Hovedkontrakter" tabindex="0" (dblclick)="redigerKontrakter(h.Id)">
                            <td>{{h.Id}}</td>
                            <td>{{h.Navn}}</td>
                            <td>{{h.Kontraktstittel}}</td>
                            <td>{{h.Kontraktsnr}}</td>
                            <td>{{h.Kontraktstype}}</td>
                            <td>{{h.Kontrakter}}</td>
                            <td *ngIf="manglerRoller()">
                                <div *ngIf="h.ManglendeRoller"><span class="glyphicon glyphicon-alert text-danger"></span>&nbsp;{{h.ManglendeRoller}}</div>
                                <div *ngIf="!h.ManglendeRoller">-</div>
                            </td>
                            <td class="nopadd tight"><button (click)="redigerKontrakter(h.Id)" class="btn btn-default"><span class="glyphicon glyphicon-pencil"></span></button></td>
                        </tr>
                    </tbody>
                </table>
            </div>


            <div *ngIf="brukergrupper != null">
                <h2 i18n="@@app02ProsjektadminRediger16">Brukergrupper</h2>

                <table class="table table-striped table-bordered">
                    <thead>
                        <tr>
                            <th i18n="@@app02ProsjektadminRediger17">Id</th>
                            <th i18n="@@app02ProsjektadminRediger18">Navn</th>
                            <th i18n="@@app02ProsjektadminRediger19">Brukere</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody *ngIf="brukergrupper.length == 0">
                        <tr>
                            <td colspan="100"><span i18n="@@app02ProsjektadminRediger20">(ingen)</span></td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr *ngFor="let gruppe of brukergrupper; let i = index" tabindex="0" (dblclick)="redigerBrukergruppe(gruppe.Id)">
                            <td>{{gruppe.Id}}</td>
                            <td>{{gruppe.Navn}}</td>
                            <td>
                                <ng-container *ngFor="let bruker of gruppe.Brukerkontoer">
                                    <span *ngIf="!bruker.Slettet || (bruker.Slettet &amp;&amp; visSlettedeBrukere)" class="badge bg-default" style="margin-right:5px;">{{bruker.Navn}}</span>
                                </ng-container>
                            </td>
                            <td class="nopadd tight"><button (click)="redigerBrukergruppe(gruppe.Id)" class="btn btn-default"><span class="glyphicon glyphicon-pencil"></span></button></td>
                        </tr>
                    </tbody>
                </table>

                <label style="margin-right:0.5em">
                    <input type="checkbox" [(ngModel)]="visSlettedeBrukere" />&nbsp;<span i18n="@@app02ProsjektadminRediger33">Vis slettede gruppemedlemmer</span>
                </label>

            </div>

        </div>

    </div>

    <div style="display:none">
        <div #Tittel="" i18n="@@app02ProsjektadminRediger28">BIM2Share - Rediger prosjekt</div>
    </div>
</div>