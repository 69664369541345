﻿import { Component, ViewChild, ElementRef } from '@angular/core';

import { ModalsService } from '../../80 Modals/Service';
import { Title } from '@angular/platform-browser';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AdminDriftsmeldingOversiktService, DriftsmeldingDto } from '../../99 Services/AdminDriftsmeldingOversikt.service';
import { DatetimeinputPipe } from '../../98 Shared/datetimeinput.pipe';

declare let $: any;

@Component({
    selector: "driftsmelding-oversikt",
    templateUrl: "./Oversikt.html",
})
export class DriftsmeldingOversiktComponent {

    constructor(private service: AdminDriftsmeldingOversiktService, private modalService: ModalsService, private titleService: Title, private bsModalService: BsModalService) { }

    busy: any;
    visSlettede: boolean = false;
    driftsmeldinger: DriftsmeldingDto[] = null;
    
    
    SletteMelding: string;

    @ViewChild("Tittel") TittelRef: ElementRef;
    @ViewChild("SletteMelding") SletteMeldingRef: ElementRef;

    ngAfterViewInit() {
        this.titleService.setTitle($(this.TittelRef.nativeElement).text());
        this.SletteMelding = $(this.SletteMeldingRef.nativeElement).text();
    }

    ngOnInit() {
        this.oppdater();
    }

    oppdater() {
        var that = this;
        this.busy = this.service.Hent()
            .then(res => {
                that.driftsmeldinger = res;
            }, fail => {
                that.modalService.Alert(fail.error.Message);
            });
    }

    lagre() {
        var that = this;
        this.busy = this.service.Lagre(that.driftsmeldinger).then(res => {
            that.driftsmeldinger = res;
        }, fail => {
            that.modalService.Alert(fail.error.Message);
        });
    }

    nyMelding() {
        var m = new DriftsmeldingDto();
        m.VisFra = new DatetimeinputPipe("en-US").transform(new Date());
        this.driftsmeldinger.push(m);
    }

    slettMelding(index: number) {
        var that = this;
        this.modalService.Confirm(that.SletteMelding, function () {
            var melding = that.driftsmeldinger[index]
            melding.VisTil = new DatetimeinputPipe("en-US").transform(new Date());
            melding.Slettet = true;
        });
    }

    preview(i: number) {
        var that = this;
        this.driftsmeldinger.forEach(function (item, index) {
            var d = that.driftsmeldinger[index];
            if (index != i)
                d.Forhandsvisning = false;
        });
    }
    

}
