﻿// ********************************************************************
// ***                                                              ***
// ***            DO NOT MODIFY THIS FILE MANUALLY!                 ***
// *** This file was generated by the API2ANGULAR Service Generator ***
// ***                                                              ***
// ********************************************************************

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpParameterCodec } from '@angular/common/http';
import { environment } from '../../environments/environment';


export class ProsessflytgruppeDto {
    public Id: number;
    public Navn: string;
    public Slettet: string;
    public Prosessflyter: ProsessflytDto[] = [];
    public Sortering: number;
}

export class ProsessflytDto {
    public Id: number;
    public Navn: string;
    public Beskrivelse: string;
    public Oppdatert: string;
    public Versjonsnr: number;
    public Kontraktstyper: KontraktstypeDto[] = [];
    public Nummerserie: string;
    public Kode: string;
    public Antall: number;
    public Trinn: number;
    public Slettet: string;
    public Tilgang: string;
    public Mappenavn: string;
    public Skjemaer: SkjemaDto[] = [];
}

export class KontraktstypeDto {
    public Id: number;
    public Navn: string;
}

export class SkjemaDto {
    public Id: number;
    public Navn: string;
}

export class WorkingEncoder implements HttpParameterCodec {
    encodeKey(key: string): string { return encodeURIComponent(key); }
    encodeValue(value: string): string { return encodeURIComponent(value); }
    decodeKey(key: string): string { return decodeURIComponent(key); }
    decodeValue(value: string): string { return decodeURIComponent(value); }
}

@Injectable({
   providedIn: "root"
})
export class AdminProsessflytgruppeRedigerService {
    private serviceUrl = environment.APIUrl + 'AdminProsessflytgruppeRediger/';

    constructor(private http: HttpClient) { } 

    private addArray(params: HttpParams, key: string, value: any): HttpParams {
        if (typeof value === "undefined" || value === null) return params;
        if (Array.isArray(value) && value.length === 0) return params;
        for (let i = 0; i < value.length; i++) params = params.append(key + "[]", value[i].toString());
        return params;
    }

    public Hent(id: number, sortering: number): Promise<ProsessflytgruppeDto> {
        let params = new HttpParams({ encoder: new WorkingEncoder() })
        params = params.set("id",id != null ? id.toString() : null);
        params = params.set("sortering",sortering != null ? sortering.toString() : null);
        return <Promise<ProsessflytgruppeDto>>this.http.get(this.serviceUrl + "Hent", { params, withCredentials: true }).toPromise();
    }

    public Lagre(data: ProsessflytgruppeDto): Promise<ProsessflytgruppeDto> {
        return <Promise<ProsessflytgruppeDto>>this.http.post(this.serviceUrl + "Lagre", data, { withCredentials: true }).toPromise();
    }

    public SlettProsessflyt(id: number, sortering: number): Promise<ProsessflytgruppeDto> {
        let params = new HttpParams({ encoder: new WorkingEncoder() })
        params = params.set("id",id != null ? id.toString() : null);
        params = params.set("sortering",sortering != null ? sortering.toString() : null);
        return <Promise<ProsessflytgruppeDto>>this.http.get(this.serviceUrl + "SlettProsessflyt", { params, withCredentials: true }).toPromise();
    }
}
