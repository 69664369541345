﻿import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpParameterCodec } from '@angular/common/http';
import { environment } from '../../environments/environment';


@Injectable({
    providedIn: "root"
})
export class LanguageService {
    constructor() {
        this.currentLang = localStorage.getItem("userLang");
    }

    public currentLang: string = null;


    updateLang(lang: string) {
        //console.log(this.currentLang, lang);

        let isLocal = window.location.host.indexOf("localhost") != -1;
        let languageChanged = false;

        if (this.currentLang != lang) {
            if (lang == null) {
                localStorage.removeItem("userLang");
            } else {
                localStorage.setItem("userLang", lang);
            }
            this.currentLang = lang;
            languageChanged = true;
        }
        

        // Use precompiled i18n apps in prod
        if (!isLocal) {

            let pathname = window.location.pathname;

            if (lang != "en" && pathname.indexOf("/en/") != -1) {
                console.warn("Url has language path for another language. Removing '/en/' from pathname");
                pathname = "/" + pathname.replace("/en/", "");

                window.location.pathname = pathname;

            } else if (lang == "en" && pathname.indexOf("/en/") == -1) {
                console.warn("Url is missing language path. Adding '/en/' to pathname");
                pathname = "/en" + pathname;

                window.location.pathname = pathname;
            }
        } else {
            if (languageChanged) {
                location.reload();
            }
        }
    }
    

    
}
