﻿import { NgModule, Inject } from '@angular/core';
import { Router, RouterModule, UrlHandlingStrategy, UrlSerializer, DefaultUrlSerializer, UrlTree, Routes } from '@angular/router';

import { SmartappComponent } from './smartapp';
import { FirmaOversiktComponent } from './01 Admin/01 Firma/Oversikt';
import { FirmaRedigerComponent } from './01 Admin/01 Firma/Rediger';
import { BrukergruppeOversiktComponent } from './01 Admin/02 Brukergruppe/Oversikt';
import { BrukerkontoRedigerComponent } from './01 Admin/03 Brukerkonto/Rediger';
import { ProsjektadminOversiktComponent } from './02 Prosjektadmin/Oversikt';
import { ProsjektadminRedigerComponent } from './02 Prosjektadmin/Rediger';
import { BrukergruppeRedigerComponent } from './01 Admin/02 Brukergruppe/Rediger';
import { AdminProsessflytRedigerComponent } from './01 Admin/04 Prosessflyt/Rediger';
import { DriftsmeldingOversiktComponent } from './01 Admin/05 Driftsmelding/Oversikt';
import { AdminProsessflytgruppeOversiktComponent } from './01 Admin/05 Prosessflytgruppe/Oversikt';
import { AdminProsessflytgruppeRedigerComponent } from './01 Admin/05 Prosessflytgruppe/Rediger';
import { RapportOppforingerComponent } from './03 Rapport/Oppforinger';

export const routes: Routes = [
    { path: 'Admin/Firma/Oversikt', component: FirmaOversiktComponent },
    { path: 'Admin/Firma/Rediger/:id', component: FirmaRedigerComponent },
    { path: 'Admin/Brukergruppe/Oversikt', component: BrukergruppeOversiktComponent },
    { path: 'Admin/Brukerkonto/Rediger/:firmaId/:brukerkontoId', component: BrukerkontoRedigerComponent },
    { path: 'Prosjektadmin/Oversikt', component: ProsjektadminOversiktComponent },
    { path: 'Prosjektadmin/Rediger/:hovedprosjektId', component: ProsjektadminRedigerComponent },
    { path: 'Admin/Brukergruppe/Rediger/:brukergruppeId', component: BrukergruppeRedigerComponent },
    { path: 'Prosjektadmin/Brukergruppe/Rediger/:brukergruppeId', component: BrukergruppeRedigerComponent },
    { path: 'Admin/Prosessflyt/Rediger/:prosessflytgruppeId/:prosessflytId', component: AdminProsessflytRedigerComponent },
    { path: 'Admin/Driftsmeldinger', component: DriftsmeldingOversiktComponent },
    { path: 'Admin/Prosessflytgruppe/Oversikt/:sortering', component: AdminProsessflytgruppeOversiktComponent },
    { path: 'Admin/Prosessflytgruppe/Rediger/:id/:sortering', component: AdminProsessflytgruppeRedigerComponent },
    { path: 'Rapport/Oppforinger', component: RapportOppforingerComponent},
    { path: '**', component: SmartappComponent },
];

export class CustomUrlSerializer implements UrlSerializer {
    parse(url: any): UrlTree {
        let dus = new DefaultUrlSerializer();
        return dus.parse(url);
    }

    serialize(tree: UrlTree): any {
        let dus = new DefaultUrlSerializer(),
            path = dus.serialize(tree);
        // use your regex to replace as per your requirement.
        return path.replace(/%2B/g, '+');
    }
}

export class CustomHandling implements UrlHandlingStrategy {

    initialSmartappLoad: boolean = false;

    // Dont process routes we dont recognize as long as smartapp is running
    shouldProcessUrl(url) {

        let urlStr: string = url.toString();

        if (urlStr.startsWith("/")) urlStr = urlStr.substr(1);
        
        let knownRoute = routes.some((route) => {
            return urlStr.startsWith(route.path.split(":")[0]);
        });
        
        if (!knownRoute && !this.initialSmartappLoad) {
            console.log("Angular UrlHandlingStrategy: unknown angular path and smartapp not loaded: handling url (loading Smartapp component):", urlStr);
            this.initialSmartappLoad = true;
            return true;
        }

        if (!knownRoute && this.initialSmartappLoad) {
            console.log("Angular UrlHandlingStrategy: unknown angular path and smartapp loaded: ignoring url:", urlStr);
            return false;
        }

        if (knownRoute) {
            console.log("Angular UrlHandlingStrategy: know angular url: handling:", urlStr);
            this.initialSmartappLoad = false;
            return true;
        }
    }
    extract(url) { return url; }
    merge(url, whole) { return url; }
}

@NgModule({
    declarations: [],
    imports: [RouterModule.forRoot(routes, { useHash: true, initialNavigation: false })],
    exports: [RouterModule],
    providers: [
        { provide: UrlSerializer, useClass: CustomUrlSerializer },
        { provide: UrlHandlingStrategy, useClass: CustomHandling }
    ],
})
export class AppRoutingModule {
    constructor(private router: Router) {
        router.initialNavigation();
    }
}