<div autocomplete="off">

    <div class="container" [ng-busy]="busy">
        <div class="pull-right meny">
            <button id="LagreBtn" class="btn btn-success" (click)="lagre()" *ngIf="brukerkonto != null &amp;&amp; brukerkonto?.Slettet == null">
                <span class="glyphicon glyphicon-ok"></span>&nbsp;<span i18n="@@app01Admin03BrukerkontoRediger1">Lagre</span>
            </button>

            <button class="btn btn-danger" (click)="slett()" *ngIf="brukerkonto != null &amp;&amp; brukerkontoId > 0 &amp;&amp; brukerkonto?.Slettet == null">
                <span class="glyphicon glyphicon-trash"></span>&nbsp;<span i18n="@@app01Admin03BrukerkontoRediger37">Slett</span>
            </button>

            <button class="btn btn-default" (click)="loggInnSom()" *ngIf="brukerkonto != null &amp;&amp; brukerkontoId > 0 &amp;&amp; brukerErAdmin &amp;&amp; brukerkonto?.Slettet == null">
                <span class="glyphicon glyphicon-log-in"></span>&nbsp;<span i18n="@@app01Admin03BrukerkontoRediger38">Logg inn som</span>
            </button>

            <a onclick="return navback();" class="btn btn-default" tabindex="-1">
                <span class="glyphicon glyphicon-menu-left"></span>
                <span i18n="@@app01Admin03BrukerkontoRediger2">Gå tilbake</span>
            </a>
        </div>

        <div *ngIf="brukerkonto != null" autocomplete="off">
            <h1 *ngIf="brukerkonto.Id == 0" i18n="@@app01Admin03BrukerkontoRediger3">Opprett brukerkonto</h1>
            <h1 *ngIf="brukerkonto.Id != 0" i18n="@@app01Admin03BrukerkontoRediger4">Rediger brukerkonto</h1>

            <div class="row">
                <div class="col-lg-3 col-xs-12">
                    <div class="form-group">
                        <label i18n="@@app01Admin03BrukerkontoRediger5">Navn</label>
                        <input class="form-control" [(ngModel)]="brukerkonto.Navn" [disabled]="brukerkonto.Slettet != null" />
                    </div>
                </div>
                <div class="col-lg-3 col-xs-12">
                    <div class="form-group">
                        <label i18n="@@app01Admin03BrukerkontoRediger6">E-post</label>
                        <input class="form-control" [(ngModel)]="brukerkonto.Epost" [disabled]="brukerkonto.Slettet != null" />
                    </div>
                </div>
                <div class="col-lg-3 col-xs-12">
                    <div class="form-group">
                        <label i18n="@@app01Admin03BrukerkontoRediger7">Firma</label><br />
                        <ng-select [items]="firmaer" bindLabel="text" bindValue="id" [multiple]="false" [clearable]="false" i18n-placeholder="@@app01Admin03BrukerkontoRediger48" placeholder="(velg firma)" [(ngModel)]="brukerkonto.FirmaId" [disabled]="brukerkonto.Slettet != null"></ng-select>
                    </div>
                </div>
                <div class="col-lg-3 col-xs-12">
                    <div class="form-group">
                        <label i18n="@@app01Admin03BrukerkontoRediger53">Sist aktiv</label><br />
                        <span>{{brukerkonto.SistAktiv | datetime}}</span>
                    </div>
                </div>
            </div>
            <h4 i18n="@@app01Admin03BrukerkontoRediger8">Innlogging</h4>
            <div class="row">
                <div class="col-lg-3 col-xs-12">
                    <div class="form-group">
                        <label i18n="@@app01Admin03BrukerkontoRediger9">Brukernavn</label>
                        <input class="form-control" [(ngModel)]="brukerkonto.Login" [disabled]="brukerkonto.Slettet != null" />
                    </div>
                </div>
                <div class="col-lg-3 col-xs-12">
                    <div class="form-group">
                        <label i18n="@@app01Admin03BrukerkontoRediger10">Passord</label>
                        <input type="password" class="form-control" [(ngModel)]="brukerkonto.Passord" [disabled]="brukerkonto.LoggPaViaByggeweblogin || brukerkonto.Slettet != null" />
                    </div>
                </div>
                <div class="col-lg-3 col-xs-12">
                    <div class="form-group">
                        <label>&nbsp;</label><br />
                        <label>
                            <input type="checkbox" [(ngModel)]="brukerkonto.KrevNyttPassord" [disabled]="brukerkonto.LoggPaViaByggeweblogin || brukerkonto.Slettet != null" />&nbsp;<span i18n="@@app01Admin03BrukerkontoRediger11">Krev passordendring ved neste login</span>
                        </label>
                    </div>
                </div>
                <div class="col-lg-3 col-xs-12">
                    <div class="form-group">
                        <label>&nbsp;</label><br />
                        <label>
                            <input type="checkbox" [(ngModel)]="brukerkonto.LoggPaViaByggeweblogin" [disabled]="brukerkonto.Slettet != null" />&nbsp;<span i18n="@@app01Admin03BrukerkontoRediger12">Bruk byggewebbruker</span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-xs-12">
                    <div class="alert alert-info" *ngIf="brukerkonto.LoggPaViaByggeweblogin">OBS! Bruk samme brukernavn som i Byggeweb prosjekt</div>
                </div>
            </div>
            <h4 i18n="@@app01Admin03BrukerkontoRediger13">E-post varsling</h4>
            <div class="row">
                <div class="col-lg-3 col-xs-12">
                    <div class="form-group">
                        <label>
                            <input type="checkbox" [(ngModel)]="brukerkonto.Epostvarsel" [disabled]="brukerkonto.Slettet != null" />&nbsp;<span i18n="@@app01Admin03BrukerkontoRediger14">Varsel om ny oppføring</span>
                        </label>
                    </div>
                </div>
                <div class="col-lg-3 col-xs-12">
                    <div class="form-group">
                        <label>
                            <input type="checkbox" [(ngModel)]="brukerkonto.MottaEpostkvittering" [disabled]="brukerkonto.Slettet != null" />&nbsp;<span i18n="@@app01Admin03BrukerkontoRediger15">E-post kvittering</span>
                        </label>
                    </div>
                </div>
                <div class="col-lg-3 col-xs-12">
                    <div class="form-group">
                        <label>
                            <input type="checkbox" [(ngModel)]="brukerkonto.DagligEpostSamlerapport" [disabled]="brukerkonto.Slettet != null" />&nbsp;<span i18n="@@app01Admin03BrukerkontoRediger16">Motta daglig samlerapport</span>
                        </label>
                    </div>
                </div>
                <div class="col-lg-3 col-xs-12">
                    <div class="form-group">
                        <label>
                            <input type="checkbox" [(ngModel)]="brukerkonto.DagligVarslingUbehandlede" [disabled]="brukerkonto.Slettet != null" />&nbsp;<span i18n="@@app01Admin03BrukerkontoRediger34">Motta daglig varsling om ubehandlede oppføringer</span>
                        </label>
                    </div>
                </div>
            </div>
            <h4 i18n="@@app01Admin03BrukerkontoRediger17">Brukergruppemedlemskap</h4>
            <div class="row">
                <div class="col-xs-12">
                    <ng-select #brukergruppemedlemskap="" [items]="brukergrupper" bindLabel="Navn" bindValue="Id" [multiple]="true" [clearable]="true" i18n-placeholder="@@app01Admin03BrukerkontoRediger49" placeholder="(velg brukergrupper)" [(ngModel)]="brukerkonto.Brukergrupper" [disabled]="brukerkonto.Slettet != null">
                        <ng-template ng-label-tmp="" let-item="item">
                            <span aria-hidden="true" class="ng-value-icon left" (click)="brukergruppemedlemskap.clearItem(item)">×</span>
                            <span style="padding: 0 5px">
                                <span *ngIf="item.Slettet" class="glyphicon glyphicon-warning-sign text-danger"></span>
                                {{item.Navn}}
                                <span *ngIf="item.Slettet" class="text-danger" i18n="@@app01Admin03BrukerkontoRediger50">(slettet)</span>
                            </span>
                        </ng-template>
                        <ng-template ng-option-tmp="" let-item="item">

                            <span *ngIf="item.Slettet" class="glyphicon glyphicon-warning-sign text-danger"></span>
                            {{item.Navn}}
                            <span *ngIf="item.Slettet" class="text-danger" i18n="@@app01Admin03BrukerkontoRediger51">(slettet)</span>
                        </ng-template>

                    </ng-select>
                </div>
            </div>
            <div *ngIf="brukerkonto.Kontrakter != null &amp;&amp; brukerkonto.Slettet == null">
                <hr />
                <h2 i18n="@@app01Admin03BrukerkontoRediger18">Kontrakter</h2>
                <table class="table table-condensed table-bordered table-striped table-hover" style="width:auto">
                    <thead>
                        <tr>
                            <th i18n="@@app01Admin03BrukerkontoRediger25">Tittel</th>
                            <th i18n="@@app01Admin03BrukerkontoRediger26">Kontraktsnr</th>
                            <th i18n="@@app01Admin03BrukerkontoRediger27">Hovedprosjekt</th>
                            <th i18n="@@app01Admin03BrukerkontoRediger28">Hovedkontrakt</th>
                            <th i18n="@@app01Admin03BrukerkontoRediger29">Rolle</th>
                            <th i18n="@@app01Admin03BrukerkontoRediger30">Bruker / gruppe</th>
                            <th i18n="@@app01Admin03BrukerkontoRediger31">Oppstart</th>
                            <th i18n="@@app01Admin03BrukerkontoRediger32">Utløpsdato</th>
                            <th i18n="@@app01Admin03BrukerkontoRediger33">Innboks</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody *ngIf="ingenKontrakter()">
                        <tr>
                            <td colspan="100" i18n="@@app01Admin03BrukerkontoRediger19">Ingen kontrakter</td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="brukerkonto.Kontrakter.length > 0">
                        <tr *ngFor="let k of brukerkonto.Kontrakter; let i = index" [class.text-danger]="k.Utlopt" [class.hidden]="k.Utlopt &amp;&amp; !visUtlopteKontrakter || (k.BrukergruppeId != null &amp;&amp; (brukerkonto.Brukergrupper == null || brukerkonto.Brukergrupper.indexOf(k.BrukergruppeId) == -1))">
                            <td class="input" *ngIf="brukerErAdmin"><div contenteditable="true" [(ngModel)]="k.Tittel" [attr.data-placeholder]="k.HovedkontraktKontraktstittel" [disabled]="k.Utlopt || k.Brukergruppe"></div></td>
                            <td class="padd" *ngIf="!brukerErAdmin">{{k.GjeldendeTittel}}</td>
                            <td class="input" *ngIf="brukerErAdmin"><div contenteditable="true" [(ngModel)]="k.Kontraktsnr" [attr.data-placeholder]="k.HovedkontraktKontraktsnr" [disabled]="k.Utlopt || k.Brukergruppe"></div></td>
                            <td class="padd" *ngIf="!brukerErAdmin">{{k.GjeldendeKontraktsnr}}</td>
                            <td>
                                <div style="display:flex;">
                                    <ng-select [items]="hovedprosjekter" bindLabel="Navn" bindValue="Id" [multiple]="false" [clearable]="false" placeholder="(velg hovedprosjekt)" [(ngModel)]="k.HovedprosjektId" (change)="hovedprosjektChanged(i)" class="custom" [disabled]="k.Utlopt || k.Brukergruppe"></ng-select>
                                    <div class="nopadd">
                                        <a class="btn btn-default" title="Gå til hovedprosjekt" (click)="openHovedprosjekt(k.HovedprosjektId)">
                                            <span class="glyphicon glyphicon-pencil"></span>
                                        </a>
                                    </div>
                                </div>
                            </td>
                            <td><ng-select [items]="k.Hovedkontrakter" bindLabel="text" bindValue="id" [multiple]="false" [clearable]="false" placeholder="(velg hovedkontrakt)" [(ngModel)]="k.HovedkontraktId" (change)="hovedkontraktChanged(i)" class="custom" [disabled]="k.Utlopt || k.Brukergruppe"></ng-select></td>
                            <td><ng-select [items]="hentRoller(i)" bindLabel="text" bindValue="id" [multiple]="false" [clearable]="false" placeholder="(velg rolle)" [(ngModel)]="k.RolleId" class="custom" [disabled]="k.Utlopt || k.Brukergruppe"></ng-select></td>
                            <td class="padd"><span *ngIf="k.Brukerkonto"><span class="glyphicon glyphicon-user text-muted"></span> {{k.Brukerkonto}}</span><span *ngIf="k.Brukergruppe"><span class="fa fa-users text-muted"></span> {{k.Brukergruppe}}</span></td>
                            <td><input class="form-control input-sm" type="date" [(ngModel)]="k.Oppstart" [disabled]="k.Utlopt || k.Brukergruppe" /></td>
                            <td><input class="form-control input-sm" type="date" [(ngModel)]="k.Utlopsdato" [disabled]="k.Utlopt || k.Brukergruppe" /></td>
                            <td class="padd">{{k.Innboks}}</td>
                            <td class="nopadd"><button *ngIf="!k.Utlopt" class="btn btn-default" (click)="slettKontrakt(i)" [disabled]="k.Brukergruppe"><span class="glyphicon glyphicon-trash"></span></button></td>
                        </tr>
                    </tbody>
                </table>
                <p *ngIf="brukerErAdmin" i18n="@@app01Admin03BrukerkontoRediger52">* Hvis kontraktsnr endres, vil eksisterende oppføringer få oppdatert oppføringsnummer.</p>
                <label>
                    <input type="checkbox" [(ngModel)]="visUtlopteKontrakter" />&nbsp;<span i18n="@@app01Admin03BrukerkontoRediger21">Vis utløpte kontrakter</span>
                </label>
                <br />
                <button class="btn btn-primary" (click)="nyKontrakt()"><span i18n="@@app01Admin03BrukerkontoRediger22">Legg til kontrakt</span></button>


            </div>

            <div *ngIf="brukerkonto.Prosjektadmin != null &amp;&amp; brukerErAdmin">
                <h2 i18n="@@app01Admin03BrukerkontoRediger40">Brukeradministrator</h2>
                <table class="table table-condensed table-bordered table-striped table-hover" style="width:auto">
                    <thead>
                        <tr>

                            <th i18n="@@app01Admin03BrukerkontoRediger41">Hovedprosjekt</th>
                            <th i18n="@@app01Admin03BrukerkontoRediger42">Oppstart</th>
                            <th i18n="@@app01Admin03BrukerkontoRediger43">Utløpsdato</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody *ngIf="ingenProsjektadmin()">
                        <tr>
                            <td colspan="100" i18n="@@app01Admin03BrukerkontoRediger44">Ingen hovedprosjekter</td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr *ngFor="let p of brukerkonto.Prosjektadmin; let i = index;" [class.text-danger]="p.Utlopt" [class.hidden]="p.Utlopt &amp;&amp; !visUtlopteProsjektadmin">
                            <td class="inputcol">
                                <div style="display: flex">
                                    <ng-select [items]="p.HovedprosjektListe" bindLabel="Navn" bindValue="Id" [multiple]="false" [clearable]="false" placeholder="(velg hovedprosjekt)" [(ngModel)]="p.HovedprosjektId" (change)="prosjektadminHovedprosjektChanged()" class="custom" [disabled]="p.Utlopt &amp;&amp; brukerkonto.Slettet != null">
                                    </ng-select>
                                    <div class="nopadd">
                                        <a class="btn btn-default" title="Gå til hovedprosjekt" *ngIf="p.HovedprosjektId" (click)="openHovedprosjekt(p.HovedprosjektId)">
                                            <span class="glyphicon glyphicon-pencil"></span>
                                        </a>
                                    </div>
                                </div>
                            </td>
                            <td class="inputcol"><input class="form-control input-sm" type="date" [(ngModel)]="p.Oppstart" [disabled]="p.Utlopt &amp;&amp; brukerkonto.Slettet != null" /></td>
                            <td class="inputcol"><input class="form-control input-sm" type="date" [(ngModel)]="p.Utlopsdato" [disabled]="p.Utlopt &amp;&amp; brukerkonto.Slettet != null" /></td>
                            <td class="nobr nopadd">
                                <button class="btn btn-default" (click)="slettProsjektadmin(i)" [disabled]="p.Utlopt" [class.hidden]="p.Utlopt &amp;&amp; brukerkonto.Slettet != null"><span class="glyphicon glyphicon-trash"></span></button>
                            </td>
                        </tr>
                    </tbody>

                </table>
                <label>
                    <input type="checkbox" [(ngModel)]="visUtlopteProsjektadmin" />&nbsp;<span i18n="@@app01Admin03BrukerkontoRediger45">Vis utløpte brukeradministratorer</span>
                </label>
                <br />
                <button class="btn btn-primary" (click)="nyProsjektadmin()" [disabled]="brukerkonto.Slettet != null"><span i18n="@@app01Admin03BrukerkontoRediger47">Legg til prosjekt</span></button>

            </div>
        </div>

    </div>

    <div style="display:none">
        <div #Tittel="" i18n="@@app01Admin03BrukerkontoRediger23">BIM2Share - Rediger brukerkonto</div>
        <div #SletteKontraktMelding="" i18n="@@app01Admin03BrukerkontoRediger24">Vil du slette kontrakten?</div>
        <div #HovedprosjektPlaceholder="" i18n="@@app01Admin03BrukerkontoRediger20">(velg hovedprosjekt)</div>
        <div #HovedkontraktPlaceholder="" i18n="@@app01Admin03BrukerkontoRediger35">(velg hovedkontrakt)</div>
        <div #RollePlaceholder="" i18n="@@app01Admin03BrukerkontoRediger36">(velg rolle)</div>
        <div #SlettBrukerMeldingTekst="" i18n="@@app01Admin03BrukerkontoRediger39">Vil du slette brukerkontoen?</div>
        <div #SlettProsjektadminMeldingTekst="" i18n="@@app01Admin03BrukerkontoRediger46">Vil du fjerne brukeren som brukeradministrator for valgt hovedprosjekt?</div>
    </div>
</div>