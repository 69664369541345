﻿import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { LoginService } from '../99 Services/Login.service';
import { ModalsService } from '../80 Modals/Service';
import { GlemtPassordControlService } from '../99 Services/GlemtPassordControl.service';

declare let $: any;
declare let base64: any;

@Component({
    selector: 'Login',
    templateUrl: "./Login.component.html"
})
export class LoginComponent {

    busy: any;
    ErrorMsg: string = "";
    NotChrome: boolean = false;
    IsTest: boolean;

    OpenPane = 1;

    public OKFunc: () => void;
    public CancelFunc: () => void;

    constructor(private modalRef: BsModalRef,
        private service: LoginService,
        private modalHelper: ModalsService,
        private glemtpassordSvc: GlemtPassordControlService) {

        if (window.location.href.indexOf("ikx.no") > 0 || window.location.href.indexOf("localhost") > 0) {
            this.IsTest = true;
        }

        this.NotChrome = window.navigator.vendor != "Google Inc.";
    }

    private brukernavn: string;
    private passord: string;

    login(user: string, pass: string) {
        this.ErrorMsg = null;

        if (this.isEmpty(user) || this.isEmpty(pass)) {
            this.ErrorMsg = "Du må oppgi både brukernavn og passord!";
            return false;
        }

        let e = base64.encode("Edialog") + " " + base64.encode(user + ":" + pass);

        this.busy = this.service.Login(e).then(result => {

            if (result.Message && !result.Success) {
                if (result.ResultCode == 2) {

                    this.brukernavn = user;
                    this.passord = pass;

                    this.OpenPane = 3;
                } else {
                    this.ErrorMsg = result.Message;
                }
                return;
            }

            if (this.OKFunc) this.OKFunc();
            this.modalRef.hide();

            window.localStorage.setItem("role", result.Role.toString());
            $(window).trigger("storage");
        }, error => {
            this.ErrorMsg = error.Message;
        });

        return false;
    }

    glemtpassord(user: string) {
        if (this.isEmpty(user)) {
            this.ErrorMsg = "Brukernavn må oppgis!";
            return false;
        }

        this.glemtpassordSvc.SendGlemtPassord(user).then(result => {
            if (result.Success) {
                this.modalHelper.Alert("Et nytt passord er blitt generert og sendt til din epostadresse!");
                this.OpenPane = 1;
            }
            else if (!result.Success && result.Message) {
                this.modalHelper.Alert(result.Message);
            }
        }, fail => {
        });

        return false;
    }

    cancel() {
        if (this.CancelFunc) this.CancelFunc();
        this.modalRef.hide();
    }

    private isEmpty(val: any) {
        if (val == null)
            return true;

        if (val == "")
            return true;

        return false;
    }

    oppdaterpassord(pass1: string, pass2: string) {

        if (this.isEmpty(this.brukernavn)) {
            this.ErrorMsg = "Brukernavn ikke oppgitt!";
            return false;
        }

        if (this.isEmpty(this.passord)) {
            this.ErrorMsg = "Gammelt passord ikke oppgitt!";
            return false;
        }

        if (this.isEmpty(pass1) || this.isEmpty(pass2)) {
            this.ErrorMsg = "Passord må oppgis!";
            return false;
        }

        if (pass1.localeCompare(pass2) != 0) {
            this.ErrorMsg = "Sørg for at du har tastet det nye passordet inn riktig i begge felter!";
            return false;
        }

        var t = base64.encode("Edialog") + " " + base64.encode(this.brukernavn + ":" + this.passord);
        var d = base64.encode("Edialog") + " " + base64.encode(this.brukernavn + ":" + pass2);

        this.busy = this.service.OppdaterPassord(t, d).then(result => {

            if (!result.Success) {
                this.modalHelper.Alert(result.Message);
            } else {
                this.modalHelper.Alert(result.Message, () => {
                    this.OpenPane = 1;
                    this.login(this.brukernavn, pass2);
                });
            }

        }, fail => {

        });

        return false;
    }

    testbrukere = [
        { color: "muted", label: "Admin (BIM2Share)", username: "admin", password: "test" },
        { color: "primary", label: "Prosjektleder", username: "pleder", password: "test" },
        { color: "primary", label: "Byggeleder", username: "bleder", password: "test" },
        { color: "warning", label: "Entreprenør", username: "ent", password: "test" },
        { color: "success", label: "Ekstern rådgiver", username: "eraad", password: "test" },
        { color: "yellow", label: "Intern rådgiver", username: "iraad", password: "test" },
        { color: "alert", label: "Innsyn", username: "inn", password: "test" },
        { color: "default", label: "Arkiv", username: "ark", password: "test" },
        { color: "success", label: "Tiltaksansvarlig", username: "tilt", password: "test" },
        { color: "warning", label: "Tiltaksansvarlig", username: "tilte", password: "test" },
        { color: "primary", label: "Kvalitetsrådgiver", username: "kval", password: "test" },
        { color: "primary", label: "Oppfølgingsansvarlig", username: "oppf", password: "test" },
        { color: "primary", label: "Prosjekteringsleder", username: "prleder", password: "test" }
    ];
}
