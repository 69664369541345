﻿import { Component, Input } from '@angular/core';
import { BsModalRef} from 'ngx-bootstrap/modal';

@Component({
    selector: "modal-content",
    templateUrl: "./SourceModal.html"
})
export class SourceModalComponent {
    constructor(public modalRef: BsModalRef) { }

    @Input() source: string;
    @Input() okfunc: Function = null;
    
    ok() {
        if (this.okfunc) this.okfunc(this.source);
        this.modalRef.hide();
    }

    cancel() {
        this.modalRef.hide();
    }

}

