﻿// ********************************************************************
// ***                                                              ***
// ***            DO NOT MODIFY THIS FILE MANUALLY!                 ***
// *** This file was generated by the API2ANGULAR Service Generator ***
// ***                                                              ***
// ********************************************************************

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpParameterCodec } from '@angular/common/http';
import { environment } from '../../environments/environment';


export class HentHovedprosjektDto {
    public Hovedprosjekt: HovedprosjektDto;
    public Brukergrupper: BrukergruppeDto[] = [];
}

export class HovedprosjektDto {
    public Id: number;
    public Navn: string;
    public Prosjektnr: string;
    public Hovedkontrakter: HovedkontraktDto[] = [];
}

export class HovedkontraktDto {
    public Id: number;
    public Navn: string;
    public Kontraktsnr: string;
    public Kontrakter: number;
    public Kontraktstype: string;
    public ManglendeRoller: string;
    public Kontraktstittel: string;
}

export class BrukergruppeDto {
    public Id: number;
    public Navn: string;
    public Brukerkontoer: BrukerkontoDto[] = [];
}

export class BrukerkontoDto {
    public Id: number;
    public Navn: string;
    public Brukernavn: string;
    public Epost: string;
    public Firma: string;
    public InnloggetBruker: boolean;
    public Slettet: boolean;
    public Hovedkontrakter: string[] = [];
}

export class WorkingEncoder implements HttpParameterCodec {
    encodeKey(key: string): string { return encodeURIComponent(key); }
    encodeValue(value: string): string { return encodeURIComponent(value); }
    decodeKey(key: string): string { return decodeURIComponent(key); }
    decodeValue(value: string): string { return decodeURIComponent(value); }
}

@Injectable({
   providedIn: "root"
})
export class ProsjektadminRedigerService {
    private serviceUrl = environment.APIUrl + 'ProsjektadminRediger/';

    constructor(private http: HttpClient) { } 

    private addArray(params: HttpParams, key: string, value: any): HttpParams {
        if (typeof value === "undefined" || value === null) return params;
        if (Array.isArray(value) && value.length === 0) return params;
        for (let i = 0; i < value.length; i++) params = params.append(key + "[]", value[i].toString());
        return params;
    }

    public Hent(id: number): Promise<HentHovedprosjektDto> {
        let params = new HttpParams({ encoder: new WorkingEncoder() })
        params = params.set("id",id != null ? id.toString() : null);
        return <Promise<HentHovedprosjektDto>>this.http.get(this.serviceUrl + "Hent", { params, withCredentials: true }).toPromise();
    }

    public SlettBrukerkonto(id: number): Promise<boolean> {
        let params = new HttpParams({ encoder: new WorkingEncoder() })
        params = params.set("id",id != null ? id.toString() : null);
        return <Promise<boolean>>this.http.get(this.serviceUrl + "SlettBrukerkonto", { params, withCredentials: true }).toPromise();
    }

    public LastNedTilExcel(hovedprosjektId: number): void {
        let params = new HttpParams({ encoder: new WorkingEncoder() })
        params = params.set("hovedprosjektId",  hovedprosjektId.toString());
        window.open(this.serviceUrl + "LastNedTilExcel?" + params.toString());
    }
}
