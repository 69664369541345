import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var KopierBrukergruppeDto = /** @class */ (function () {
    function KopierBrukergruppeDto() {
        this.Hovedprosjekter = [];
        this.Brukerkontoer = [];
    }
    return KopierBrukergruppeDto;
}());
export { KopierBrukergruppeDto };
var HovedprosjektDto = /** @class */ (function () {
    function HovedprosjektDto() {
    }
    return HovedprosjektDto;
}());
export { HovedprosjektDto };
var BrukerkontoDto = /** @class */ (function () {
    function BrukerkontoDto() {
    }
    return BrukerkontoDto;
}());
export { BrukerkontoDto };
var WorkingEncoder = /** @class */ (function () {
    function WorkingEncoder() {
    }
    WorkingEncoder.prototype.encodeKey = function (key) { return encodeURIComponent(key); };
    WorkingEncoder.prototype.encodeValue = function (value) { return encodeURIComponent(value); };
    WorkingEncoder.prototype.decodeKey = function (key) { return decodeURIComponent(key); };
    WorkingEncoder.prototype.decodeValue = function (value) { return decodeURIComponent(value); };
    return WorkingEncoder;
}());
export { WorkingEncoder };
var AdminBrukergruppeKopierModalService = /** @class */ (function () {
    function AdminBrukergruppeKopierModalService(http) {
        this.http = http;
        this.serviceUrl = environment.APIUrl + 'AdminBrukergruppeKopierModal/';
    }
    AdminBrukergruppeKopierModalService.prototype.addArray = function (params, key, value) {
        if (typeof value === "undefined" || value === null)
            return params;
        if (Array.isArray(value) && value.length === 0)
            return params;
        for (var i = 0; i < value.length; i++)
            params = params.append(key + "[]", value[i].toString());
        return params;
    };
    AdminBrukergruppeKopierModalService.prototype.Hent = function (id) {
        var params = new HttpParams({ encoder: new WorkingEncoder() });
        params = params.set("id", id != null ? id.toString() : null);
        return this.http.get(this.serviceUrl + "Hent", { params: params, withCredentials: true }).toPromise();
    };
    AdminBrukergruppeKopierModalService.prototype.Kopier = function (data) {
        return this.http.post(this.serviceUrl + "Kopier", data, { withCredentials: true }).toPromise();
    };
    AdminBrukergruppeKopierModalService.ngInjectableDef = i0.defineInjectable({ factory: function AdminBrukergruppeKopierModalService_Factory() { return new AdminBrukergruppeKopierModalService(i0.inject(i1.HttpClient)); }, token: AdminBrukergruppeKopierModalService, providedIn: "root" });
    return AdminBrukergruppeKopierModalService;
}());
export { AdminBrukergruppeKopierModalService };
