﻿import { Component, Input } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AdminFirmaSokemodalService, FirmaDto, BrukerkontoDto, BrukergruppeDto, KontraktDto, HovedkontraktDto } from '../../99 Services/AdminFirmaSokemodal.service';
import { ModalsService } from '../../80 Modals/Service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
    selector: "modal-content",
    templateUrl: "./Sokemodal.html"
})
export class SokemodalComponent {
    constructor(public modalRef: BsModalRef, private service: AdminFirmaSokemodalService, private modalService: ModalsService, private router: Router, private ngxModalService: BsModalService        ) { }

    busy: any;
    sub: Subscription;
    soketekst: string = "";
    sokttekst: string = "";

    firmaer: FirmaDto[] = null;
    brukerkontoer: BrukerkontoDto[] = null;
    brukergrupper: BrukergruppeDto[] = null;
    kontrakter: KontraktDto[] = null;
    hovedkontrakter: HovedkontraktDto[] = null;

    sok() {
        var that = this;
        this.sokttekst = this.soketekst;
        this.firmaer = null;
        this.brukerkontoer = null;
        this.brukergrupper = null;
        this.kontrakter = null;
        this.hovedkontrakter = null;

        this.busy = this.service.GetSearchResult(that.soketekst).then(res => {
            that.firmaer = res.Firmaer;
            that.brukerkontoer = res.Brukerkontoer;
            that.brukergrupper = res.Brukergrupper;
            that.kontrakter = res.Kontrakter;
            that.hovedkontrakter = res.Hovedkontrakter;
        }, fail => {
            that.modalService.Alert(fail.error.Message);
        });
    }

    openFirma(id: number) {
        this.router.navigate(["Admin/Firma/Rediger", id]);
        this.modalRef.hide();
    }

    openBrukerkonto(id: number) {
        this.router.navigate(["Admin/Brukerkonto/Rediger", 0, id]);
        this.modalRef.hide();
    }

    openBrukergruppe(id: number) {
        this.router.navigate(["Admin/Brukergruppe/Oversikt"]);
        this.modalRef.hide();
    }

    openHovedprosjekt(id: number) {
        var that = this;

        this.sub = this.ngxModalService.onHidden.subscribe(() => {
            that.sub.unsubscribe();
            that.router.navigateByUrl("#/Admin/Prosjekt/Rediger+" + id);
        })
        this.modalRef.hide();
    }


}

