﻿import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BrukerPassordService } from '../99 Services/BrukerPassord.service';
import { ModalsService } from '../80 Modals/Service';

@Component({
    selector: "modal-content",
    templateUrl: "./Passord.html"
})
export class PassordComponent implements OnInit {

    busy: any;

    constructor(public modalRef: BsModalRef, private service: BrukerPassordService, private modalsService: ModalsService) { }

    ngOnInit() {
        this.busy = this.service.Hent().then(res => {
            if (!res.Success) {

                setTimeout(() => {
                    this.modalRef.hide();
                }, 300);

                this.modalsService.Alert(res.Message);
            }
        }, feil => { });
    }

    gammeltPass: string = "";
    nyttPass: string = "";
    nyttPass2: string = "";

    Tilbakemelding: string;

    Lagre() {
        this.busy = this.service.LagrePassord(this.gammeltPass, this.nyttPass, this.nyttPass2).then(res => {
            if (!res.Success) {
                this.Tilbakemelding = res.Message || "   ";
            } else {
                this.modalRef.hide();
                this.modalsService.Alert(res.Message);
            }
        }, feil => { });
    }
}