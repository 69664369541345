﻿import { Component, ViewChild, ElementRef } from '@angular/core';
import { AdminFirmaOversiktService, FirmaDto } from '../../99 Services/AdminFirmaOversikt.service';
import { ModalsService } from '../../80 Modals/Service';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { SokemodalComponent } from './Sokemodal';

declare let $: any;

@Component({
    selector: "firma-oversikt",
    templateUrl: "./Oversikt.html",
    styleUrls: ["./Oversikt.css"]
})
export class FirmaOversiktComponent {

    constructor(private service: AdminFirmaOversiktService, private modalService: ModalsService, private titleService: Title, private router: Router, private bsModalService: BsModalService) { }

    busy: any;
    slettede: boolean = false;
    sortering: number = 2;
    reversert: boolean = false;
    firmaer: FirmaDto[] = null;

    HentTilbakeMelding: string;
    SletteMelding: string;
    EgetFirmaMelding: string;
    KanIkkeRedigereSletteFirmaMelding: string;

    @ViewChild("Tittel") TittelRef: ElementRef;
    @ViewChild("HentTilbakeMelding") HentTilbakeMeldingRef: ElementRef;
    @ViewChild("SletteMelding") SletteMeldingRef: ElementRef;
    @ViewChild("EgetFirmaMeldingTekst") EgetFirmaMeldingRef: ElementRef;
    @ViewChild("KanIkkeRedigereSletteFirmaMelding") KanIkkeRedigereSletteFirmaMeldingRef: ElementRef;

    ngAfterViewInit() {
        this.titleService.setTitle($(this.TittelRef.nativeElement).text());
        this.HentTilbakeMelding = $(this.HentTilbakeMeldingRef.nativeElement).text();
        this.SletteMelding = $(this.SletteMeldingRef.nativeElement).text();
        this.EgetFirmaMelding = $(this.EgetFirmaMeldingRef.nativeElement).text();
        this.KanIkkeRedigereSletteFirmaMelding = $(this.KanIkkeRedigereSletteFirmaMeldingRef.nativeElement).text();
    }

    ngOnInit() {
        this.oppdater();
    }

    oppdater() {
        var that = this;
        this.busy = this.service.HentOversikt(that.slettede, that.sortering, that.reversert)
            .then(res => {
                that.firmaer = res;
            }, fail => {
                that.modalService.Alert(fail.error.Message);
            });
    }

    slett(id: number, restore: boolean) {
        var that = this;
        this.modalService.Confirm(restore ? that.HentTilbakeMelding : that.SletteMelding, function () {
            that.busy = that.service.Slett(id)
                .then(res => that.oppdater(),
                    fail => {
                        that.modalService.Alert(fail.error.Message);
                    });
        });
    }

    sort(id: number) {
        if (this.sortering == id)
            this.reversert = !this.reversert;

        this.sortering = id;
        this.oppdater();
    }

    rediger(id: number, firma: FirmaDto) {
        var that = this;
        if (firma != null && firma.Slettet != null) {
            this.modalService.Alert(that.KanIkkeRedigereSletteFirmaMelding);
            return;
        }
        this.router.navigate(["Admin/Firma/Rediger", id]);
    }

    sok() {
        this.bsModalService.show(SokemodalComponent, { class: "modal-xl sokemodal" });
    }
}
