import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var RedigerProsessflytDto = /** @class */ (function () {
    function RedigerProsessflytDto() {
        this.Variabeltyper = [];
        this.Roller = [];
        this.Hovedstatuser = [];
        this.Skjemaer = [];
        this.OppforingKanOpprettesAv = [];
        this.Prosessflytgrupper = [];
    }
    return RedigerProsessflytDto;
}());
export { RedigerProsessflytDto };
var HentLikeNummerserierDto = /** @class */ (function () {
    function HentLikeNummerserierDto() {
    }
    return HentLikeNummerserierDto;
}());
export { HentLikeNummerserierDto };
var TestkapittelDto = /** @class */ (function () {
    function TestkapittelDto() {
        this.Testtabeller = [];
    }
    return TestkapittelDto;
}());
export { TestkapittelDto };
var VariabeltypeDto = /** @class */ (function () {
    function VariabeltypeDto() {
    }
    return VariabeltypeDto;
}());
export { VariabeltypeDto };
var RolleDto = /** @class */ (function () {
    function RolleDto() {
    }
    return RolleDto;
}());
export { RolleDto };
var HovedstatusDto = /** @class */ (function () {
    function HovedstatusDto() {
    }
    return HovedstatusDto;
}());
export { HovedstatusDto };
var SkjemaDto = /** @class */ (function () {
    function SkjemaDto() {
    }
    return SkjemaDto;
}());
export { SkjemaDto };
var OppforingKanOpprettesAvDto = /** @class */ (function () {
    function OppforingKanOpprettesAvDto() {
        this.KanOpprettesAvRoller = [];
    }
    return OppforingKanOpprettesAvDto;
}());
export { OppforingKanOpprettesAvDto };
var ProsessflytDto = /** @class */ (function () {
    function ProsessflytDto() {
        this.Statuser = [];
        this.Prosesstrinn = [];
        this.Variabler = [];
        this.NormalverdierRolleTilgang = [];
        this.KanRelateresTilProsessflytgrupper = [];
    }
    return ProsessflytDto;
}());
export { ProsessflytDto };
var StatusDto = /** @class */ (function () {
    function StatusDto() {
    }
    return StatusDto;
}());
export { StatusDto };
var ProsesstrinnDto = /** @class */ (function () {
    function ProsesstrinnDto() {
        this.BehandlesAvRoller = [];
        this.Handlinger = [];
        this.AssisterendeRollerListe = [];
        this.OpprettProsessflyterListe = [];
    }
    return ProsesstrinnDto;
}());
export { ProsesstrinnDto };
var HandlingDto = /** @class */ (function () {
    function HandlingDto() {
        this.VarslingRolleIder = [];
        this.Rolletilgang = [];
        this.VarslingRollerListe = [];
    }
    return HandlingDto;
}());
export { HandlingDto };
var RolleTilgangDto = /** @class */ (function () {
    function RolleTilgangDto() {
    }
    return RolleTilgangDto;
}());
export { RolleTilgangDto };
var OpprettOppforingDto = /** @class */ (function () {
    function OpprettOppforingDto() {
    }
    return OpprettOppforingDto;
}());
export { OpprettOppforingDto };
var ProsessvariabelDto = /** @class */ (function () {
    function ProsessvariabelDto() {
    }
    return ProsessvariabelDto;
}());
export { ProsessvariabelDto };
var ProsessflytgruppeDto = /** @class */ (function () {
    function ProsessflytgruppeDto() {
    }
    return ProsessflytgruppeDto;
}());
export { ProsessflytgruppeDto };
var TesttabellDto = /** @class */ (function () {
    function TesttabellDto() {
        this.Forberedelser = [];
    }
    return TesttabellDto;
}());
export { TesttabellDto };
var ForberedelseDto = /** @class */ (function () {
    function ForberedelseDto() {
    }
    return ForberedelseDto;
}());
export { ForberedelseDto };
var AksjonsDto = /** @class */ (function () {
    function AksjonsDto() {
    }
    return AksjonsDto;
}());
export { AksjonsDto };
var WorkingEncoder = /** @class */ (function () {
    function WorkingEncoder() {
    }
    WorkingEncoder.prototype.encodeKey = function (key) { return encodeURIComponent(key); };
    WorkingEncoder.prototype.encodeValue = function (value) { return encodeURIComponent(value); };
    WorkingEncoder.prototype.decodeKey = function (key) { return decodeURIComponent(key); };
    WorkingEncoder.prototype.decodeValue = function (value) { return decodeURIComponent(value); };
    return WorkingEncoder;
}());
export { WorkingEncoder };
var AdminProsessflytRedigerService = /** @class */ (function () {
    function AdminProsessflytRedigerService(http) {
        this.http = http;
        this.serviceUrl = environment.APIUrl + 'AdminProsessflytRediger/';
    }
    AdminProsessflytRedigerService.prototype.addArray = function (params, key, value) {
        if (typeof value === "undefined" || value === null)
            return params;
        if (Array.isArray(value) && value.length === 0)
            return params;
        for (var i = 0; i < value.length; i++)
            params = params.append(key + "[]", value[i].toString());
        return params;
    };
    AdminProsessflytRedigerService.prototype.Hent = function (id) {
        var params = new HttpParams({ encoder: new WorkingEncoder() });
        params = params.set("id", id != null ? id.toString() : null);
        return this.http.get(this.serviceUrl + "Hent", { params: params, withCredentials: true }).toPromise();
    };
    AdminProsessflytRedigerService.prototype.HentLikeNummerserier = function (nummerserie, id) {
        var params = new HttpParams({ encoder: new WorkingEncoder() });
        params = params.set("nummerserie", nummerserie != null ? nummerserie.toString() : null);
        params = params.set("id", id != null ? id.toString() : null);
        return this.http.get(this.serviceUrl + "HentLikeNummerserier", { params: params, withCredentials: true }).toPromise();
    };
    AdminProsessflytRedigerService.prototype.Lagre = function (data) {
        return this.http.post(this.serviceUrl + "Lagre", data, { withCredentials: true }).toPromise();
    };
    AdminProsessflytRedigerService.prototype.GenererTestscript = function (id) {
        var params = new HttpParams({ encoder: new WorkingEncoder() });
        params = params.set("id", id != null ? id.toString() : null);
        return this.http.get(this.serviceUrl + "GenererTestscript", { params: params, withCredentials: true }).toPromise();
    };
    AdminProsessflytRedigerService.ngInjectableDef = i0.defineInjectable({ factory: function AdminProsessflytRedigerService_Factory() { return new AdminProsessflytRedigerService(i0.inject(i1.HttpClient)); }, token: AdminProsessflytRedigerService, providedIn: "root" });
    return AdminProsessflytRedigerService;
}());
export { AdminProsessflytRedigerService };
