﻿import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { AdminFirmaRedigerService, FirmaDto, BrukerkontoDto, BrukergruppeDto } from '../../99 Services/AdminFirmaRediger.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalsService } from '../../80 Modals/Service';
import { Title } from '@angular/platform-browser';
import { LoginService } from '../../99 Services/Login.service';

declare let $: any;

@Component({
    selector: "firma-rediger",
    templateUrl: "./Rediger.html",
    styleUrls: ["./Rediger.css"]
})
export class FirmaRedigerComponent {

    constructor(
        private service: AdminFirmaRedigerService,
        private route: ActivatedRoute,
        private modalService: ModalsService,
        private titleService: Title,
        private router: Router,
        private loginService: LoginService
    ) { }

    busy: any;
    sub: Subscription;
    firmaId: number;
    firma: FirmaDto = null;
    brukergrupper: BrukergruppeDto[] = null;
    visSlettede: boolean;

    EgenBrukerMelding: string;
    SlettBrukerConfirm: string;

    @ViewChild("Tittel") TittelRef: ElementRef;
    @ViewChild("EgenBrukerMeldingTekst") EgenBrukerMeldingRef: ElementRef;
    @ViewChild("SlettBrukerConfirmTekst") SlettBrukerConfirmRef: ElementRef;

    ngAfterViewInit() {
        this.titleService.setTitle($(this.TittelRef.nativeElement).text());
        this.EgenBrukerMelding = $(this.EgenBrukerMeldingRef.nativeElement).text();
        this.SlettBrukerConfirm = $(this.SlettBrukerConfirmRef.nativeElement).text();
    }

    ngOnInit() {
        var that = this;
        this.sub = this.route.params.subscribe(params => {
            var id = parseInt(params['id']);
            that.firmaId = id;
            that.hent();
        });
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    hent() {
        var that = this;
        this.busy = this.service.Hent(this.firmaId).then(res => {
            that.firma = res.Firma;
            that.brukergrupper = res.Brukergrupper;
        }, fail => {
            that.modalService.Alert(fail.error.Message);
        });
    }

    lagre() {
        var that = this;
        this.busy = this.service.Lagre(that.firma).then(res => {
            if (that.firmaId != res)
                that.router.navigate(["Admin/Firma/Rediger", res], { replaceUrl: true });
        }, fail => {
            that.modalService.Alert(fail.error.Message);
        });

    }

    slettBruker(index: number) {
        var that = this;
        this.modalService.Confirm(that.SlettBrukerConfirm, function () {
            that.firma.Brukerkontoer[index].Slettet = true;
        });
    }

    redigerBruker(id: number) {
        this.router.navigate(["Admin/Brukerkonto/Rediger", this.firmaId, id]);
    }

    loggInnSom(brukerId: number) {
        var that = this;
        this.busy = this.loginService.Login2(brukerId).then(result => {
            window.localStorage.setItem("username2", result.Username);
            window.localStorage.setItem("role", result.Role);
            window.localStorage.setItem("leder", result.Leder);
            window.localStorage.setItem("entreprenor", result.Entreprenor);
            window.localStorage.setItem("oppdelinnboks", result.OppdelInnboks);
            window.localStorage.setItem("oppdelalle", result.OppdelAlle);

            window.localStorage.setItem("rapportmalebrev", result.VisRapportMalebrev);
            window.localStorage.setItem("rapportoversikt", result.VisRapportOversikt);
            window.localStorage.setItem("rapportoppforinger", result.VisRapportOppforinger);
            window.localStorage.setItem("rapportprosessdiagrammer", result.VisRapportProsessdiagrammer);
            window.localStorage.setItem("rapportoversiktkravue", result.VisRapportOversiktKravUE);

            window.location.href = "#/";
        }, fail => {
            that.modalService.Alert(fail.error.Message);
        });
    }

    ingenBrukere() {
        return this.visSlettede && this.firma.Brukerkontoer.length == 0 || !this.visSlettede && this.firma.Brukerkontoer.filter(f => f.Slettet != null).length == 0
    }
}
