<form (submit)="retry()">
    <div class="modal-header">
        <h1 data-control="HeaderLbl" class="modal-title">{{Title}}</h1>
    </div>
    <div class="modal-body">
        <p [innerHTML]="MessageLbl"></p>
        <p>
            OBS! Klikk p&aring; pr&oslash;v igjen knappen under for &aring; fortsette og evt. unng&aring; &aring; miste data.
        </p>
    </div>
    <div class="modal-footer">
        <button type="submit" autofocus class="btn btn-danger btn-lg btn-block">
            <span class="glyphicon glyphicon-repeat"></span>
            <span>Pr&oslash;v igjen</span>
        </button>

        <button type="button" class="btn btn-default btn-lg btn-block" (click)="cancel()">
            <span class="glyphicon glyphicon-remove"></span>
            <span>Avbryt</span>
        </button>
    </div>
</form>