﻿import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: "modal-content",
    templateUrl: "./Alert.html"
})
export class AlertComponent {

    public static readonly DefaultAlertOptions: AlertOptions = {};

    @Input() message: string;
    @Input() options: AlertOptions = null;

    constructor(public modalRef: BsModalRef) { }
}

export interface AlertOptions {
}