﻿import { Component, ViewChild, ElementRef } from '@angular/core';
import { ProsjektadminRedigerService, HovedprosjektDto, BrukergruppeDto, BrukerkontoDto } from '../99 Services/ProsjektadminRediger.service';
import { ModalsService } from '../80 Modals/Service';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ProsjektadminKontrakterComponent } from './01 Kontrakter/Kontrakter';

declare let $: any;

@Component({
    selector: "prosjektadmin-rediger",
    templateUrl: "./Rediger.html",
    //styleUrls: ["./Oversikt.css"]
})
export class ProsjektadminRedigerComponent {

    constructor(private service: ProsjektadminRedigerService,
        private modalService: ModalsService,
        private titleService: Title,
        private router: Router,
        private route: ActivatedRoute,
        private bsModalService: BsModalService) { }

    busy: any;
    sub: Subscription;
    hovedprosjektId: number;
    hovedprosjekt: HovedprosjektDto = null;
    brukergrupper: BrukergruppeDto[] = null;
    visSlettedeBrukere: boolean = false;

    select2options: Select2Options = {
        multiple: true,
        escapeMarkup: (markup: string) => { return markup },
        templateSelection: (object: Select2SelectionObject) => { return $("<span/>").html(object.text) },
        templateResult: (object: Select2SelectionObject) => { return $("<span/>").html(object.text) }
    };

    @ViewChild("Tittel") TittelRef: ElementRef;
    
    ngAfterViewInit() {
        this.titleService.setTitle($(this.TittelRef.nativeElement).text());
    }

    ngOnInit() {
        var that = this;
        this.sub = this.route.params.subscribe(params => {
            that.hovedprosjektId = parseInt(params['hovedprosjektId']);
            that.oppdater();
        });
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    oppdater() {
        var that = this;
        this.busy = this.service.Hent(that.hovedprosjektId)
            .then(res => {
                that.hovedprosjekt = res.Hovedprosjekt;
                that.brukergrupper = res.Brukergrupper;
            }, fail => {
                that.modalService.Alert(fail.error.Message);
            });
    }

    manglerRoller() {
        if (this.hovedprosjekt == null) return false;
        var mangler = this.hovedprosjekt.Hovedkontrakter.some((hovedkontrakt) => {
            return hovedkontrakt.ManglendeRoller.length > 0;
        });
        return mangler;
    }


    redigerBrukergruppe(id: number) {
        this.router.navigate(["Prosjektadmin/Brukergruppe/Rediger", id]);
    }


    redigerKontrakter(hovedkontraktId: number) {
        this.bsModalService.show(ProsjektadminKontrakterComponent, {
            initialState: {
                hovedkontraktId: hovedkontraktId,
                onCloseFunc: () => { this.oppdater() }
            },
            class: "modal-xl"
        });
    }

    lastNedExcel() {
        this.service.LastNedTilExcel(this.hovedprosjektId);
    }

}
