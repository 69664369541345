﻿import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: "modal-content",
    templateUrl: "./Confirm.html"
})
export class ConfirmComponent {

    public static readonly DefaultConfirmOptions: ConfirmOptions = {};

    @Input() message: string;
    @Input() options: ConfirmOptions = null;
    @Input() okfunc: Function = null;
    @Input() cancelfunc: Function = null;

    constructor(public modalRef: BsModalRef) { }

    ok() {
        if (this.okfunc) this.okfunc();
        this.modalRef.hide();
    }

    cancel() {
        if (this.cancelfunc) this.cancelfunc();
        this.modalRef.hide();
    }

}

export interface ConfirmOptions {
}