﻿// ********************************************************************
// ***                                                              ***
// ***            DO NOT MODIFY THIS FILE MANUALLY!                 ***
// *** This file was generated by the API2ANGULAR Service Generator ***
// ***                                                              ***
// ********************************************************************

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpParameterCodec } from '@angular/common/http';
import { environment } from '../../environments/environment';


export class WorkingEncoder implements HttpParameterCodec {
    encodeKey(key: string): string { return encodeURIComponent(key); }
    encodeValue(value: string): string { return encodeURIComponent(value); }
    decodeKey(key: string): string { return decodeURIComponent(key); }
    decodeValue(value: string): string { return decodeURIComponent(value); }
}

@Injectable({
   providedIn: "root"
})
export class LoginService {
    private serviceUrl = environment.APIUrl + 'Login/';

    constructor(private http: HttpClient) { } 

    private addArray(params: HttpParams, key: string, value: any): HttpParams {
        if (typeof value === "undefined" || value === null) return params;
        if (Array.isArray(value) && value.length === 0) return params;
        for (let i = 0; i < value.length; i++) params = params.append(key + "[]", value[i].toString());
        return params;
    }

    public Login(login: string): Promise<any> {
        let params = new HttpParams({ encoder: new WorkingEncoder() })
        params = params.set("login",login != null ? login.toString() : null);
        return <Promise<any>>this.http.get(this.serviceUrl + "Login", { params, withCredentials: true }).toPromise();
    }

    public Login2(id: number): Promise<any> {
        let params = new HttpParams({ encoder: new WorkingEncoder() })
        params = params.set("id",id != null ? id.toString() : null);
        return <Promise<any>>this.http.get(this.serviceUrl + "Login2", { params, withCredentials: true }).toPromise();
    }

    public Ping(): Promise<any> {
        return <Promise<any>>this.http.get(this.serviceUrl + "Ping", { withCredentials: true }).toPromise();
    }

    public OppdaterPassord(oldlogin: string, newlogin: string): Promise<any> {
        let params = new HttpParams({ encoder: new WorkingEncoder() })
        params = params.set("oldlogin",oldlogin != null ? oldlogin.toString() : null);
        params = params.set("newlogin",newlogin != null ? newlogin.toString() : null);
        return <Promise<any>>this.http.get(this.serviceUrl + "OppdaterPassord", { params, withCredentials: true }).toPromise();
    }
}
