<div>
    <nav class="Menu navbar navbar-default navbar-fixed-top" [ngClass]="tellere?.MenyClass">
        <div *ngIf="isTest" style="float:left; padding:10px; font-size: 24px;">
            <b class="text-danger"><u>TESTSYSTEM</u></b>
        </div>
        
        <div class="container">
            <div class="navbar-header">
                <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#Meny" aria-expanded="false">
                    <span class="sr-only">Toggle navigation</span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                </button>
                <a href="#/" class="navbar-brand hidden-sm" style="display: flex; align-items: center;">
                    <img src="Images/BIM2share.png" alt="BIM2Share" style="height:2.2em;margin-right:0.3em" class="pull-left" />
                    <h3 style="margin: 0">BIM2Share</h3><br />
                </a>
                <a href="#/" class="navbar-brand visible-sm" style="display: flex; align-items: center;">
                    <img src="Images/BIM2share.png" alt="BIM2Share" style="height:2.2em;" />
                </a>
                <!--<span class="navbar-brand text-danger" *ngIf="isTest">
                    <b class="text-danger"><u>TESTSYSTEM</u></b>
                </span>-->
            </div>

            <ul id="Meny" class="nav navbar-nav navbar-right navbar-collapse collapse" style="margin:0" *ngIf="bruker">
                <li style="padding: 10px; max-width:250px">
                    <select2 [options]="select2optionsSingle" [data]="hovedkontrakter" [value]="filterHovedkontraktId" (valueChanged)="hovedkontrakterChanged($event)" [class.hidden]="hovedkontrakter == null || hovedkontrakter?.length == 0"></select2>
                </li>
                <li><a href="#/Innboks/Opprett"><span i18n="@@appmeny1">Ny</span>&nbsp;<span class="hidden-sm" i18n="@@appmeny2">oppføring</span>&nbsp;<span class="glyphicon glyphicon-plus-sign" style="position:relative;top:3px;"></span></a></li>
                <li class="dropdown">
                    <a class="dropdown-toggle" data-toggle="dropdown">
                        <span class="glyphicon glyphicon-envelope"></span>&nbsp;<span i18n="@@appmeny3">Meldinger</span>&nbsp;<span class="badge" [ngClass]="{ &#39;innboks-frist-utgatt&#39;: tellere?.InnboksFristUtgaatt, &#39;.innboks-frist-utgar&#39;: tellere?.InnboksFristUtgaarIdag }">{{tellere?.Innboksantall}}</span>&nbsp;<span class="caret"></span>
                    </a>
                    <ul class="dropdown-menu" role="menu">
                        <li *ngIf="bruker.OppdelInnboks" class="dropdown-submenu">
                            <a href="#/Innboks/Oversikt+0"><span i18n="@@appmeny4">Innboks</span>&nbsp;<span class="badge" [ngClass]="{ &#39;innboks-frist-utgatt&#39;: tellere?.InnboksFristUtgaatt, &#39;.innboks-frist-utgar&#39;: tellere?.InnboksFristUtgaarIdag }">{{tellere?.Innboksantall}}</span></a>
                            <ul *ngIf="tellere?.Innboksantall > 0" class="dropdown-menu" role="menu">
                                <li *ngFor="let innboksdel of tellere?.InnboksDelt">
                                    <a href="#/Innboks/Oversikt+{{innboksdel.Modus}}+G{{innboksdel.Id}}">{{innboksdel.Navn}}&nbsp;<span class="badge">{{innboksdel.Antall}}</span></a>
                                </li>
                            </ul>
                        </li>
                        <li *ngIf="!bruker.OppdelInnboks"><a href="#/Innboks/Oversikt+0"><span i18n="@@appmeny5">Innboks</span>&nbsp;<span class="badge" [ngClass]="{ &#39;innboks-frist-utgatt&#39;: tellere?.InnboksFristUtgaatt, &#39;.innboks-frist-utgar&#39;: tellere?.InnboksFristUtgaarIdag }">{{tellere?.Innboksantall}}</span></a></li>
                        <li><a href="#/Innboks/Oversikt+4"><span i18n="@@appmeny6">Målebrev</span>&nbsp;<span class="badge">{{tellere?.Maalebrevantall}}</span></a></li>
                        <li class="dropdown-submenu">
                            <a style="cursor:default"><span i18n="@@appmeny7">Mine</span>&nbsp;<span class="visible-lg" i18n="@@appmeny36">oppføringer</span></a>
                            <ul class="dropdown-menu">
                                <li><a href="#/Innboks/Oversikt+3"><span i18n="@@appMenyMeny4">Mine aktive oppføringer</span>&nbsp;<span class="badge">{{tellere?.Mineantall}}</span></a></li>
                                <li><a href="#/Innboks/Oversikt+5"><span i18n="@@appMenyMeny5">Oppføringer startet av meg</span>&nbsp;<span class="badge">{{tellere?.StartetAvBrukerAntall}}</span></a></li>
                                <li><a href="#/Innboks/Oversikt+6"><span i18n="@@appMenyMeny6">Oppføringer behandlet av meg</span>&nbsp;<span class="badge">{{tellere?.BehandletAvBrukerAntall}}</span></a></li>
                                <li><a href="#/Innboks/Oversikt+7"><span i18n="@@appMenyMeny7">Alle mine oppføringer</span>&nbsp;<span class="badge">{{tellere?.AlleMineAntall}}</span></a></li>
                            </ul>
                        </li>
                        <li><a href="#/Innboks/Oversikt+1"><span i18n="@@appmeny8">Utkast</span>&nbsp;<span class="badge">{{tellere?.Utkastantall}}</span></a></li>
                        <li *ngIf="!bruker.OppdelAlle"><a href="#/Innboks/Oversikt+2"><span i18n="@@appmeny9">Alle</span>&nbsp;<span class="visible-lg" i18n="@@appmeny10">oppføringer</span>&nbsp;<span class="badge">{{tellere?.Alleantall}}</span></a></li>
                        <li *ngIf="bruker.OppdelAlle" class="dropdown-submenu">
                            <a href="#/Innboks/Oversikt+2"><span i18n="@@appmeny11">Alle oppføringer</span>&nbsp;<span class="badge">{{tellere?.Alleantall}}</span></a>
                            <ul *ngIf="tellere?.Alleantall > 0" class="dropdown-menu">
                                <li *ngFor="let innboksdel of tellere?.AlleDelt">
                                    <a href="#/Innboks/Oversikt+{{innboksdel.Modus}}+G{{innboksdel.Id}}">{{innboksdel.Navn}} <span class="badge">{{innboksdel.Antall}}</span></a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li *ngIf="bruker.Admin || bruker.VisRapportMalebrev || bruker.VisRapportOversikt || bruker.VisRapportOppforinger || bruker.VisRapportProsessdiagrammer">
                    <a class="dropdown-toggle" data-toggle="dropdown"><span i18n="First half of the word Reports@@appmeny12">Rap</span><span class="hidden-sm" i18n="Second half of the word Reports@@appmeny13">porter</span> <span class="caret"></span></a>
                    <ul class="dropdown-menu" role="menu">
                        <li *ngIf="bruker.Admin"><a href="#/Admin/Arkivoverføring" i18n="@@appmeny14">Arkivoverføring</a></li>
                        <li *ngIf="bruker.Admin"><a href="#/Admin/Logg/Hendelseslogg" i18n="@@appmeny15">Hendelseslogg</a></li>
                        <li *ngIf="bruker.Admin"><a href="#/Admin/Logg/Overføringsfeil" i18n="@@appmeny16">Overføringsfeil</a></li>
                        <li role="separator" class="divider" *ngIf="bruker.Admin"></li>
                        <li *ngIf="bruker.VisRapportMalebrev"><a href="#/Rapport/Målebrev" i18n="@@appmeny17">Målebrev</a></li>
                        <li *ngIf="bruker.VisRapportOversikt"><a href="#/Rapport/Oversiktsrapport" i18n="@@appmeny18">Oversiktsrapport</a></li>
                        <li *ngIf="bruker.VisRapportOppforinger"><a href="#/Rapport/Oppforinger" i18n="@@appmeny19">Oppføringer per hovedprosjekt</a></li>
                        <li *ngIf="bruker.VisRapportProsessdiagrammer"><a href="#/Rapport/Prosessdiagrammer" i18n="@@appmeny20">Prosessdiagrammer</a></li>
                    </ul>
                </li>

                <li *ngIf="bruker.VisRapportOversiktKravUE">
                    <a class="dropdown-toggle" data-toggle="dropdown">
                        <span i18n="@@appmeny37">Hist</span>
                        <span class="hidden-sm" i18n="@@appmeny38">orikk</span>&nbsp;
                        <span class="caret"></span>
                    </a>
                    <ul class="dropdown-menu" role="menu">
                        <li data-control="RapportOversiktKravUE"><a href="#/Rapport/OversiktKravUE" i18n="@@appmeny39">Oversikt krav fra UE</a></li>
                    </ul>
                </li>

                <li *ngIf="bruker.Admin">
                    <a class="dropdown-toggle" data-toggle="dropdown"><span i18n="@@appmeny21">Admin</span>&nbsp;<span class="caret"></span></a>
                    <ul class="dropdown-menu" role="menu">
                        <li><a href="#/Admin/Prosjekt/Oversikt"><span class="glyphicon glyphicon-folder-close"></span>&nbsp;<span i18n="@@appmeny22">Prosjekter/hovedkontrakter</span></a></li>
                        <li><a href="#/Admin/Firma/Oversikt"><span class="glyphicon glyphicon-user"></span>&nbsp;<span i18n="@@appmeny23">Firmaer/brukere</span></a></li>
                        <li><a href="#/Admin/Brukergruppe/Oversikt"><span class="fa fa-users"></span>&nbsp;<span i18n="@@appMenyMeny1">Brukergrupper</span></a></li>
                        <li><a href="#/Admin/Kontraktstype/Oversikt"><span class="glyphicon glyphicon-list-alt"></span>&nbsp;<span i18n="@@appmeny24">Kontraktstyper</span></a></li>
                        <li class="divider"></li>
                        <li><a href="#/Admin/Prosessflytgruppe/Oversikt/2"><span class="glyphicon glyphicon-cog"></span>&nbsp;<span i18n="@@appmeny25">Prosessflyter</span></a></li>
                        <li><a href="#/Admin/Rolle/Oversikt"><span class="glyphicon glyphicon-list-alt"></span>&nbsp;<span i18n="@@appmeny26">Roller</span></a></li>
                        <li><a href="#/Admin/Skjema/Oversikt+0"><span class="glyphicon glyphicon-list-alt"></span>&nbsp;<span i18n="@@appmeny27">Skjemaer</span> </a></li>
                        <li><a href="#/Admin/Hovedstatus/Oversikt"><span class="glyphicon glyphicon-list-alt"></span>&nbsp;<span i18n="@@appmeny28">Hovedstatuser</span> </a></li>
                        <li class="divider"></li>
                        <li><a href="#/Admin/MålebrevkontraktImport"><span class="glyphicon glyphicon-list-alt"></span>&nbsp;<span i18n="@@appmeny29">Import målebrevkont.</span></a></li>
                        <li class="divider"></li>
                        <li><a href="#/Admin/Driftsmeldinger"><span class="glyphicon glyphicon-info-sign"></span>&nbsp;<span i18n="@@appMenyMeny3">Driftsmeldinger</span></a></li>
                    </ul>
                </li>
                <li>
                    <a style="width:auto;max-width:216px;" class="dropdown-toggle" data-toggle="dropdown">
                        <span style="display:inline-block; vertical-align:top;"><span class="glyphicon glyphicon-user"></span></span>
                        <span style="display: inline-block;max-width: 78%;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;" [style.color]="bruker.Impersonating ? &#39;orangered&#39; : &#39;&#39;">
                            &nbsp;{{bruker.Brukernavn || bruker.Login}}
                        </span>
                        <span style="display:inline-block; vertical-align:top;"><span class="caret"></span></span>
                    </a>
                    <ul class="dropdown-menu" role="menu">
                        <li>
                            <a> <span i18n="@@appmeny30">Språk</span> <span class="pull-right flag-icon flag-icon-squared" [ngClass]="{&#39;flag-icon-no&#39; : languageService.currentLang != &#39;en&#39;, &#39;flag-icon-gb&#39;: languageService.currentLang == &#39;en&#39;}"></span></a>
                            <ul class="dropdown-menu sub-menu">
                                <li (click)="selectLanguage(&#39;nb&#39;)">
                                    <a>Norsk <span class="pull-right flag-icon flag-icon-no flag-icon-squared"></span></a>
                                </li>
                                <li (click)="selectLanguage(&#39;en&#39;)">
                                    <a>English <span class="pull-right flag-icon flag-icon-gb flag-icon-squared"></span></a>
                                </li>
                            </ul>
                        </li>
                        <li class="divider"></li>
                        <li *ngIf="bruker.Prosjektadmin"><a href="#/Prosjektadmin/Oversikt" i18n="@@appMenyMeny2">Brukeradmin</a></li>
                        <li *ngIf="bruker.Prosjektadmin" class="divider"></li>
                        <li><a (click)="changePassword()" i18n="@@appmeny32">Endre passord ...</a></li>
                        <li class=""><a href="#/Bruker/Innstillinger" i18n="@@appmeny33">Innstillinger ...</a></li>
                        <li><a href="https://hjelp.bim2share.no/arbeidsflyt" target="_blank" i18n="@@appmeny34">Hjelpesider</a></li>
                        <li class="divider"></li>
                        <li><a (click)="logut()" i18n="@@appmeny35">Logg ut</a></li>
                    </ul>
                </li>
            </ul>
        </div>
    </nav>
</div>