<header class="modal-header">
    <button type="button" class="close" (click)="modalRef.hide()" aria-hidden="true">&times;</button>
    <h1 i18n="@@appMenyPassord1">Endre passord</h1>
</header>
<div class="modal-body" [ng-busy]="busy">
    <div class="form-group">
        <label i18n="@@appMenyPassord2">Gammelt passord:</label>
        <input class="form-control" [(ngModel)]="gammeltPass" type="password" required="required" />
    </div>
    <div class="form-group">
        <label i18n="@@appMenyPassord3">Nytt passord:</label>
        <input class="form-control" [(ngModel)]="nyttPass" type="password" required="required" />
    </div>
    <div class="form-group">
        <label i18n="@@appMenyPassord4">Gjenta nytt passord:</label>
        <input class="form-control" [(ngModel)]="nyttPass2" type="password" required="required" />
    </div>
    <div class="form-group" *ngIf="Tilbakemelding">
        <div class="alert alert-danger">
            <strong [innerHTML]="Tilbakemelding"></strong>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-success" (click)="Lagre()" i18n="@@appMenyPassord5">Lagre</button>
    <button type="button" class="btn btn-default" (click)="modalRef.hide()" i18n="@@appMenyPassord6">Avbryt</button>
</div>

<div style="display:none">
    <div data-control="Title_1" i18n="@@appMenyPassord7">Endre passord</div>
    <div data-control="Alert_1" i18n="@@appMenyPassord8">Ditt passord er endret!</div>
</div>