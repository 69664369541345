﻿import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({ name: 'dateinput' })
export class DateinputPipe extends DatePipe implements PipeTransform {
    transform(value: any, args?: any): any {
        if (value == null) return "";
        return super.transform(value, "yyyy-MM-dd");
    }

}
