    <div class="container-fluid" [ng-busy]="busy">
        <div class="pull-right">
            <button id="LagreBtn" class="btn btn-success" (click)="lagre()">
                <span class="glyphicon glyphicon-ok"></span>
                <span i18n="@@app01Admin05ProsessflytgruppeRediger1">Lagre</span>
            </button>
            &nbsp;
            <a onclick="return navback();" class="btn btn-default" tabindex="-1">
                <span class="glyphicon glyphicon-menu-left"></span>
                <span i18n="@@app01Admin05ProsessflytgruppeRediger2">Gå tilbake</span>
            </a>
        </div>
        <div *ngIf="gruppe != null">
            <h1 *ngIf="gruppe.Id == 0" i18n="@@app01Admin05ProsessflytgruppeRediger3">Opprett prosessflytgruppe</h1>
            <h1 *ngIf="gruppe.Id != 0" i18n="@@app01Admin05ProsessflytgruppeRediger4">Rediger prosessflytgruppe</h1>

            <div class="row">
                <div class="col-xs-12 col-md-6">
                    <div class="form-group">
                        <label i18n="@@app01Admin05ProsessflytgruppeRediger5">Navn</label>
                        <input class="form-control" name="Navn" autocomplete="off" maxlength="50" [(ngModel)]="gruppe.Navn" />
                    </div>
                </div>
                <div class="col-xs-12 col-md-6" *ngIf="gruppe.Slettet">
                    <div class="form-group">
                        <label i18n="@@app01Admin05ProsessflytgruppeRediger6">Slettet</label>
                        <label class="form-control text-danger" name="Slettet">{{gruppe.Slettet | datetime}}</label>
                    </div>
                </div>
            </div>
            

            <hr />

            <div class="panel panel-default" *ngIf="gruppe.Id != 0">
                <div class="panel-heading">
                    <div class="pull-right">
                        <label style="margin-right:1em"><input type="checkbox" [(ngModel)]="visSlettede" />&nbsp;<span i18n="@@app01Admin05ProsessflytgruppeRediger7">Vis slettede</span></label>
                        <button class="btn btn-primary" (click)="redigerProsessflyt(0)">
                            <span class="glyphicon glyphicon-plus-sign"></span>
                            <span i18n="@@app01Admin05ProsessflytgruppeRediger8">Opprett ny</span>
                        </button>
                    </div>

                    <h1 i18n="@@app01Admin05ProsessflytgruppeRediger9">Prosessflyter</h1>
                </div>
                <table class="table table-striped table-hover table-bordered">
                    <thead>
                        <tr class="sortable">
                            <th (click)="sort(1)"><span i18n="@@app01Admin05ProsessflytgruppeRediger10">Id</span><span class="glyphicon glyphicon-triangle-top sortering" [class.show]="sortering==1"></span><span class="glyphicon glyphicon-triangle-bottom sortering" [class.show]="sortering==-1"></span></th>
                            <th (click)="sort(2)"><span i18n="@@app01Admin05ProsessflytgruppeRediger11">Navn</span><span class="glyphicon glyphicon-triangle-top sortering" [class.show]="sortering==2"></span><span class="glyphicon glyphicon-triangle-bottom sortering" [class.show]="sortering==-2"></span></th>
                            <th (click)="sort(3)"><span i18n="@@app01Admin05ProsessflytgruppeRediger12">Nr</span><span class="glyphicon glyphicon-triangle-top sortering" [class.show]="sortering==3"></span><span class="glyphicon glyphicon-triangle-bottom sortering" [class.show]="sortering==-3"></span></th>
                            <th (click)="sort(4)"><span i18n="@@app01Admin05ProsessflytgruppeRediger13">Kode</span><span class="glyphicon glyphicon-triangle-top sortering" [class.show]="sortering==4"></span><span class="glyphicon glyphicon-triangle-bottom sortering" [class.show]="sortering==-4"></span></th>
                            <th (click)="sort(5)"><span i18n="@@app01Admin05ProsessflytgruppeRediger14">Beskrivelse</span><span class="glyphicon glyphicon-triangle-top sortering" [class.show]="sortering==5"></span><span class="glyphicon glyphicon-triangle-bottom sortering" [class.show]="sortering==-5"></span></th>
                            <th (click)="sort(6)"><span i18n="@@app01Admin05ProsessflytgruppeRediger15">Trinn</span><span class="glyphicon glyphicon-triangle-top sortering" [class.show]="sortering==6"></span><span class="glyphicon glyphicon-triangle-bottom sortering" [class.show]="sortering==-6"></span></th>
                            <th (click)="sort(7)"><span i18n="@@app01Admin05ProsessflytgruppeRediger16">Versjon</span><span class="glyphicon glyphicon-triangle-top sortering" [class.show]="sortering==7"></span><span class="glyphicon glyphicon-triangle-bottom sortering" [class.show]="sortering==-7"></span></th>
                            <th (click)="sort(8)"><span i18n="@@app01Admin05ProsessflytgruppeRediger17">Oppdatert</span><span class="glyphicon glyphicon-triangle-top sortering" [class.show]="sortering==8"></span><span class="glyphicon glyphicon-triangle-bottom sortering" [class.show]="sortering==-8"></span></th>
                            <th (click)="sort(9)"><span i18n="@@app01Admin05ProsessflytgruppeRediger18">Antall oppføringer</span><span class="glyphicon glyphicon-triangle-top sortering" [class.show]="sortering==9"></span><span class="glyphicon glyphicon-triangle-bottom sortering" [class.show]="sortering==-9"></span></th>
                            <th (click)="sort(10)"><span i18n="@@app01Admin05ProsessflytgruppeRediger19">Kontraktstyper</span><span class="glyphicon glyphicon-triangle-top sortering" [class.show]="sortering==10"></span><span class="glyphicon glyphicon-triangle-bottom sortering" [class.show]="sortering==-10"></span></th>
                            <th (click)="sort(11)"><span i18n="@@app01Admin05ProsessflytgruppeRediger20">Tilgjengelig for</span><span class="glyphicon glyphicon-triangle-top sortering" [class.show]="sortering==11"></span><span class="glyphicon glyphicon-triangle-bottom sortering" [class.show]="sortering==-11"></span></th>
                            <th (click)="sort(12)"><span i18n="@@app01Admin05ProsessflytgruppeRediger21">Mappenavn i arkiv</span><span class="glyphicon glyphicon-triangle-top sortering" [class.show]="sortering==12"></span><span class="glyphicon glyphicon-triangle-bottom sortering" [class.show]="sortering==-12"></span></th>
                            <th>Skjemaer i bruk</th>
                            <th class="tight"></th>
                            <th class="tight"></th>
                        </tr>
                    </thead>
                    <tbody *ngIf="gruppe.Prosessflyter.length == 0">
                        <tr>
                            <td colspan="100" i18n="@@app01Admin05ProsessflytgruppeRediger22">(ingen)</td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="gruppe.Prosessflyter.length > 0">
                        <ng-container *ngFor="let p of gruppe.Prosessflyter; let i = index">
                            <tr *ngIf="!p.Slettet || p.Slettet &amp;&amp; visSlettede" [class.text-danger]="p.Slettet || gruppe.Slettet" (dblclick)="redigerProsessflyt(p.Id)" tabindex="0">
                                <td>{{p.Id}}</td>
                                <td>{{p.Navn}}</td>
                                <td>{{p.Nummerserie}}</td>
                                <td>{{p.Kode}}</td>
                                <td>{{p.Beskrivelse}}</td>
                                <td>{{p.Trinn}}</td>
                                <td>{{p.Versjonsnr}}</td>
                                <td>{{p.Oppdatert | datetime}}</td>
                                <td>{{p.Antall}}</td>
                                <td><a *ngFor="let k of p.Kontraktstyper" class="badge badge-default" [href]="&#39;#/Admin/Kontraktstype/Rediger+&#39; + k.Id">{{k.Navn}}</a></td>
                                <td>{{p.Tilgang}}</td>
                                <td>{{p.Mappenavn}}</td>
                                <td><a *ngFor="let s of p.Skjemaer" class="badge badge-default" [href]="&#39;#/Admin/Skjema/Rediger+&#39; + s.Id">{{s.Navn}}</a> </td>
                                <td class="nopadd">
                                    <button class="btn btn-default" (click)="slettProsessflyt(i)"><span class="glyphicon glyphicon-trash"></span></button>
                                </td>
                                <td class="nopadd">
                                    <button class="btn btn-default" (click)="redigerProsessflyt(p.Id)"><span class="glyphicon glyphicon-pencil"></span></button>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>

        </div>
    </div>

    <div style="display:none">
        <div #Tittel="" i18n="@@app01Admin05ProsessflytgruppeRediger23">BIM2Share - Rediger prosessflytgruppe</div>
        <div i18n="@@app01Admin05ProsessflytgruppeRediger24" #Text1="">Vil du slette denne prosessflyten?</div>
        <div i18n="@@app01Admin05ProsessflytgruppeRediger25" #Text2="">Vil du hente tilbake denne prosessflyten?</div>
    </div>