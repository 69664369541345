import { enableProdMode, LOCALE_ID, TRANSLATIONS, TRANSLATIONS_FORMAT } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// JIT Translations (ng serve)
declare let require;

let providers = [];

if (environment.production) {
    enableProdMode();
} else {
    const selectedLanguage = localStorage.getItem("userLang");

    if (selectedLanguage) {

        console.log("Angular boot: Selected language:" + selectedLanguage);

        try {
            let translations = require("raw-loader!../Angular/messages." + selectedLanguage + ".xlf");
            console.log("Angular boot: Loaded translation file with length: ", translations.length);

            providers = [
                { provide: TRANSLATIONS, useValue: translations },
                { provide: TRANSLATIONS_FORMAT, useValue: 'xlf' },
                { provide: LOCALE_ID, useValue: selectedLanguage }
            ];
        } catch{
            console.error("Angular boot: Failed to load translations!");
        }
    }
}

platformBrowserDynamic().bootstrapModule(AppModule, {
    providers: providers
}).catch(err => console.log(err));


