﻿// ********************************************************************
// ***                                                              ***
// ***            DO NOT MODIFY THIS FILE MANUALLY!                 ***
// *** This file was generated by the API2ANGULAR Service Generator ***
// ***                                                              ***
// ********************************************************************

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpParameterCodec } from '@angular/common/http';
import { environment } from '../../environments/environment';


export class HentBrukergruppeDto {
    public Brukergruppe: BrukergruppeDto;
    public Hovedkontrakter: HovedkontraktDto[] = [];
    public Hovedprosjekter: HovedprosjektDto[] = [];
    public Admin: boolean;
}

export class BrukergruppeDto {
    public Id: number;
    public Navn: string;
    public Opprettet: string;
    public Brukerkontoer: number[] = [];
    public Kontrakter: KontraktDto[] = [];
    public BrukerkontoListe: BrukerkontoDto[] = [];
    public HovedprosjektId: number;
}

export class HovedkontraktDto {
    public Id: number;
    public Navn: string;
    public Kontraktsnr: string;
    public Kontraktstittel: string;
    public HovedprosjektId: number;
    public Roller: RolleDto[] = [];
}

export class RolleDto {
    public Id: number;
    public Navn: string;
}

export class HovedprosjektDto {
    public Id: number;
    public Navn: string;
}

export class KontraktDto {
    public Id: number;
    public Kontraktsnr: string;
    public Tittel: string;
    public HovedprosjektNavn: string;
    public HovedkontraktId: number;
    public HovedkontraktKontraktsnr: string;
    public HovedkontraktKontraktstittel: string;
    public Opprettet: string;
    public Oppstart: string;
    public Utlopsdato: string;
    public RolleId: number;
    public Innboks: number;
    public Utlopt: boolean;
    public GjeldendeKontraktsnr: string;
    public GjeldendeTittel: string;
    public IBruk: boolean;
    public OrginalHovedkontraktId: number;
    public OrginalGjeldendeKontraktsnr: string;
    public OrginalGjeldendeTittel: string;
    public OrginalOppstart: string;
    public OrginalUtlopsdato: string;
    public OrginalRolleId: number;
    public Hovedkontrakter: HovedkontraktDto[] = [];
    public Roller: RolleDto[] = [];
}

export class BrukerkontoDto {
    public Id: number;
    public Navn: string;
    public Slettet: boolean;
}

export class WorkingEncoder implements HttpParameterCodec {
    encodeKey(key: string): string { return encodeURIComponent(key); }
    encodeValue(value: string): string { return encodeURIComponent(value); }
    decodeKey(key: string): string { return decodeURIComponent(key); }
    decodeValue(value: string): string { return decodeURIComponent(value); }
}

@Injectable({
   providedIn: "root"
})
export class AdminBrukergruppeRedigerService {
    private serviceUrl = environment.APIUrl + 'AdminBrukergruppeRediger/';

    constructor(private http: HttpClient) { } 

    private addArray(params: HttpParams, key: string, value: any): HttpParams {
        if (typeof value === "undefined" || value === null) return params;
        if (Array.isArray(value) && value.length === 0) return params;
        for (let i = 0; i < value.length; i++) params = params.append(key + "[]", value[i].toString());
        return params;
    }

    public Hent(brukergruppeId: number): Promise<HentBrukergruppeDto> {
        let params = new HttpParams({ encoder: new WorkingEncoder() })
        params = params.set("brukergruppeId",brukergruppeId != null ? brukergruppeId.toString() : null);
        return <Promise<HentBrukergruppeDto>>this.http.get(this.serviceUrl + "Hent", { params, withCredentials: true }).toPromise();
    }

    public Lagre(brukergruppe: BrukergruppeDto): Promise<HentBrukergruppeDto> {
        return <Promise<HentBrukergruppeDto>>this.http.post(this.serviceUrl + "Lagre", brukergruppe, { withCredentials: true }).toPromise();
    }
}
