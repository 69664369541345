﻿import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ProsjektadminKontrakterService, KontraktDto, Select2Dto, RolleDto, LagreKontrakterDto, PakrevdeRollerDto } from '../../99 Services/ProsjektadminKontrakter.service';
import { ModalsService } from '../../80 Modals/Service';
import { DateinputPipe } from '../../98 Shared/dateinput.pipe';

declare let $: any;

@Component({
    selector: "modal-content",
    templateUrl: "./Kontrakter.html"
})
export class ProsjektadminKontrakterComponent {
    @Input() hovedkontraktId: number;
    @Input() onCloseFunc: Function;

    visUtlopteKontrakter: boolean = false;
    hovedkontraktnavn: string;
    hovedkontraktKontraktsnr: string;
    hovedkontraktKontraktstittel: string;
    kontrakter: KontraktDto[] = null;
    brukere: Select2Dto[] = null;
    roller: RolleDto[] = null;
    pakrevdeRoller: PakrevdeRollerDto[] = null;
    busy: any;
    manglendeRoller: string = "";

    brukerGruppeSelect2Opts: Select2Options;
    rollerSelect2Opts: Select2Options;

    SletteKontraktMelding: string;
    @ViewChild("SletteKontraktMelding") SletteMeldingRef: ElementRef;
    @ViewChild("BrukerGruppePlaceholder") BrukerGruppePlaceholderRef: ElementRef;
    @ViewChild("RollePlaceholder") RollePlaceholderRef: ElementRef;

    ngAfterViewInit() {
        this.SletteKontraktMelding = $(this.SletteMeldingRef.nativeElement).text();

        this.brukerGruppeSelect2Opts = Object.assign({}, this.select2optionsSingle, {
            placeholder: $(this.BrukerGruppePlaceholderRef.nativeElement).text()
        });
        this.rollerSelect2Opts = Object.assign({}, this.select2optionsSingle, {
            placeholder: $(this.RollePlaceholderRef.nativeElement).text()
        });
    }

    select2optionsSingle: Select2Options = {
        multiple: false,
        escapeMarkup: (markup: string) => { return markup },
        templateSelection: (object: Select2SelectionObject) => { return $("<span/>").html(object.text) },
        templateResult: (object: Select2SelectionObject) => { return $("<span/>").html(object.text) },
        theme: "bootstrap",
        dropdownAutoWidth: true
    };

    constructor(public modalRef: BsModalRef,
        private service: ProsjektadminKontrakterService,
        private modalService: ModalsService) { }

    ngOnInit() {
        var that = this;
        this.busy = this.service.Hent(that.hovedkontraktId).then(res => {
            that.hovedkontraktnavn = res.Hovedkontraktnavn;
            that.kontrakter = res.Kontrakter;
            that.brukere = res.Brukere;
            that.roller = res.Roller;
            that.hovedkontraktKontraktsnr = res.HovedkontraktKontraktsnr;
            that.hovedkontraktKontraktstittel = res.HovedkontraktKontraktstittel
            that.pakrevdeRoller = res.PakrevdeRoller;
            that.sjekkManglendeRoller();
        }, fail => {
            that.modalService.Alert(fail.error.Message);
        });
    }

    lagre() {
        var that = this;
        var data = new LagreKontrakterDto();
        data.Kontrakter = that.kontrakter;
        data.HovedkontraktId = that.hovedkontraktId;

        this.busy = this.service.Lagre(data).then(res => {
            if (that.onCloseFunc) that.onCloseFunc();
            that.modalRef.hide();
        }, fail => {
            that.modalService.Alert(fail.error.Message);
        });
    }

    brukerChanged($event: any, index: number) {
        var kontrakt = this.kontrakter[index];
        kontrakt.BrukerId = $event.value;
    }

    rolleChanged($event: any, index: number) {
        var kontrakt = this.kontrakter[index];
        kontrakt.RolleId = $event.value;
        this.sjekkManglendeRoller();
    }

    sjekkManglendeRoller() {
        var valgteRoller = this.kontrakter.map(m => {
            //this.brukere.find(b => b.id == m.BrukerId)

            if (!m.Utlopt) return m.RolleId
        });

        var mangler: RolleDto[] = [];

        this.pakrevdeRoller.forEach(m => {
            if (m.Roller.every(e => !valgteRoller.some(v => e.id == v))) {
                m.Roller.forEach(f => {
                    if (!mangler.find(r => r.id == f.id))
                        mangler.push(f);
                });
            }
        })

        this.manglendeRoller = "";
        mangler.forEach(m => {
            this.manglendeRoller += (this.manglendeRoller.length == 0 ? "" : ", ") + m.text;
        });
    }

    nyKontrakt() {
        var kontrakt = new KontraktDto();
        kontrakt.Id = 0;
        
        kontrakt.GjeldendeTittel = this.hovedkontraktKontraktstittel;
        kontrakt.GjeldendeKontraktsnr = this.hovedkontraktKontraktsnr;
        kontrakt.Oppstart = new DateinputPipe("en-US").transform(new Date());
        //kontrakt.RolleId = this.roller[0].id;
        //kontrakt.BrukerId = this.brukere[0].children[0].id;
        this.kontrakter.push(kontrakt);
    }

    slettKontrakt(index: number) {
        var that = this;
        this.modalService.Confirm(that.SletteKontraktMelding, function () {
            var kontrakt = that.kontrakter[index];
            if (kontrakt.Id == 0) {
                that.kontrakter.splice(index, 1);
            } else {
                kontrakt.Utlopsdato = new DateinputPipe("en-US").transform(new Date());
                kontrakt.Utlopt = true;
            }
            that.sjekkManglendeRoller();
        });
    }

    
}