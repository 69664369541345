/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./Confirm";
import * as i2 from "ngx-bootstrap/modal/bs-modal-ref.service";
var styles_ConfirmComponent = [];
var RenderType_ConfirmComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ConfirmComponent, data: {} });
export { RenderType_ConfirmComponent as RenderType_ConfirmComponent };
export function View_ConfirmComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "h3", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.ok() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["OK, fortsett!"])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "button", [["class", "btn btn-default"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Avbryt"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.message; _ck(_v, 1, 0, currVal_0); }); }
export function View_ConfirmComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "modal-content", [], null, null, null, View_ConfirmComponent_0, RenderType_ConfirmComponent)), i0.ɵdid(1, 49152, null, 0, i1.ConfirmComponent, [i2.BsModalRef], null, null)], null, null); }
var ConfirmComponentNgFactory = i0.ɵccf("modal-content", i1.ConfirmComponent, View_ConfirmComponent_Host_0, { message: "message", options: "options", okfunc: "okfunc", cancelfunc: "cancelfunc" }, {}, []);
export { ConfirmComponentNgFactory as ConfirmComponentNgFactory };
