/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "./Retry.component";
import * as i3 from "ngx-bootstrap/modal/bs-modal-ref.service";
var styles_RetryComponent = [];
var RenderType_RetryComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RetryComponent, data: {} });
export { RenderType_RetryComponent as RenderType_RetryComponent };
export function View_RetryComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 20, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submit" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i0.ɵnov(_v, 2).onReset() !== false);
        ad = (pd_1 && ad);
    } if (("submit" === en)) {
        var pd_2 = (_co.retry() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 16384, null, 0, i1.ɵangular_packages_forms_forms_bh, [], null, null), i0.ɵdid(2, 4210688, null, 0, i1.NgForm, [[8, null], [8, null]], null, null), i0.ɵprd(2048, null, i1.ControlContainer, null, [i1.NgForm]), i0.ɵdid(4, 16384, null, 0, i1.NgControlStatusGroup, [[4, i1.ControlContainer]], null, null), (_l()(), i0.ɵeld(5, 0, null, null, 2, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "h1", [["class", "modal-title"], ["data-control", "HeaderLbl"]], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["", ""])), (_l()(), i0.ɵeld(8, 0, null, null, 3, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 0, "p", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" OBS! Klikk p\u00E5 pr\u00F8v igjen knappen under for \u00E5 fortsette og evt. unng\u00E5 \u00E5 miste data. "])), (_l()(), i0.ɵeld(12, 0, null, null, 8, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 3, "button", [["autofocus", ""], ["class", "btn btn-danger btn-lg btn-block"], ["type", "submit"]], null, null, null, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 0, "span", [["class", "glyphicon glyphicon-repeat"]], null, null, null, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Pr\u00F8v igjen"])), (_l()(), i0.ɵeld(17, 0, null, null, 3, "button", [["class", "btn btn-default btn-lg btn-block"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(18, 0, null, null, 0, "span", [["class", "glyphicon glyphicon-remove"]], null, null, null, null, null)), (_l()(), i0.ɵeld(19, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Avbryt"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 4).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 4).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 4).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 4).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 4).ngClassValid; var currVal_5 = i0.ɵnov(_v, 4).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 4).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = _co.Title; _ck(_v, 7, 0, currVal_7); var currVal_8 = _co.MessageLbl; _ck(_v, 9, 0, currVal_8); }); }
export function View_RetryComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "Retry", [], null, null, null, View_RetryComponent_0, RenderType_RetryComponent)), i0.ɵdid(1, 49152, null, 0, i2.RetryComponent, [i3.BsModalRef], null, null)], null, null); }
var RetryComponentNgFactory = i0.ɵccf("Retry", i2.RetryComponent, View_RetryComponent_Host_0, {}, {}, []);
export { RetryComponentNgFactory as RetryComponentNgFactory };
