﻿import { Component, ViewChild, ElementRef } from '@angular/core';
import { AdminProsessflytRedigerService, RedigerProsessflytDto, TestkapittelDto, ProsesstrinnDto, HandlingDto, OpprettOppforingDto, StatusDto, ProsessvariabelDto, RolleTilgangDto } from '../../99 Services/AdminProsessflytRediger.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalsService } from '../../80 Modals/Service';
import { Title } from '@angular/platform-browser';
import { forEach } from '@angular/router/src/utils/collection';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Guid } from "guid-typescript";
import { BsModalService } from 'ngx-bootstrap/modal';
import { SourceModalComponent } from './SourceModal';

declare let $: any;
declare let TegnDiagram: any;

@Component({
    selector: 'prosessflytredigercomponent',
    templateUrl: './Rediger.html',
    styleUrls: ['./Rediger.css']
})
export class AdminProsessflytRedigerComponent {

    constructor(
        private service: AdminProsessflytRedigerService,
        private route: ActivatedRoute,
        private router: Router,
        private modalService: ModalsService,
        private titleService: Title,
        private bsModalService: BsModalService
    ) { }

    busy: any;
    sub: Subscription;
    opentab: number = 1;
    prosessflytId: number;

    data: RedigerProsessflytDto;
    testscript: TestkapittelDto[];
    
    visknappetekster: boolean = false;
    
    SlettStatusMelding: string;
    SlettVariabelMelding: string;
    KommerSenereMelding: string;
    SlettHandlingMelding: string;
    SlettProsesstrinnMelding: string;
    LagreKopiMelding: string;
    Kopi: string;
    @ViewChild("Text1") LagreKopiRef: ElementRef;
    @ViewChild("Text7") KommerSenereRef: ElementRef
    @ViewChild("Text3") KopiRef: ElementRef;
    @ViewChild("SlettStatusMeldingTekst") SlettStatusMeldingRef: ElementRef;
    @ViewChild("SlettVariabelMeldingTekst") SlettVariabelMeldingRef: ElementRef;
    @ViewChild("SlettHandlingMeldingTekst") SlettHandlingMeldingRef: ElementRef;
    @ViewChild("SlettProsesstrinnMeldingTekst") SlettProsesstrinnMeldingRef: ElementRef;

    ngAfterViewInit() {
        //this.titleService.setTitle($(this.TittelRef.nativeElement).text());
        this.SlettStatusMelding = $(this.SlettStatusMeldingRef.nativeElement).text();
        this.SlettVariabelMelding = $(this.SlettVariabelMeldingRef.nativeElement).text();
        this.KommerSenereMelding = $(this.KommerSenereRef.nativeElement).text();
        this.SlettHandlingMelding = $(this.SlettHandlingMeldingRef.nativeElement).text();
        this.SlettProsesstrinnMelding = $(this.SlettProsesstrinnMeldingRef.nativeElement).text();
        this.LagreKopiMelding = $(this.LagreKopiRef.nativeElement).text();
        this.Kopi = $(this.KopiRef.nativeElement).text();
        
    }

    ngOnInit() {
        var that = this;
        this.sub = this.route.params.subscribe(params => {
            that.prosessflytId = parseInt(params['prosessflytId']);
            var prosessflytgruppeId = parseInt(params['prosessflytgruppeId']);
            that.hent(prosessflytgruppeId);
        });
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    hent(prosessflytgruppeId: number) {
        var that = this;
        this.busy = this.service.Hent(that.prosessflytId).then(res => {
            that.data = res;
            if (that.data.Prosessflyt.ProsessflytgruppeId == 0)
                that.data.Prosessflyt.ProsessflytgruppeId = prosessflytgruppeId;
            setTimeout(function () {
                $("#LikeNummerserier").tooltip();
            }, 0);
            
        }, fail => {
            that.modalService.Alert(fail.error.Message);
        });
    }

    lagre() {
        var that = this;
        this.busy = this.service.Lagre(that.data).then(res => {
            that.data = res;
            that.prosessflytId = res.Prosessflyt.Id;
            that.router.navigate(["Admin/Prosessflyt/Rediger", res.Prosessflyt.ProsessflytgruppeId, that.prosessflytId], { replaceUrl: true });
        }, fail => {
            that.modalService.Alert(fail.error.Message);
        });
    }

    lagreKopi() {
        var that = this;
        this.modalService.Confirm(this.LagreKopiMelding, function () {
            that.prosessflytId = 0;
            that.data.Prosessflyt.Id = 0;
            that.data.Prosessflyt.Navn = that.data.Prosessflyt.Navn + " " + that.Kopi;
            that.data.Prosessflyt.Versjonsnr = 0;
            that.busy = that.service.Lagre(that.data).then(res => {
                that.data = res;
                if (res.Prosessflyt.Id != that.prosessflytId) {
                    that.prosessflytId = res.Prosessflyt.Id;
                    that.router.navigate(["Admin/Prosessflyt/Rediger", that.prosessflytId], { replaceUrl: true });
                }
            }, fail => {
                that.modalService.Alert(fail.error.Message);
            });
        });
    }

    openTab(i: number) {
        this.opentab = i;
        this.settTooltip();
    }

    settTooltip() {
        setTimeout(function () {
            $(".tilganglegende .label").tooltip({ animation: false, trigger: "hover" });
            $("input.rolletilgang ").tooltip({ animation: false, trigger: "hover" });
        }, 0);
    }

    oppdaterLikeNummerserier() {
        var that = this;
        this.service.HentLikeNummerserier(that.data.Prosessflyt.Nummerserie, that.prosessflytId).then(res => {
            that.data.Prosessflyt.LikeNummerserier = res.Antall;
            that.data.Prosessflyt.LikeNummerserierTooltip = res.Tooltip;
        });
    }

    normalverdieRolleTilgangChange(i: number) {
        this.data.Prosessflyt.Prosesstrinn.forEach(t => {
            var behandlesAvRoller = [t.RolleId].concat(t.BehandlesAvRoller);
            t.Handlinger.forEach(h => {
                h.Rolletilgang[i].HarTilgang = this.data.Prosessflyt.NormalverdierRolleTilgang[i].HarTilgang || behandlesAvRoller.includes(this.data.Prosessflyt.NormalverdierRolleTilgang[i].RolleId);
            });
        });
    }

    dropProsesstrinn(event: CdkDragDrop<ProsesstrinnDto>) {
        moveItemInArray(this.data.Prosessflyt.Prosesstrinn, event.previousIndex, event.currentIndex);
    }

    getProsesstrinnClass(i: number) {
        var rolle = this.data.Roller.find(r => r.Id == this.data.Prosessflyt.Prosesstrinn[i].RolleId);
        return rolle == null ? "default" : rolle.FargeklasseCss;
    }

    leggTilProsesstrinn() {
        var p = new ProsesstrinnDto;
        p.Expand = true;
        p.Guid = Guid.create().toString();

        this.data.Prosessflyt.Prosesstrinn = [...this.data.Prosessflyt.Prosesstrinn, p];
        this.settTooltip();
    }

    tittelChanged(i: number, t: string) {
        this.data.Prosessflyt.Prosesstrinn[i] = { ...this.data.Prosessflyt.Prosesstrinn[i], Tittel: t };
        this.data.Prosessflyt.Prosesstrinn = [...this.data.Prosessflyt.Prosesstrinn];
    }

    slettProsesstrinn(i: number) {
        var that = this;
        this.modalService.Confirm(this.SlettProsesstrinnMelding, function () {
            var slettProsesstrinnGuid = that.data.Prosessflyt.Prosesstrinn[i].Guid;
            that.data.Prosessflyt.Prosesstrinn.splice(i, 1);
            that.data.Prosessflyt.Prosesstrinn.forEach(m => {
                m.Handlinger.forEach(f => {
                    if (f.LinkId == slettProsesstrinnGuid) 
                        f.LinkId = null;
                    });
            });
            that.data.Prosessflyt.Prosesstrinn = [...that.data.Prosessflyt.Prosesstrinn];
        });
    }

    dropHandling(event: CdkDragDrop<ProsesstrinnDto>, index: number) {
        moveItemInArray(this.data.Prosessflyt.Prosesstrinn[index].Handlinger, event.previousIndex, event.currentIndex);
    }

    leggTilHandling(i: number) {
        var trinn = this.data.Prosessflyt.Prosesstrinn[i];
        var behandlesAvRoller = [trinn.RolleId].concat(trinn.BehandlesAvRoller);

        var h = new HandlingDto();
        this.data.Prosessflyt.NormalverdierRolleTilgang.forEach(n => {
            var t = new RolleTilgangDto();
            t.RolleId = n.RolleId;
            t.Navn = n.Navn;
            t.HarTilgang = n.HarTilgang || trinn.RolleId == n.RolleId || behandlesAvRoller.includes(n.RolleId);
            t.Disabled = trinn.RolleId == n.RolleId || behandlesAvRoller.includes(n.RolleId);
            t.FargeklasseCss = n.FargeklasseCss;
            h.Rolletilgang.push(t);
        });

        this.data.Prosessflyt.Prosesstrinn[i].Handlinger.push(h);
        this.settTooltip();
    }

    slettHandling(prosesstrinnindex: number, handlingsalternativindex: number) {
        var that = this;
        this.modalService.Confirm(that.SlettHandlingMelding, function () {
            that.data.Prosessflyt.Prosesstrinn[prosesstrinnindex].Handlinger.splice(handlingsalternativindex, 1);
        });
    }

    rolleChanged(e, prosesstrinnIndex: number) {
        
        var trinn = this.data.Prosessflyt.Prosesstrinn[prosesstrinnIndex];
        var gammelRolleId = trinn.RolleId;
        trinn.RolleId = e.Id;
        
        trinn.AssisterendeRollerListe = this.data.Roller.filter(r => r.Id != trinn.RolleId);
        this.data.Prosessflyt.Prosesstrinn = [...this.data.Prosessflyt.Prosesstrinn];
        if (trinn.BehandlesAvRoller.includes(trinn.RolleId)) {
            trinn.BehandlesAvRoller = trinn.BehandlesAvRoller.filter(f => f != trinn.RolleId);
        }

        for (var i = 0; i < trinn.Handlinger.length; i++) {
            for (var j = 0; j < trinn.Handlinger[i].Rolletilgang.length; j++) {
                var r = trinn.Handlinger[i].Rolletilgang[j];
                if (r.RolleId == trinn.RolleId) {
                    r.HarTilgang = true;
                    r.Disabled = true;
                } else {
                    r.Disabled = false;
                }
                if (r.RolleId == gammelRolleId)
                    r.HarTilgang = this.data.Prosessflyt.NormalverdierRolleTilgang[j].HarTilgang;
            }
        }

        this.kanBehandlesAvChanged(prosesstrinnIndex);
    }

    kanBehandlesAvChanged(prosesstrinnIndex: number) {
        var trinn = this.data.Prosessflyt.Prosesstrinn[prosesstrinnIndex];
        
        if (prosesstrinnIndex == 0) {
            this.data.OppforingKanOpprettesAv.forEach(o => {
                if (o.Id == this.prosessflytId) {
                    o.KanOpprettesAvRoller = [trinn.RolleId].concat(trinn.BehandlesAvRoller);
                }
            });
        }

        this.data.Prosessflyt.Prosesstrinn.forEach(t => {
            var behandlesAvRoller = [t.RolleId].concat(t.BehandlesAvRoller);

            t.OpprettProsessflyterListe = this.data.OppforingKanOpprettesAv.filter(f => behandlesAvRoller.every(b => f.KanOpprettesAvRoller.some(s => s == b))).map(m => {
                var o = new OpprettOppforingDto;
                o.Id = m.Id;
                o.Navn = m.Navn;
                return o;
            });

            t.Handlinger.forEach(h => {
                if (!t.OpprettProsessflyterListe.some(s => s.Id == h.OpprettProsessdefId))
                    h.OpprettProsessdefId = null;
            });
        });


        for (var i = 0; i < trinn.Handlinger.length; i++) {
            this.oppdaterVarsling(prosesstrinnIndex, i);
        }

    }

    assisterendeAdded($e, prosesstrinnIndex: number) {
        var trinn = this.data.Prosessflyt.Prosesstrinn[prosesstrinnIndex];
        for (var i = 0; i < trinn.Handlinger.length; i++) {
            trinn.Handlinger[i].Rolletilgang.forEach(r => {
                if (r.RolleId == $e.Id) {
                    r.HarTilgang = true;
                    r.Disabled = true;
                }
            });
        }
    }

    assisterendeRemoved($e, prosesstrinnIndex: number) {
        var trinn = this.data.Prosessflyt.Prosesstrinn[prosesstrinnIndex];
        for (var i = 0; i < trinn.Handlinger.length; i++) {
            for (var j = 0; j < trinn.Handlinger[i].Rolletilgang.length; j++) {
                var r = trinn.Handlinger[i].Rolletilgang[j];
                if (r.RolleId == $e.value.Id) {
                    r.HarTilgang = this.data.Prosessflyt.NormalverdierRolleTilgang[j].HarTilgang;
                    r.Disabled = false;
                }
            }
        }
    }

    oppdaterVarsling(prosesstrinnIndex: number, handlingsalternativIndex: number) {
        var h = this.data.Prosessflyt.Prosesstrinn[prosesstrinnIndex].Handlinger[handlingsalternativIndex];
        h.VarslingRollerListe = [...this.data.Roller.filter(r => h.Rolletilgang.filter(r => r.HarTilgang).some(s => s.RolleId == r.Id))];
        h.VarslingRolleIder = [...h.VarslingRolleIder.filter(f => h.VarslingRollerListe.some(v => v.Id == f))];
    }

    
       

    leggTilStatus() {
        var s = new StatusDto();
        s.Guid = Guid.create().toString();
        this.data.Prosessflyt.Statuser.push(s);
    }

    slettStatus(statusindex: number) {
        var that = this;
        this.modalService.Confirm(that.SlettStatusMelding, function () {
            that.data.Prosessflyt.Statuser.splice(statusindex, 1);
        });
    }


    leggTilVariabel() {
        var p = new ProsessvariabelDto();
        p.Guid = Guid.create().toString();
        this.data.Prosessflyt.Variabler.push(p);
    }

    slettVariabel(variabelindex: number) {
        var that = this;
        this.modalService.Confirm(that.SlettVariabelMelding, function () {
            that.data.Prosessflyt.Variabler.splice(variabelindex, 1);
        });
    }


    tegnDiagram() {
        var that = this;
        setTimeout(() => {
            var data = {
                Statuser: this.data.Prosessflyt.Statuser,
                Prosesstrinn: this.data.Prosessflyt.Prosesstrinn,
            };

            $("#Diagram").off("tittelEndret").on("tittelEndret", function (e, text, index) {
                that.data.Prosessflyt.Prosesstrinn[index].Tittel = text;
            });

            TegnDiagram(this.data.Roller, this.data.Skjemaer, data, $("#Diagram"), true);
        }, 0);
       
    }


    hentTestscript() {
        var that = this;
        this.busy = this.service.GenererTestscript(that.prosessflytId).then(res => {
            that.testscript = res;
        }, fail => {
            that.modalService.Alert(fail.error.Message);
        });
    }

    getTestscriptTeller(kindex: number, tindex: number, forberedelseindex: number, testindex: number) {
        var teller = 1;
        for (var i = 0; i < kindex; i++) {
            var test = this.testscript[i]
            test.Testtabeller.forEach(function (tab) {
                teller += tab.Forberedelser.length;
                teller += tab.Tester.length;
            });
        }

        for (var i = 0; i < tindex; i++) {
            var tab = this.testscript[kindex].Testtabeller[i];
            teller += tab.Forberedelser.length;
            teller += tab.Tester.length;
        }

        if (forberedelseindex != null) {
            teller += forberedelseindex;
        }
        else if (testindex != null) {
            teller += this.testscript[kindex].Testtabeller[tindex].Forberedelser.length + testindex;
        }

        return teller;
    }

    lastNedTestscript() {
        this.modalService.Alert(this.KommerSenereMelding);
    }

    bugChanged(kindex: number, tindex: number, testindex: number) {
        var test = this.testscript[kindex].Testtabeller[tindex].Tester[testindex];
        if (test.Bug)
            test.Ok = false;
    }

    okChanged(kindex: number, tindex: number, testindex: number) {
        var test = this.testscript[kindex].Testtabeller[tindex].Tester[testindex];
        if (test.Ok)
            test.Bug = false;
    }

    openSource() {
        var that = this;
        this.bsModalService.show(SourceModalComponent, {
            class: "modal-xl sokemodal",
            initialState: {
                source: JSON.stringify(that.data.Prosessflyt, null, 3),
                okfunc: function (source) {
                    that.data.Prosessflyt = JSON.parse(source);
                }
            }
        });
    }
}
