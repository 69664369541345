import { setTheme as ngxSetTheme } from 'ngx-bootstrap/utils';
import { getNorwegianPaginatorIntl } from './98 Shared/PaginatorLanguage';
import { registerLocaleData } from '@angular/common';
import localeNor from '@angular/common/locales/nb';
import localeNorExtra from '@angular/common/locales/nb';
registerLocaleData(localeNor, 'no', localeNorExtra);
var ɵ0 = getNorwegianPaginatorIntl();
var AppModule = /** @class */ (function () {
    function AppModule() {
        ngxSetTheme('bs3'); // or 'bs4'
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
