<div class="container" [ng-busy]="busy">
    <div class="panel panel-default">
        <div class="panel-heading">
            <div class="pull-right">
                <label style="margin-right:1em"><input type="checkbox" [(ngModel)]="visSlettede" />&nbsp;<span i18n="@@app01Admin05ProsessflytgruppeOversikt1">Vis slettede</span></label>
                <button class="btn btn-primary" (click)="rediger(0)">
                    <span class="glyphicon glyphicon-plus-sign"></span>&nbsp;<span i18n="@@app01Admin05ProsessflytgruppeOversikt2">Opprett ny</span>
                </button>
            </div>

            <h1 i18n="@@app01Admin05ProsessflytgruppeOversikt3">Prosessflytgrupper</h1>
        </div>

        <table class="table table-striped table-hover table-bordered" *ngIf="grupper">
            <thead>
                <tr class="sortable">
                    <th (click)="sort(1)"><span i18n="@@app01Admin05ProsessflytgruppeOversikt4">Id</span><span class="glyphicon glyphicon-triangle-top sortering" [class.show]="sortering==1"></span><span class="glyphicon glyphicon-triangle-bottom sortering" [class.show]="sortering==-1"></span></th>
                    <th (click)="sort(2)"><span i18n="@@app01Admin05ProsessflytgruppeOversikt5">Navn</span><span class="glyphicon glyphicon-triangle-top sortering" [class.show]="sortering==2"></span><span class="glyphicon glyphicon-triangle-bottom sortering" [class.show]="sortering==-2"></span></th>
                    <th (click)="sort(3)"><span i18n="@@app01Admin05ProsessflytgruppeOversikt6">Prosessflyter</span><span class="glyphicon glyphicon-triangle-top sortering" [class.show]="sortering==3"></span><span class="glyphicon glyphicon-triangle-bottom sortering" [class.show]="sortering==-3"></span></th>
                    <th (click)="sort(4)" *ngIf="visSlettede"><span i18n="@@app01Admin05ProsessflytgruppeOversikt7">Slettet</span><span class="glyphicon glyphicon-triangle-top sortering" [class.show]="sortering==4"></span><span class="glyphicon glyphicon-triangle-bottom sortering" [class.show]="sortering==-4"></span></th>
                    <th class="tight"></th>
                    <th class="tight"></th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let g of grupper; let i = index">
                    <tr *ngIf="!g.Slettet || g.Slettet &amp;&amp; visSlettede" tabindex="0" (dblclick)="rediger(g.Id)" [class.text-danger]="g.Slettet">
                        <td class="tight">{{g.Id}}</td>
                        <td>{{g.Navn}}</td>
                        <td>{{g.Prosessflyter}}</td>
                        <td *ngIf="visSlettede">{{g.Slettet | datetime}}</td>
                        <td class="nopadd"><button class="btn btn-default" (click)="slett(i)"><span class="glyphicon glyphicon-trash"></span></button></td>
                        <td class="nopadd"><button class="btn btn-default" (click)="rediger(g.Id)"><span class="glyphicon glyphicon-pencil"></span></button></td>
                    </tr>
                </ng-container>
                
            </tbody>

        </table>
    </div>

    <div style="display:none">
        <div i18n="@@app01Admin05ProsessflytgruppeOversikt8" #Text1="">Vil du slette denne prosessflytgruppen?</div>
        <div i18n="@@app01Admin05ProsessflytgruppeOversikt9" #Text2="">Vil du hente tilbake denne prosessflytgruppen?</div>
    </div>

</div>