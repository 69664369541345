import * as i0 from "@angular/core";
var LanguageService = /** @class */ (function () {
    function LanguageService() {
        this.currentLang = null;
        this.currentLang = localStorage.getItem("userLang");
    }
    LanguageService.prototype.updateLang = function (lang) {
        //console.log(this.currentLang, lang);
        var isLocal = window.location.host.indexOf("localhost") != -1;
        var languageChanged = false;
        if (this.currentLang != lang) {
            if (lang == null) {
                localStorage.removeItem("userLang");
            }
            else {
                localStorage.setItem("userLang", lang);
            }
            this.currentLang = lang;
            languageChanged = true;
        }
        // Use precompiled i18n apps in prod
        if (!isLocal) {
            var pathname = window.location.pathname;
            if (lang != "en" && pathname.indexOf("/en/") != -1) {
                console.warn("Url has language path for another language. Removing '/en/' from pathname");
                pathname = "/" + pathname.replace("/en/", "");
                window.location.pathname = pathname;
            }
            else if (lang == "en" && pathname.indexOf("/en/") == -1) {
                console.warn("Url is missing language path. Adding '/en/' to pathname");
                pathname = "/en" + pathname;
                window.location.pathname = pathname;
            }
        }
        else {
            if (languageChanged) {
                location.reload();
            }
        }
    };
    LanguageService.ngInjectableDef = i0.defineInjectable({ factory: function LanguageService_Factory() { return new LanguageService(); }, token: LanguageService, providedIn: "root" });
    return LanguageService;
}());
export { LanguageService };
