﻿import { Directive, ElementRef, Input, OnDestroy, Renderer2 } from '@angular/core';
import { from, Subscription } from 'rxjs';


@Directive({
    selector: '[ng-busy]',
})
export class NgBusyDirective implements OnDestroy {

    private internalSubs: Subscription[] = [];

    constructor(private renderer: Renderer2, private elementRef: ElementRef) { }

    @Input("ng-busy") set ngBusy(input: any) {

        if (this.isPromise(input)) {
            input = from(input).subscribe();
        }

        if (input instanceof Subscription) {
            const sub = input.add(() => {
                const index = this.internalSubs.indexOf(sub);

                if (index !== -1)
                    this.internalSubs.splice(index, 1);

                this.update();
            });
            this.internalSubs.push(sub);
        }

        this.update();
    }

    private isPromise(value: any): value is PromiseLike<any> {
        return value && typeof (<any>value).subscribe !== 'function' && typeof (value as any).then === 'function';
    }

    private update() {
        if (!this.internalSubs.length)
            this.hide();
        else
            this.show();
    }

    private show() {
        this.renderer.addClass(this.elementRef.nativeElement, "busy-loader");
    }

    private hide() {
        this.renderer.removeClass(this.elementRef.nativeElement, "busy-loader");
    }

    ngOnDestroy() {
        this.internalSubs.forEach(x => x.unsubscribe());
    }
}