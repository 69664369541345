import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ModalModule } from 'ngx-bootstrap/modal';
import { setTheme as ngxSetTheme } from 'ngx-bootstrap/utils';

import { Select2Module } from 'ng2-select2';
import { DragDropModule } from '@angular/cdk/drag-drop'
import { NgSelectModule } from '@ng-select/ng-select';
//import { Guid } from "guid-typescript";

import { AppComponent } from './app.component';
import { SmartappComponent } from './smartapp';
import { AppRoutingModule } from './routing.module';
import { MenyComponent } from './Meny/Meny';
import { PassordComponent } from './Meny/Passord';
import { AlertComponent } from './80 Modals/Alert';

import { FirmaOversiktComponent } from './01 Admin/01 Firma/Oversikt';
import { FirmaRedigerComponent } from './01 Admin/01 Firma/Rediger';
import { DatetimePipe } from './98 Shared/datetime.pipe';
import { ConfirmComponent } from './80 Modals/Confirm';
import { ContenteditableDirective } from './98 Shared/contenteditable.directive';
import { BrukergruppeOversiktComponent } from './01 Admin/02 Brukergruppe/Oversikt';
import { BrukerkontoRedigerComponent } from './01 Admin/03 Brukerkonto/Rediger';
import { DateinputPipe } from './98 Shared/dateinput.pipe';
import { ProsjektadminOversiktComponent } from './02 Prosjektadmin/Oversikt';
import { ProsjektadminRedigerComponent } from './02 Prosjektadmin/Rediger';
import { ProsjektadminKontrakterComponent } from './02 Prosjektadmin/01 Kontrakter/Kontrakter';
import { SokemodalComponent } from './01 Admin/01 Firma/Sokemodal';
import { HighlightPipe } from './98 Shared/highlight.pipe';
import { BrukergruppeRedigerComponent } from './01 Admin/02 Brukergruppe/Rediger';
import { AdminProsessflytRedigerComponent } from './01 Admin/04 Prosessflyt/Rediger';
import { SourceModalComponent } from './01 Admin/04 Prosessflyt/SourceModal';
import { NgBusyDirective } from './98 Shared/ng-busy.directive';
import { SafeInterceptor } from './99 Services/Safe.interceptor';
import { LoginComponent } from './90 Login/Login.component';
import { RetryComponent } from './90 Login/Retry.component';
import { VersionComponent } from './90 Login/Version.component';
import { BrukergruppeKopierComponent } from './01 Admin/02 Brukergruppe/KopierModal';
import { DriftsmeldingOversiktComponent } from './01 Admin/05 Driftsmelding/Oversikt';
import { DatetimeinputPipe } from './98 Shared/datetimeinput.pipe';
import { SafeHtmlPipe } from './98 Shared/keep-html.pipe';
import { AdminProsessflytgruppeOversiktComponent } from './01 Admin/05 Prosessflytgruppe/Oversikt';
import { AdminProsessflytgruppeRedigerComponent } from './01 Admin/05 Prosessflytgruppe/Rediger';
import { RapportOppforingerComponent } from './03 Rapport/Oppforinger';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { getNorwegianPaginatorIntl } from './98 Shared/PaginatorLanguage';
import { ColorPickerModule } from 'ngx-color-picker';
import { MatMenuModule } from '@angular/material/menu';

import { registerLocaleData } from '@angular/common';
import localeNor from '@angular/common/locales/nb';
import localeNorExtra from '@angular/common/locales/nb';
import { DecodeHtmlPipe } from './98 Shared/DecodeHTML.pipe';
registerLocaleData(localeNor, 'no', localeNorExtra);

@NgModule({
    declarations: [
        AppComponent,
        MenyComponent,
        SmartappComponent,
        AlertComponent,
        ConfirmComponent,
        PassordComponent,
        FirmaOversiktComponent,
        FirmaRedigerComponent,
        DatetimePipe,
        DateinputPipe,
        DatetimeinputPipe,
        HighlightPipe,
        SafeHtmlPipe,
        DecodeHtmlPipe,
        ContenteditableDirective,
        BrukergruppeOversiktComponent,
        BrukerkontoRedigerComponent,
        ProsjektadminOversiktComponent,
        ProsjektadminRedigerComponent,
        ProsjektadminKontrakterComponent,
        SokemodalComponent,
        BrukergruppeRedigerComponent,
        AdminProsessflytRedigerComponent,
        SourceModalComponent,
        NgBusyDirective,
        BrukergruppeKopierComponent,
        DriftsmeldingOversiktComponent,
        AdminProsessflytgruppeOversiktComponent,
        AdminProsessflytgruppeRedigerComponent,
        RapportOppforingerComponent,

        LoginComponent,
        RetryComponent,
        VersionComponent
    ],
    entryComponents: [
        AlertComponent,
        ConfirmComponent,
        PassordComponent,
        ProsjektadminKontrakterComponent,
        SokemodalComponent,
        SourceModalComponent,
        BrukergruppeKopierComponent,
        LoginComponent,
        RetryComponent,
        VersionComponent
    ],
    imports: [
        FormsModule,
        BrowserModule,
        BrowserAnimationsModule,
        //NgBusyModule.forRoot({
        //    message: "Laster...",
        //    delay: 180
        //}),
        AppRoutingModule,
        HttpClientModule,
        ModalModule.forRoot(),
        Select2Module,
        DragDropModule,
        NgSelectModule,
        MatTableModule,
        MatPaginatorModule,
        ColorPickerModule,
        MatMenuModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SafeInterceptor,
            multi: true
        },
        {
            provide: LOCALE_ID,
            useValue: 'no'
        },
        {
            provide: MatPaginatorIntl,
            useValue: getNorwegianPaginatorIntl()
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor() {
        ngxSetTheme('bs3'); // or 'bs4'
    }
}
