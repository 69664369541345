﻿import { Component, ViewChild, ElementRef } from '@angular/core';
import { AdminProsessflytgruppeOversiktService, ProsessflytgruppeDto  } from 'Angular/app/99 Services/AdminProsessflytgruppeOversikt.service';
import { ModalsService } from 'Angular/app/80 Modals/Service';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';


@Component({
    selector: 'ProsessflytgruppeOversikt',
    templateUrl: './Oversikt.html',
})

export class AdminProsessflytgruppeOversiktComponent {

    busy: any;
    sub: Subscription;
    grupper: ProsessflytgruppeDto[] = [];
    visSlettede: boolean = false;
    sortering: number = 2;

    constructor(private service: AdminProsessflytgruppeOversiktService, private modalService: ModalsService, private router: Router, private route: ActivatedRoute) { }

    slettMelding: string;
    hentTilbakeMelding: string;

    @ViewChild("Text1") slettMeldingRef: ElementRef;
    @ViewChild("Text2") hentTilbakeMeldingRef: ElementRef;
    ngAfterViewInit() {
        this.slettMelding = $(this.slettMeldingRef.nativeElement).text();
        this.hentTilbakeMelding = $(this.hentTilbakeMeldingRef.nativeElement).text();
    }

    ngOnInit() {
        var that = this;
        this.sub = this.route.params.subscribe(params => {
            that.sortering = parseInt(params['sortering']);
            this.oppdater();
        });
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    oppdater() {
        var that = this;
        this.busy = this.service.Hent(that.sortering).then(result => {
            that.grupper = result;
        }, fail => {
            that.modalService.Alert(fail.error.Message);
        });
    }

    rediger(id: number) {
        this.router.navigate(["Admin/Prosessflytgruppe/Rediger", id, 2]);
    }

    slett(i: number) {
        var gruppe = this.grupper[i];
        var melding = gruppe.Slettet ? this.hentTilbakeMelding : this.slettMelding;
        var that = this;
        this.modalService.Confirm(melding, function () {
            that.service.Slett(gruppe.Id, that.sortering).then(result => {
                that.grupper = result;
            }, fail => {
                that.modalService.Alert(fail.error.Message);
            });
        })
    }

    sort(col: number) {
        if (this.sortering == col)
            this.sortering = col * -1
        else
            this.sortering = col;

        this.router.navigate(["Admin/Prosessflytgruppe/Oversikt/", this.sortering], { replaceUrl: true });
        
        this.oppdater();
    }
}