import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var HentBrukerkontoDto = /** @class */ (function () {
    function HentBrukerkontoDto() {
        this.Brukergrupper = [];
        this.Hovedkontrakter = [];
        this.Firmaer = [];
        this.Hovedprosjekter = [];
    }
    return HentBrukerkontoDto;
}());
export { HentBrukerkontoDto };
var BrukerkontoDto = /** @class */ (function () {
    function BrukerkontoDto() {
        this.Brukergrupper = [];
        this.Kontrakter = [];
        this.Prosjektadmin = [];
    }
    return BrukerkontoDto;
}());
export { BrukerkontoDto };
var BrukergruppeDto = /** @class */ (function () {
    function BrukergruppeDto() {
    }
    return BrukergruppeDto;
}());
export { BrukergruppeDto };
var HovedkontraktDto = /** @class */ (function () {
    function HovedkontraktDto() {
        this.Roller = [];
    }
    return HovedkontraktDto;
}());
export { HovedkontraktDto };
var Select2Dto = /** @class */ (function () {
    function Select2Dto() {
    }
    return Select2Dto;
}());
export { Select2Dto };
var FirmaDto = /** @class */ (function () {
    function FirmaDto() {
    }
    return FirmaDto;
}());
export { FirmaDto };
var HovedprosjektDto = /** @class */ (function () {
    function HovedprosjektDto() {
    }
    return HovedprosjektDto;
}());
export { HovedprosjektDto };
var KontraktDto = /** @class */ (function () {
    function KontraktDto() {
        this.Hovedkontrakter = [];
    }
    return KontraktDto;
}());
export { KontraktDto };
var ProsjektadminDto = /** @class */ (function () {
    function ProsjektadminDto() {
        this.HovedprosjektListe = [];
    }
    return ProsjektadminDto;
}());
export { ProsjektadminDto };
var WorkingEncoder = /** @class */ (function () {
    function WorkingEncoder() {
    }
    WorkingEncoder.prototype.encodeKey = function (key) { return encodeURIComponent(key); };
    WorkingEncoder.prototype.encodeValue = function (value) { return encodeURIComponent(value); };
    WorkingEncoder.prototype.decodeKey = function (key) { return decodeURIComponent(key); };
    WorkingEncoder.prototype.decodeValue = function (value) { return decodeURIComponent(value); };
    return WorkingEncoder;
}());
export { WorkingEncoder };
var AdminBrukerkontoRedigerService = /** @class */ (function () {
    function AdminBrukerkontoRedigerService(http) {
        this.http = http;
        this.serviceUrl = environment.APIUrl + 'AdminBrukerkontoRediger/';
    }
    AdminBrukerkontoRedigerService.prototype.addArray = function (params, key, value) {
        if (typeof value === "undefined" || value === null)
            return params;
        if (Array.isArray(value) && value.length === 0)
            return params;
        for (var i = 0; i < value.length; i++)
            params = params.append(key + "[]", value[i].toString());
        return params;
    };
    AdminBrukerkontoRedigerService.prototype.Hent = function (brukerkontoId) {
        var params = new HttpParams({ encoder: new WorkingEncoder() });
        params = params.set("brukerkontoId", brukerkontoId != null ? brukerkontoId.toString() : null);
        return this.http.get(this.serviceUrl + "Hent", { params: params, withCredentials: true }).toPromise();
    };
    AdminBrukerkontoRedigerService.prototype.Lagre = function (brukerkonto) {
        return this.http.post(this.serviceUrl + "Lagre", brukerkonto, { withCredentials: true }).toPromise();
    };
    AdminBrukerkontoRedigerService.prototype.Slett = function (id) {
        var params = new HttpParams({ encoder: new WorkingEncoder() });
        params = params.set("id", id != null ? id.toString() : null);
        return this.http.get(this.serviceUrl + "Slett", { params: params, withCredentials: true }).toPromise();
    };
    AdminBrukerkontoRedigerService.ngInjectableDef = i0.defineInjectable({ factory: function AdminBrukerkontoRedigerService_Factory() { return new AdminBrukerkontoRedigerService(i0.inject(i1.HttpClient)); }, token: AdminBrukerkontoRedigerService, providedIn: "root" });
    return AdminBrukerkontoRedigerService;
}());
export { AdminBrukerkontoRedigerService };
