<div class="modal-header">
    <h4 class="modal-title" style="float: left;"><span i18n="@@app02Prosjektadmin01KontrakterKontrakter1">Kontrakter for hovedkontrakt</span>&nbsp;<span *ngIf="hovedkontraktnavn">{{hovedkontraktnavn}}</span></h4>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" [ng-busy]="busy" style="padding:0">
    <table class="table table-condensed table-striped table-hover small" *ngIf="kontrakter != null">
        <thead>
            <tr>
                <th i18n="@@app02Prosjektadmin01KontrakterKontrakter7">Tittel</th>
                <th i18n="@@app02Prosjektadmin01KontrakterKontrakter8">Kontraktsnr</th>
                <th i18n="@@app02Prosjektadmin01KontrakterKontrakter9">Bruker / gruppe</th>
                <th i18n="@@app02Prosjektadmin01KontrakterKontrakter10">Rolle</th>
                <th i18n="@@app02Prosjektadmin01KontrakterKontrakter11">Oppstart</th>
                <th i18n="@@app02Prosjektadmin01KontrakterKontrakter12">Utløpsdato</th>
                <th i18n="@@app02Prosjektadmin01KontrakterKontrakter13">Innboks</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let k of kontrakter; let i = index" [class.text-danger]="k.Utlopt" [class.hidden]="k.Utlopt &amp;&amp; !visUtlopteKontrakter">
                <td class="padd">{{k.GjeldendeTittel}}</td>
                <td class="padd">{{k.GjeldendeKontraktsnr}}</td>
                <td><select2 [options]="brukerGruppeSelect2Opts" [data]="brukere" [value]="k.BrukerId" (valueChanged)="brukerChanged($event, i)" [disabled]="k.Utlopt"><option></option></select2></td>
                <td><select2 [options]="rollerSelect2Opts" [data]="roller" [value]="k.RolleId" (valueChanged)="rolleChanged($event, i)" [disabled]="k.Utlopt"><option></option></select2></td>
                <td><input class="form-control input-sm" type="date" [(ngModel)]="k.Oppstart" [disabled]="k.Utlopt" /></td>
                <td><input class="form-control input-sm" type="date" [(ngModel)]="k.Utlopsdato" [disabled]="k.Utlopt" /></td>
                <td class="padd text-center">{{k.Meldinger}}</td>
                <td class="nopadd"><button *ngIf="!k.Utlopt" class="btn btn-default" (click)="slettKontrakt(i)"><span class="glyphicon glyphicon-trash"></span></button></td>
            </tr>
        </tbody>
    </table>
    <div class="alert alert-warning" style="margin: 15px;" *ngIf="manglendeRoller.length > 0">
        <span class="glyphicon glyphicon-alert"></span>&nbsp;<span i18n="@@app02Prosjektadmin01KontrakterKontrakter16">Mangler en eller flere roller:</span>&nbsp;{{manglendeRoller}}
    </div>
</div>
<div class="modal-footer">
    <div class="form-group" style="float:left">
        <button class="btn btn-primary" (click)="nyKontrakt()" style="margin-right:5px;"><span i18n="@@app02Prosjektadmin01KontrakterKontrakter2">Legg til kontrakt</span></button>
        <label><input type="checkbox" [(ngModel)]="visUtlopteKontrakter" />&nbsp;<span i18n="@@app02Prosjektadmin01KontrakterKontrakter3">Vis utløpte kontrakter</span></label>
    </div>
    <button class="btn btn-success" i18n="@@app02Prosjektadmin01KontrakterKontrakter4" (click)="lagre()">Lagre</button>
    <button type="button" class="btn btn-default" (click)="modalRef.hide()" i18n="@@app02Prosjektadmin01KontrakterKontrakter5">Avbryt</button>
</div>
<div style="display:none">
    <div #SletteKontraktMelding="" i18n="@@app02Prosjektadmin01KontrakterKontrakter6">Vil du slette kontrakten?</div>
    <div #BrukerGruppePlaceholder="" i18n="@@app02Prosjektadmin01KontrakterKontrakter14">(velg bruker/gruppe)</div>
    <div #RollePlaceholder="" i18n="@@app02Prosjektadmin01KontrakterKontrakter15">(velg rolle)</div>
</div>