﻿import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';

import { ActivatedRoute, Router } from '@angular/router';
import { ModalsService } from '../../80 Modals/Service';
import { Title } from '@angular/platform-browser';
import { LoginService } from '../../99 Services/Login.service';
import { AdminBrukerkontoRedigerService, BrukerkontoDto, Select2Dto, HovedkontraktDto, KontraktDto, FirmaDto, ProsjektadminDto, HovedprosjektDto, BrukergruppeDto } from '../../99 Services/AdminBrukerkontoRediger.service';
import { forEach } from '@angular/router/src/utils/collection';
import { DateinputPipe } from '../../98 Shared/dateinput.pipe';

import { NgSelectModule, NgOption } from '@ng-select/ng-select';

declare let $: any;

@Component({
    selector: "brukerkonto-rediger",
    templateUrl: "./Rediger.html",
    styleUrls: ["./Rediger.css"]
})
export class BrukerkontoRedigerComponent {

    constructor(
        private service: AdminBrukerkontoRedigerService,
        private route: ActivatedRoute,
        private modalService: ModalsService,
        private titleService: Title,
        private router: Router,
        private loginService: LoginService
    ) { }

    busy: any;
    sub: Subscription;
    brukerkonto: BrukerkontoDto = null
    brukergrupper: BrukergruppeDto[] = null;
    hovedprosjekter: HovedprosjektDto[] = null;
    hovedkontrakter: HovedkontraktDto[] = null;
    firmaer: FirmaDto[] = null;
    visUtlopteKontrakter: boolean = false;
    brukerErAdmin: boolean = false;
    visUtlopteProsjektadmin: boolean = false;
    
    brukerkontoId: number = null;
    firmaId: number = null;

    hovedprosjekterSelect2Opts: Select2Options;
    hovedkontrakterSelect2Opts: Select2Options;
    rollerSelect2Opts: Select2Options;

    SletteKontraktMelding: string;
    SlettBrukerMelding: string;
    slettProsjektadminMelding: string;
    @ViewChild("Tittel") TittelRef: ElementRef;
    @ViewChild("SletteKontraktMelding") SletteMeldingRef: ElementRef;
    @ViewChild("HovedprosjektPlaceholder") HovedprosjektPlaceholderRef: ElementRef;
    @ViewChild("HovedkontraktPlaceholder") HovedkontraktPlaceholderRef: ElementRef;
    @ViewChild("RollePlaceholder") RollePlaceholderRef: ElementRef;
    @ViewChild("SlettBrukerMeldingTekst") SlettBrukerMeldingRef: ElementRef;
    @ViewChild("SlettProsjektadminMeldingTekst") SlettProsjektadminMeldingRef: ElementRef;

    ngAfterViewInit() {
        this.titleService.setTitle($(this.TittelRef.nativeElement).text());
        this.SletteKontraktMelding = $(this.SletteMeldingRef.nativeElement).text();
        this.SlettBrukerMelding = $(this.SlettBrukerMeldingRef.nativeElement).text();
        this.slettProsjektadminMelding = $(this.SlettProsjektadminMeldingRef.nativeElement).text();

        this.hovedprosjekterSelect2Opts = Object.assign({}, this.select2optionsSingle, {
            placeholder: $(this.HovedprosjektPlaceholderRef.nativeElement).text()
        });
        this.hovedkontrakterSelect2Opts = Object.assign({}, this.select2optionsSingle, {
            placeholder: $(this.HovedkontraktPlaceholderRef.nativeElement).text()
        });
        this.rollerSelect2Opts = Object.assign({}, this.select2optionsSingle, {
            placeholder: $(this.RollePlaceholderRef.nativeElement).text()
        });
    }

    select2optionsMultiple: Select2Options = {
        multiple: true,
        escapeMarkup: (markup: string) => { return markup },
        templateSelection: (object: Select2SelectionObject) => { return $("<span/>").html(object.text) },
        templateResult: (object: Select2SelectionObject) => { return $("<span/>").html(object.text) },
        theme: "bootstrap",
        dropdownAutoWidth: true
    };

    select2optionsSingle: Select2Options = {
        multiple: false,
        escapeMarkup: (markup: string) => { return markup },
        templateSelection: (object: Select2SelectionObject) => { return $("<span/>").html(object.text) },
        templateResult: (object: Select2SelectionObject) => { return $("<span/>").html(object.text) },
        theme: "bootstrap",
        dropdownAutoWidth: true
    };

    ngOnInit() {
        var that = this;
        this.sub = this.route.params.subscribe(params => {
            var brukerkontoId = parseInt(params['brukerkontoId']);
            var firmaId = parseInt(params['firmaId']);
            that.brukerkontoId = brukerkontoId;
            if (firmaId != 0) that.firmaId = firmaId;
            that.hent();
        });
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    hent() {
        var that = this;
        this.busy = this.service.Hent(that.brukerkontoId).then(res => {
            that.brukerkonto = res.Brukerkonto;
            if (that.brukerkonto.FirmaId == 0 && that.firmaId != null)
                that.brukerkonto.FirmaId = that.firmaId;
            if (that.firmaId == null)
                that.firmaId = that.brukerkonto.FirmaId;
            that.brukergrupper = res.Brukergrupper;
            that.hovedprosjekter = res.Hovedprosjekter;
            that.hovedkontrakter = res.Hovedkontrakter;
            that.firmaer = res.Firmaer;
            that.brukerErAdmin = res.Admin;
        }, fail => {
            that.modalService.Alert(fail.error.Message);
        });
    }

    lagre() {
        var that = this;
        this.busy = this.service.Lagre(that.brukerkonto).then(res => {
            that.router.navigate(["Admin/Brukerkonto/Rediger", that.firmaId, res], { replaceUrl: true });
            that.brukerkontoId = res;
            that.hent();
        }, fail => {
            that.modalService.Alert(fail.error.Message);
        });
    }

    hovedprosjektChanged(index: number) {
        var kontrakt = this.brukerkonto.Kontrakter[index];
        kontrakt.HovedkontraktId = null;
        kontrakt.RolleId = null;
        kontrakt.Hovedkontrakter = this.hovedkontrakter.filter(k => k.HovedprosjektId == kontrakt.HovedprosjektId);
    }

    hovedkontraktChanged(index: number) {
        var kontrakt = this.brukerkonto.Kontrakter[index];
        kontrakt.HovedkontraktKontraktsnr = this.hovedkontrakter.find(f => f.id == kontrakt.HovedkontraktId).Kontraktsnr;
        kontrakt.HovedkontraktKontraktstittel = this.hovedkontrakter.find(f => f.id == kontrakt.HovedkontraktId).Kontraktstittel;
        kontrakt.GjeldendeKontraktsnr = !kontrakt.Kontraktsnr ? kontrakt.HovedkontraktKontraktsnr : kontrakt.Kontraktsnr;
        kontrakt.GjeldendeTittel = !kontrakt.Tittel ? kontrakt.HovedkontraktKontraktstittel : kontrakt.Tittel;
        kontrakt.RolleId = null;
    }

    nyKontrakt() {
        var kontrakt = new KontraktDto();
        kontrakt.Id = 0;
        kontrakt.BrukerkontoId = this.brukerkontoId;
        kontrakt.Brukerkonto = this.brukerkonto.Navn;
        //kontrakt.HovedprosjektId = this.hovedprosjekter[0].id;
        //kontrakt.Hovedkontrakter = this.hovedkontrakter.filter(k => k.HovedprosjektId == kontrakt.HovedprosjektId)
        //kontrakt.HovedkontraktKontraktsnr = kontrakt.Hovedkontrakter[0].Kontraktsnr;
        //kontrakt.HovedkontraktKontraktstittel = kontrakt.Hovedkontrakter[0].Kontraktstittel;
        //kontrakt.HovedkontraktId = kontrakt.Hovedkontrakter[0].id;
        kontrakt.Oppstart = new DateinputPipe("en-US").transform(new Date());
        //kontrakt.RolleId = this.roller[0].id;
        this.brukerkonto.Kontrakter.push(kontrakt);
    }

    ingenKontrakter() {
        return this.visUtlopteKontrakter && this.brukerkonto.Kontrakter.length == 0 || !this.visUtlopteKontrakter && this.brukerkonto.Kontrakter.filter(f => !f.Utlopt).length == 0;
    }

    slettKontrakt(index: number) {
        var that = this;
        this.modalService.Confirm(that.SletteKontraktMelding, function () {
            var kontrakt = that.brukerkonto.Kontrakter[index];
            if (kontrakt.Id == 0) {
                that.brukerkonto.Kontrakter.splice(index, 1);
            } else {
                kontrakt.Utlopsdato = new DateinputPipe("en-US").transform(new Date());
                kontrakt.Utlopt = true;
            }
        });
    }

    slett() {
        var that = this;
        this.modalService.Confirm(that.SlettBrukerMelding, function () {
            that.busy = that.service.Slett(that.brukerkontoId).then(res => {
                that.hent();
            }, fail => {
                that.modalService.Alert(fail.error.Message);
            });

        });
    }

    loggInnSom() {
        var that = this;
        this.busy = this.loginService.Login2(that.brukerkontoId).then(result => {
            window.location.href = "#/";
        }, fail => {
            that.modalService.Alert(fail.error.Message);
        });
    }

    hentRoller(index: number) {
        var kontrakt = this.brukerkonto.Kontrakter[index];
        var hovedkontrakt = this.hovedkontrakter.find(f => f.id == kontrakt.HovedkontraktId);
        if (hovedkontrakt != null)
            return hovedkontrakt.Roller;
    }

    prosjektadminHovedprosjektChanged() {
        for (var index = 0; index < this.brukerkonto.Prosjektadmin.length; index++) {
            if (this.brukerkonto.Prosjektadmin[index].Utlopt)
                this.brukerkonto.Prosjektadmin[index].HovedprosjektListe = this.brukerkonto.Prosjektadmin[index].HovedprosjektListe.filter(f => { return f });
            else
                this.brukerkonto.Prosjektadmin[index].HovedprosjektListe = this.hovedprosjekter.filter(f => !this.brukerkonto.Prosjektadmin.filter(function (p, i) { if (!p.Utlopt && i != index) return p }).some(s => s.HovedprosjektId == f.Id));
        }
    }

    slettProsjektadmin(index: number) {
        var that = this;
        this.modalService.Confirm(this.slettProsjektadminMelding, function () {
            that.brukerkonto.Prosjektadmin.splice(index, 1);
            that.prosjektadminHovedprosjektChanged();
        });
    }

    nyProsjektadmin() {
        var p = new ProsjektadminDto;
        this.brukerkonto.Prosjektadmin.push(p);
        this.prosjektadminHovedprosjektChanged();
    }

    ingenProsjektadmin() {
        return this.visUtlopteProsjektadmin && this.brukerkonto.Prosjektadmin.length == 0 || !this.visUtlopteProsjektadmin && this.brukerkonto.Prosjektadmin.filter(f => !f.Utlopt).length == 0;
    }

    openHovedprosjekt(hovedprosjektId) {
        window.open("#Admin/Prosjekt/Rediger+" + hovedprosjektId, "_blank");
    }
}