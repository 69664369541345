<div class="modal-header">
    <h4 class="modal-title" i18n="@@app01Admin01FirmaSokemodal1">Søk etter firmaer, brukerkontoer, brukergrupper, kontrakter og hovedkontrakter</h4>
</div>
<div class="modal-body" [ng-busy]="busy">
    <div class="form-group">
        <form class="input-group">
            <input type="text" name="soketekst" [(ngModel)]="soketekst" class="form-control" autofocus="" i18n-placeholder="@@app01Admin01FirmaSokemodal2" placeholder="(oppgi søketekst)" />
            <span class="input-group-btn">
                <button id="SearchBtn" class="btn btn-primary" type="submit" (click)="sok()"><span class="glyphicon glyphicon-search"></span>&nbsp;<span i18n="@@app01Admin01FirmaSokemodal3">Søk</span></button>
            </span>
        </form>
    </div>
    <div class="form-group" *ngIf="firmaer != null" [class.hidden]="firmaer.length > 0 || brukerkontoer.length > 0 || brukergrupper.length > 0 || kontrakter.length > 0 || hovedkontrakter.length > 0">
        <div id="Errormessage" class="alert alert-warning">
            <span i18n="@@app01Admin01FirmaSokemodal4">Ingen resultater</span>
        </div>
    </div>

    <div *ngIf="firmaer != null &amp;&amp; firmaer.length > 0">
        <h4 i18n="@@app01Admin01FirmaSokemodal6">Firmaer</h4>
        <table class="table table-responsive table-bordered table-striped table-condensed table-hover">
            <thead>
                <tr>
                    <th i18n="@@app01Admin01FirmaSokemodal7">Navn</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let f of firmaer" [class.text-danger]="f.Slettet">
                    <td><a (click)="openFirma(f.Id)" [innerHTML]="f.Navn | highlight:sokttekst"></a></td>
                </tr>
            </tbody>
        </table>
    </div>

    <div *ngIf="brukerkontoer != null &amp;&amp; brukerkontoer.length > 0">
        <h4 i18n="@@app01Admin01FirmaSokemodal8">Brukerkontoer</h4>
        <table class="table table-responsive table-bordered table-striped table-condensed table-hover">
            <thead>
                <tr>
                    <th i18n="@@app01Admin01FirmaSokemodal9">Navn</th>
                    <th i18n="@@app01Admin01FirmaSokemodal10">Brukernavn</th>
                    <th i18n="@@app01Admin01FirmaSokemodal11">E-post</th>
                    <th i18n="@@app01Admin01FirmaSokemodal12">Firma</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let b of brukerkontoer" [class.text-danger]="b.Slettet">
                    <td><a [innerHTML]="b.Navn | highlight:sokttekst" (click)="openBrukerkonto(b.Id)"> </a></td>
                    <td [innerHTML]="b.Login | highlight:sokttekst"></td>
                    <td [innerHTML]="b.Epost | highlight:sokttekst"></td>
                    <td><a [innerHTML]="b.Firmanavn | highlight:sokttekst" (click)="openFirma(b.FirmaId)"></a></td>
                </tr>
            </tbody>
        </table>
    </div>

    <div *ngIf="brukergrupper != null &amp;&amp; brukergrupper.length > 0">
        <h4 i18n="@@app01Admin01FirmaSokemodal13">Brukergrupper</h4>
        <table class="table table-responsive table-bordered table-striped table-condensed table-hover">
            <thead>
                <tr>
                    <th i18n="@@app01Admin01FirmaSokemodal14">Navn</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let b of brukergrupper" [class.text-danger]="b.Slettet">
                    <td><a [innerHTML]="b.Navn | highlight:sokttekst" (click)="openBrukergruppe(b.Id)"></a></td>
                </tr>
            </tbody>
        </table>
    </div>

    <div *ngIf="kontrakter != null &amp;&amp; kontrakter.length > 0">
        <h4 i18n="@@app01Admin01FirmaSokemodal15">Kontrakter</h4>
        <table class="table table-responsive table-bordered table-striped table-condensed table-hover">
            <thead>
                <tr>
                    <th i18n="@@app01Admin01FirmaSokemodal16">Hovedprosjekt</th>
                    <th i18n="@@app01Admin01FirmaSokemodal17">Hovedkontrakt</th>
                    <th i18n="@@app01Admin01FirmaSokemodal18">Tittel</th>
                    <th i18n="@@app01Admin01FirmaSokemodal19">Kontraktsnr</th>
                    <th i18n="@@app01Admin01FirmaSokemodal20">Oppstart</th>
                    <th i18n="@@app01Admin01FirmaSokemodal21">Utløpsdato</th>
                    <th i18n="@@app01Admin01FirmaSokemodal22">Rolle</th>
                    <th i18n="@@app01Admin01FirmaSokemodal23">Brukerkonto</th>
                    <th i18n="@@app01Admin01FirmaSokemodal24">Brukergruppe</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let b of kontrakter" [class.text-danger]="b.Slettet">
                    <td><a [innerHTML]="b.Hovedprosjektnavn | highlight:sokttekst" (click)="openHovedprosjekt(b.HovedprosjektId)"></a></td>
                    <td [innerHTML]="b.Hovedkontraktnavn | highlight:sokttekst"></td>
                    <td [innerHTML]="b.Tittel | highlight:sokttekst"></td>
                    <td [innerHTML]="b.Kontraktsnr | highlight:sokttekst"></td>
                    <td [innerHTML]="b.Oppstart | datetime:false"></td>
                    <td [innerHTML]="b.Utlopsdato | datetime:false"></td>
                    <td [innerHTML]="b.Rolle"></td>
                    <td><a *ngIf="b.BrukerkontoId" [innerHTML]="b.BrukerkontoNavn | highlight:sokttekst" (click)="openBrukerkonto(b.BrukerkontoId)"></a></td>
                    <td><a *ngIf="b.BrukergruppeId" [innerHTML]="b.BrukergruppeNavn | highlight:sokttekst" (click)="openBrukergruppe(b.BrukergruppeId)"></a></td>

                </tr>
            </tbody>
        </table>
    </div>

    <div *ngIf="hovedkontrakter != null &amp;&amp; hovedkontrakter.length > 0">
        <h4 i18n="@@app01Admin01FirmaSokemodal25">Hovedkontrakter</h4>
        <table class="table table-responsive table-bordered table-striped table-condensed table-hover">
            <thead>
                <tr>
                    <th i18n="@@app01Admin01FirmaSokemodal26">Hovedprosjekt</th>
                    <th i18n="@@app01Admin01FirmaSokemodal27">Navn</th>
                    <th i18n="@@app01Admin01FirmaSokemodal28">Mappenavn i arkiv</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let b of hovedkontrakter" [class.text-danger]="b.Slettet">
                    <td><a [innerHTML]="b.Hovedprosjektnavn | highlight:sokttekst" (click)="openHovedprosjekt(b.HovedprosjektId)"></a></td>
                    <td [innerHTML]="b.Navn | highlight:sokttekst"></td>
                    <td [innerHTML]="b.Mappenavn"></td>
                </tr>
            </tbody>
        </table>
    </div>

</div>
<div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="modalRef.hide()" i18n="@@app01Admin01FirmaSokemodal5">Lukk</button>
</div>