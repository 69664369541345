import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ProsjektDto = /** @class */ (function () {
    function ProsjektDto() {
    }
    return ProsjektDto;
}());
export { ProsjektDto };
var RapportDto = /** @class */ (function () {
    function RapportDto() {
        this.Rapport = [];
    }
    return RapportDto;
}());
export { RapportDto };
var OppforingerDto = /** @class */ (function () {
    function OppforingerDto() {
    }
    return OppforingerDto;
}());
export { OppforingerDto };
var VisOppfølgingsKolonnerDto = /** @class */ (function () {
    function VisOppfølgingsKolonnerDto() {
    }
    return VisOppfølgingsKolonnerDto;
}());
export { VisOppfølgingsKolonnerDto };
var WorkingEncoder = /** @class */ (function () {
    function WorkingEncoder() {
    }
    WorkingEncoder.prototype.encodeKey = function (key) { return encodeURIComponent(key); };
    WorkingEncoder.prototype.encodeValue = function (value) { return encodeURIComponent(value); };
    WorkingEncoder.prototype.decodeKey = function (key) { return decodeURIComponent(key); };
    WorkingEncoder.prototype.decodeValue = function (value) { return decodeURIComponent(value); };
    return WorkingEncoder;
}());
export { WorkingEncoder };
var RapportOppforingerService = /** @class */ (function () {
    function RapportOppforingerService(http) {
        this.http = http;
        this.serviceUrl = environment.APIUrl + 'RapportOppforinger/';
    }
    RapportOppforingerService.prototype.addArray = function (params, key, value) {
        if (typeof value === "undefined" || value === null)
            return params;
        if (Array.isArray(value) && value.length === 0)
            return params;
        for (var i = 0; i < value.length; i++)
            params = params.append(key + "[]", value[i].toString());
        return params;
    };
    RapportOppforingerService.prototype.GetProsjekter = function () {
        return this.http.get(this.serviceUrl + "GetProsjekter", { withCredentials: true }).toPromise();
    };
    RapportOppforingerService.prototype.GetRapport = function (prosjektId) {
        var params = new HttpParams({ encoder: new WorkingEncoder() });
        params = params.set("prosjektId", prosjektId != null ? prosjektId.toString() : null);
        return this.http.get(this.serviceUrl + "GetRapport", { params: params, withCredentials: true }).toPromise();
    };
    RapportOppforingerService.prototype.GetRapportXlsx = function (prosjektId) {
        var params = new HttpParams({ encoder: new WorkingEncoder() });
        params = params.set("prosjektId", prosjektId != null ? prosjektId.toString() : null);
        return this.http.get(this.serviceUrl + "GetRapportXlsx", { params: params, withCredentials: true }).toPromise();
    };
    RapportOppforingerService.ngInjectableDef = i0.defineInjectable({ factory: function RapportOppforingerService_Factory() { return new RapportOppforingerService(i0.inject(i1.HttpClient)); }, token: RapportOppforingerService, providedIn: "root" });
    return RapportOppforingerService;
}());
export { RapportOppforingerService };
