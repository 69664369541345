import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'Retry',
    templateUrl: "./Retry.component.html"
})
export class RetryComponent {

    public Title: string = "Feil med tilkobling";
    public MessageLbl: string = "Beklager, det har oppst&aring;tt en feil med forbindelsen til internett. Vennligst kontroller at tilgang til internett er aktivert p&aring; enheten.";

    public OKFunc: () => void;
    public CancelFunc: () => void;

    constructor(private ModalRef: BsModalRef) { }

    public retry() {
        if (this.OKFunc) this.OKFunc();
        this.ModalRef.hide();
        return false;
    }
    public cancel() {
        if (this.CancelFunc) this.CancelFunc();
        this.ModalRef.hide();
    }
}
