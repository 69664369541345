<div class="slidein restrict loginpg" style="overflow: auto;">

    <div style="float:right;padding:6px;font-size:24px" (click)="cancel()">
        <span class="glyphicon glyphicon-remove"></span>
    </div>


    <div class="container">

        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-2"></div>
            <div class="col-lg-5 col-md-6 col-sm-8 col-xs-12 text-center">

                <img class="img-responsive logo" src="Images/BIM2share.png" alt="BIM2Share" style="display:inline-block" />

                <div class="spacer"></div>
                <div class="spacer"></div>

                <div class="alert alert-danger" *ngIf="ErrorMsg">{{ErrorMsg}}</div>

                <div class="jumbotron" [ng-busy]="busy">

                    <form *ngIf="OpenPane == 1" (submit)="login(username.value, password.value)">
                        <div class="input-group input-group-lg">
                            <div class="input-group-addon">
                                <span class="glyphicon glyphicon-user"></span>
                            </div>
                            <input type="text" class="form-control" placeholder="Brukernavn" i18n-placeholder="@@app90LoginLogin.component1" required="" autofocus="" #username="" />
                        </div>
                        <div class="spacer"></div>
                        <div class="input-group input-group-lg">
                            <div class="input-group-addon">
                                <span class="glyphicon glyphicon-lock"></span>
                            </div>
                            <input class="form-control" type="password" placeholder="Passord" i18n-placeholder="@@app90LoginLogin.component2" required="" #password="" />
                        </div>
                        <div class="spacer"></div>
                        <nav>
                            <button type="submit" class="btn btn-block btn-lg btn-primary">
                                <span class="glyphicon glyphicon-log-in"></span>
                                <span i18n="@@app90LoginLogin.component3">Logg inn</span>
                            </button>
                            <div class="spacer"></div>
                            <a (click)="OpenPane = 2">
                                <span i18n="@@app90LoginLogin.component4">Glemt passord?</span>
                            </a>
                        </nav>
                    </form>

                    <form *ngIf="OpenPane == 2" [ng-busy]="busy" (submit)="glemtpassord(forgotusername.value)">
                        <h2 i18n="@@app90LoginLogin.component5">Glemt passord?</h2>
                        <p i18n="@@app90LoginLogin.component6">Fyll inn ditt brukernavn nedenfor og få tilsendt nytt passord pr. e-post.</p>
                        <div class="panel-body">
                            <fieldset>
                                <div class="form-group">
                                    <div class="input-group input-group-lg">
                                        <span class="input-group-addon"><i class="glyphicon glyphicon-envelope color-blue"></i></span>
                                        <!--Brukernavn -->
                                        <input placeholder="Brukernavn" class="form-control" type="text" required="" #forgotusername="" autofocus="" />
                                    </div>
                                </div>
                                <div class="form-group">
                                    <button type="submit" class="btn btn-lg btn-primary btn-block" i18n="@@app90LoginLogin.component7">Send meg passord</button>
                                </div>
                            </fieldset>
                            <a class="TilbakeBtn btn-lg btn btn-default btn-block" i18n="@@app90LoginLogin.component8" (click)="OpenPane = 1">Tilbake til logg inn</a>
                        </div>
                    </form>

                    <form *ngIf="OpenPane == 3" [ng-busy]="busy" (submit)="oppdaterpassord(newpassword1.value, newpassword2.value)">
                        <h2 i18n="@@app90LoginLogin.component9">Oppdater passord</h2>
                        <p i18n="@@app90LoginLogin.component10">Passordet for denne brukerkontoen må oppdateres før man kan logge inn.</p>
                        <div class="panel-body">
                            <fieldset>
                                <div class="form-group">
                                    <input type="password" class="form-control input-lg" placeholder="Nytt passord" i18n-placeholder="@@app90LoginLogin.component11" #newpassword1="" autofocus="" />
                                </div>
                                <div class="form-group">
                                    <input type="password" class="form-control input-lg" placeholder="Gjenta nytt passord" i18n-placeholder="@@app90LoginLogin.component12" #newpassword2="" />
                                </div>
                                <div class="form-group">
                                    <button type="submit" class="btn btn-lg btn-primary btn-block">
                                        Oppdater passord
                                    </button>
                                </div>
                            </fieldset>
                            <a class="TilbakeBtn btn-lg btn btn-default btn-block" i18n="@@app90LoginLogin.component13" (click)="OpenPane = 1">Tilbake til logg inn</a>
                        </div>
                    </form>
                </div>


                <div class="spacer hidden-xs"></div>
                <div class="spacer hidden-xs"></div>
                <div class="spacer hidden-xs"></div>
                <table class="table table-striped table-hover" *ngIf="IsTest">
                    <thead>
                        <tr>
                            <th colspan="3" i18n="@@app90LoginLogin.component14">Testbrukere:</th>
                        </tr>
                    </thead>
                    <tbody style="cursor:pointer">
                        <tr *ngFor="let item of testbrukere" (click)="login(item.username, item.password)">
                            <th><span class="text-{{item.color}} glyphicon glyphicon-stop"></span> {{item.label}}</th>
                            <td>{{item.username}}</td>
                            <td>{{item.password}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-lg-4 col-md-3 col-sm-2"></div>
        </div>
    </div>

    <div style="display:none">
        <div data-control="Tekst_1" i18n="@@app90LoginLogin.component15">Det gjenstår felter som må fylles ut!</div>
        <div data-control="Tekst_2" i18n="@@app90LoginLogin.component16">Sørg for at du har tastet det nye passordet inn riktig i begge felter!</div>
    </div>
</div>