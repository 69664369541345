import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ProsjektDto = /** @class */ (function () {
    function ProsjektDto() {
    }
    return ProsjektDto;
}());
export { ProsjektDto };
var WorkingEncoder = /** @class */ (function () {
    function WorkingEncoder() {
    }
    WorkingEncoder.prototype.encodeKey = function (key) { return encodeURIComponent(key); };
    WorkingEncoder.prototype.encodeValue = function (value) { return encodeURIComponent(value); };
    WorkingEncoder.prototype.decodeKey = function (key) { return decodeURIComponent(key); };
    WorkingEncoder.prototype.decodeValue = function (value) { return decodeURIComponent(value); };
    return WorkingEncoder;
}());
export { WorkingEncoder };
var ProsjektadminOversiktService = /** @class */ (function () {
    function ProsjektadminOversiktService(http) {
        this.http = http;
        this.serviceUrl = environment.APIUrl + 'ProsjektadminOversikt/';
    }
    ProsjektadminOversiktService.prototype.addArray = function (params, key, value) {
        if (typeof value === "undefined" || value === null)
            return params;
        if (Array.isArray(value) && value.length === 0)
            return params;
        for (var i = 0; i < value.length; i++)
            params = params.append(key + "[]", value[i].toString());
        return params;
    };
    ProsjektadminOversiktService.prototype.Hent = function (sortering, reversert) {
        var params = new HttpParams({ encoder: new WorkingEncoder() });
        params = params.set("sortering", sortering != null ? sortering.toString() : null);
        params = params.set("reversert", reversert != null ? reversert.toString() : null);
        return this.http.get(this.serviceUrl + "Hent", { params: params, withCredentials: true }).toPromise();
    };
    ProsjektadminOversiktService.ngInjectableDef = i0.defineInjectable({ factory: function ProsjektadminOversiktService_Factory() { return new ProsjektadminOversiktService(i0.inject(i1.HttpClient)); }, token: ProsjektadminOversiktService, providedIn: "root" });
    return ProsjektadminOversiktService;
}());
export { ProsjektadminOversiktService };
