<div class="modal-header">
    <h4 class="modal-title" i18n="@@app01Admin02BrukergruppeKopierModal1">Kopier brukergruppe til et annet hovedprosjekt</h4>
</div>
<div class="modal-body" [ng-busy]="busy">


    <div *ngIf="data">
        <div class="form-group">
            <div class="input-group">
                <label i18n="@@app01Admin02BrukergruppeKopierModal2">Navn</label>
                <input type="text" [(ngModel)]="data.Navn" class="form-control" i18n-placeholder="@@app01Admin02BrukergruppeKopierModal3" placeholder="(oppgi nytt brukergruppenavn)" />
            </div>
        </div>

        <div class="form-group">
            <div class="input-group">
                <label i18n="@@app01Admin02BrukergruppeKopierModal4">Hovedprosjekt</label>
                <ng-select [items]="data.Hovedprosjekter" bindLabel="Navn" bindValue="Id" [multiple]="false" [clearable]="false" i18n-placeholder="@@app01Admin02BrukergruppeKopierModal5" placeholder="(velg hovedprosjekt)" [(ngModel)]="data.HovedprosjektId"></ng-select>
            </div>
        </div>

        <div class="form-group">
            <div class="input-group">
                <label i18n="@@app01Admin02BrukergruppeKopierModal6">Brukere</label>
                <table class="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th i18n="@@app01Admin02BrukergruppeKopierModal7">Navn</th>
                            <th i18n="@@app01Admin02BrukergruppeKopierModal8">Login</th>
                            <th i18n="@@app01Admin02BrukergruppeKopierModal9">Firma</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let b of data.Brukerkontoer">
                            <td>{{b.Navn}}</td>
                            <td>{{b.Login}}</td>
                            <td>{{b.Firma}}</td>
                            <td><input type="checkbox" [(ngModel)]="b.Valgt" i18n-title="@@app01Admin02BrukergruppeKopierModal10" title="Meld bruker inn i ny gruppe" /></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

</div>
<div class="modal-footer">
    <button class="btn btn-primary" (click)="kopier()">Opprett kopi av brukergruppe</button>
    <button type="button" class="btn btn-default" (click)="modalRef.hide()" i18n="@@app01Admin02BrukergruppeKopierModal11">Lukk</button>
</div>