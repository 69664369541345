﻿import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({ name: 'datetime' })
export class DatetimePipe extends DatePipe implements PipeTransform {
    transform(value: any, args?: any): any {
        if (value == null) return "-";
        return super.transform(value, "dd.MM.yyyy") + (args == null ? " kl. " + super.transform(value, "HH:mm") : "");
    }

}
