import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var HentKontrakterDto = /** @class */ (function () {
    function HentKontrakterDto() {
        this.Brukere = [];
        this.Roller = [];
        this.Kontrakter = [];
        this.PakrevdeRoller = [];
    }
    return HentKontrakterDto;
}());
export { HentKontrakterDto };
var LagreKontrakterDto = /** @class */ (function () {
    function LagreKontrakterDto() {
        this.Kontrakter = [];
    }
    return LagreKontrakterDto;
}());
export { LagreKontrakterDto };
var Select2Dto = /** @class */ (function () {
    function Select2Dto() {
        this.children = [];
    }
    return Select2Dto;
}());
export { Select2Dto };
var ChildDto = /** @class */ (function () {
    function ChildDto() {
    }
    return ChildDto;
}());
export { ChildDto };
var RolleDto = /** @class */ (function () {
    function RolleDto() {
    }
    return RolleDto;
}());
export { RolleDto };
var KontraktDto = /** @class */ (function () {
    function KontraktDto() {
    }
    return KontraktDto;
}());
export { KontraktDto };
var PakrevdeRollerDto = /** @class */ (function () {
    function PakrevdeRollerDto() {
        this.Roller = [];
    }
    return PakrevdeRollerDto;
}());
export { PakrevdeRollerDto };
var WorkingEncoder = /** @class */ (function () {
    function WorkingEncoder() {
    }
    WorkingEncoder.prototype.encodeKey = function (key) { return encodeURIComponent(key); };
    WorkingEncoder.prototype.encodeValue = function (value) { return encodeURIComponent(value); };
    WorkingEncoder.prototype.decodeKey = function (key) { return decodeURIComponent(key); };
    WorkingEncoder.prototype.decodeValue = function (value) { return decodeURIComponent(value); };
    return WorkingEncoder;
}());
export { WorkingEncoder };
var ProsjektadminKontrakterService = /** @class */ (function () {
    function ProsjektadminKontrakterService(http) {
        this.http = http;
        this.serviceUrl = environment.APIUrl + 'ProsjektadminKontrakter/';
    }
    ProsjektadminKontrakterService.prototype.addArray = function (params, key, value) {
        if (typeof value === "undefined" || value === null)
            return params;
        if (Array.isArray(value) && value.length === 0)
            return params;
        for (var i = 0; i < value.length; i++)
            params = params.append(key + "[]", value[i].toString());
        return params;
    };
    ProsjektadminKontrakterService.prototype.Hent = function (hovedkontraktId) {
        var params = new HttpParams({ encoder: new WorkingEncoder() });
        params = params.set("hovedkontraktId", hovedkontraktId != null ? hovedkontraktId.toString() : null);
        return this.http.get(this.serviceUrl + "Hent", { params: params, withCredentials: true }).toPromise();
    };
    ProsjektadminKontrakterService.prototype.Lagre = function (data) {
        return this.http.post(this.serviceUrl + "Lagre", data, { withCredentials: true }).toPromise();
    };
    ProsjektadminKontrakterService.ngInjectableDef = i0.defineInjectable({ factory: function ProsjektadminKontrakterService_Factory() { return new ProsjektadminKontrakterService(i0.inject(i1.HttpClient)); }, token: ProsjektadminKontrakterService, providedIn: "root" });
    return ProsjektadminKontrakterService;
}());
export { ProsjektadminKontrakterService };
