import * as tslib_1 from "tslib";
import { PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
var DateinputPipe = /** @class */ (function (_super) {
    tslib_1.__extends(DateinputPipe, _super);
    function DateinputPipe() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DateinputPipe.prototype.transform = function (value, args) {
        if (value == null)
            return "";
        return _super.prototype.transform.call(this, value, "yyyy-MM-dd");
    };
    return DateinputPipe;
}(DatePipe));
export { DateinputPipe };
