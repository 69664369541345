﻿// ********************************************************************
// ***                                                              ***
// ***            DO NOT MODIFY THIS FILE MANUALLY!                 ***
// *** This file was generated by the API2ANGULAR Service Generator ***
// ***                                                              ***
// ********************************************************************

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpParameterCodec } from '@angular/common/http';
import { environment } from '../../environments/environment';


export class HentFirmaDto {
    public Firma: FirmaDto;
    public Brukergrupper: BrukergruppeDto[] = [];
}

export class FirmaDto {
    public Id: number;
    public Navn: string;
    public Admin: boolean;
    public Brukerkontoer: BrukerkontoDto[] = [];
}

export class BrukergruppeDto {
    public Id: number;
    public Navn: string;
}

export class BrukerkontoDto {
    public Id: number;
    public Login: string;
    public Passord: string;
    public Navn: string;
    public Epost: string;
    public Epostvarsel: boolean;
    public KrevNyttPassord: boolean;
    public MottaEpostkvittering: boolean;
    public LoggPaViaByggeweblogin: boolean;
    public DagligEpostSamlerapport: boolean;
    public Opprettet: string;
    public Slettet: boolean;
    public InnloggetBruker: boolean;
    public Brukergrupper: number[] = [];
    public DagligVarslingUbehandlede: boolean;
}

export class WorkingEncoder implements HttpParameterCodec {
    encodeKey(key: string): string { return encodeURIComponent(key); }
    encodeValue(value: string): string { return encodeURIComponent(value); }
    decodeKey(key: string): string { return decodeURIComponent(key); }
    decodeValue(value: string): string { return decodeURIComponent(value); }
}

@Injectable({
   providedIn: "root"
})
export class AdminFirmaRedigerService {
    private serviceUrl = environment.APIUrl + 'AdminFirmaRediger/';

    constructor(private http: HttpClient) { } 

    private addArray(params: HttpParams, key: string, value: any): HttpParams {
        if (typeof value === "undefined" || value === null) return params;
        if (Array.isArray(value) && value.length === 0) return params;
        for (let i = 0; i < value.length; i++) params = params.append(key + "[]", value[i].toString());
        return params;
    }

    public Hent(id: number): Promise<HentFirmaDto> {
        let params = new HttpParams({ encoder: new WorkingEncoder() })
        params = params.set("id",id != null ? id.toString() : null);
        return <Promise<HentFirmaDto>>this.http.get(this.serviceUrl + "Hent", { params, withCredentials: true }).toPromise();
    }

    public Lagre(data: FirmaDto): Promise<number> {
        return <Promise<number>>this.http.post(this.serviceUrl + "Lagre", data, { withCredentials: true }).toPromise();
    }
}
