﻿import { Component, ViewChild, ElementRef } from '@angular/core';

import { ModalsService } from 'Angular/app/80 Modals/Service';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminProsessflytgruppeRedigerService, ProsessflytgruppeDto, ProsessflytDto } from 'Angular/app/99 Services/AdminProsessflytgruppeRediger.service';
import { Subscription } from 'rxjs';


@Component({
    selector: 'ProsessflytgruppeRediger',
    templateUrl: './Rediger.html',
    styleUrls: ["./Rediger.css"]
})

export class AdminProsessflytgruppeRedigerComponent {

    busy: any;
    sub: Subscription;
    visSlettede: boolean = false;
    gruppe: ProsessflytgruppeDto;
    sortering: number = 2;
    id: number;

    constructor(private service: AdminProsessflytgruppeRedigerService, private modalService: ModalsService, private router: Router, private route: ActivatedRoute) { }

    slettMelding: string;
    hentTilbakeMelding: string;

    @ViewChild("Text1") slettMeldingRef: ElementRef;
    @ViewChild("Text2") hentTilbakeMeldingRef: ElementRef;
    ngAfterViewInit() {
        this.slettMelding = $(this.slettMeldingRef.nativeElement).text();
        this.hentTilbakeMelding = $(this.hentTilbakeMeldingRef.nativeElement).text();
    }


    ngOnInit() {
        var that = this;
        this.sub = this.route.params.subscribe(params => {
            that.id = parseInt(params['id']);
            that.sortering = parseInt(params['sortering']);
            that.oppdater();
        });
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    oppdater() {
        var that = this;
        this.busy = this.service.Hent(that.id, that.sortering).then(result => {
            that.gruppe = result;
        }, fail => {
            that.modalService.Alert(fail.error.Message);
        });    
    }

    lagre() {
        var that = this;
        this.gruppe.Sortering = this.sortering;
        this.busy = that.service.Lagre(that.gruppe).then(result => {
            that.id = result.Id;
            that.gruppe = result;
            that.router.navigate(["Admin/Prosessflytgruppe/Rediger", result.Id, that.sortering], { replaceUrl: true });
        }, fail => {
            that.modalService.Alert(fail.error.Message);
        });
    }

    slettProsessflyt(i: number) {
        var prosessflyt = this.gruppe.Prosessflyter[i];
        var melding = prosessflyt.Slettet ? this.hentTilbakeMelding : this.slettMelding;
        var that = this;
        this.modalService.Confirm(melding, function () {
            that.busy = that.service.SlettProsessflyt(prosessflyt.Id, that.sortering).then(result => {
                that.gruppe = result;
            }, fail => {
                that.modalService.Alert(fail.error.Message);
            });
        });
    }

    redigerProsessflyt(id: number) {
        this.router.navigate(["Admin/Prosessflyt/Rediger", this.gruppe.Id, id]);
    }

    sort(col: number) {
        if (this.sortering == col)
            this.sortering = col * -1
        else
            this.sortering = col;

        this.router.navigate(["Admin/Prosessflytgruppe/Rediger", this.id, this.sortering], { replaceUrl: true });

        this.oppdater();
    }

    //slett(i: number) {
    //    var gruppe = this.grupper[i];
    //    var melding = gruppe.Slettet ? this.hentTilbakeMelding : this.slettMelding;
    //    var that = this;
    //    this.modalService.Confirm(melding, function () {
    //        that.service.Slett(gruppe.Id).then(result => {
    //            that.grupper = result;
    //        }, fail => {
    //            that.modalService.Alert(fail.error.Message);
    //        });
    //    })
    //}
}