/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./smartapp";
var styles_SmartappComponent = [];
var RenderType_SmartappComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SmartappComponent, data: {} });
export { RenderType_SmartappComponent as RenderType_SmartappComponent };
export function View_SmartappComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { app: 0 }), (_l()(), i0.ɵeld(1, 0, [[1, 0], ["app", 1]], null, 0, "div", [], null, null, null, null, null))], null, null); }
export function View_SmartappComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "smartapp", [], null, null, null, View_SmartappComponent_0, RenderType_SmartappComponent)), i0.ɵdid(1, 4374528, null, 0, i1.SmartappComponent, [i0.NgZone], null, null)], null, null); }
var SmartappComponentNgFactory = i0.ɵccf("smartapp", i1.SmartappComponent, View_SmartappComponent_Host_0, {}, {}, []);
export { SmartappComponentNgFactory as SmartappComponentNgFactory };
