<div>

    <div class="container" [ng-busy]="busy">
        <div class="panel panel-default">
            <div class="panel-heading">
                <h1 i18n="@@app02ProsjektadminOversikt1">Prosjekter</h1>
            </div>
            <table class="table table-striped table-bordered" *ngIf="prosjekter != null">
                <thead>
                    <tr class="sortable">
                        <th (click)="sort(1)" [class.sortrev]="sortering == 1 &amp;&amp; reversert"><span i18n="@@app02ProsjektadminOversikt2">Prosjektnr</span> <span class="glyphicon glyphicon-triangle-top" *ngIf="sortering == 1 &amp;&amp; !reversert"></span><span class="glyphicon glyphicon-triangle-bottom" *ngIf="sortering == 1 &amp;&amp; reversert"></span></th>
                        <th (click)="sort(2)" [class.sortrev]="sortering == 2 &amp;&amp; reversert"><span i18n="@@app02ProsjektadminOversikt3">Prosjektnr i byggeweb</span> <span class="glyphicon glyphicon-triangle-top" *ngIf="sortering == 2 &amp;&amp; !reversert"></span><span class="glyphicon glyphicon-triangle-bottom" *ngIf="sortering == 2 &amp;&amp; reversert"></span></th>
                        <th (click)="sort(3)" [class.sortrev]="sortering == 3 &amp;&amp; reversert"><span i18n="@@app02ProsjektadminOversikt4">Navn</span> <span class="glyphicon glyphicon-triangle-top" *ngIf="sortering == 3 &amp;&amp; !reversert"></span><span class="glyphicon glyphicon-triangle-bottom" *ngIf="sortering == 3 &amp;&amp; reversert"></span></th>
                        <th (click)="sort(4)" [class.sortrev]="sortering == 4 &amp;&amp; reversert"><span i18n="@@app02ProsjektadminOversikt5">Hovedkontrakter</span> <span class="glyphicon glyphicon-triangle-top" *ngIf="sortering == 4 &amp;&amp; !reversert"></span><span class="glyphicon glyphicon-triangle-bottom" *ngIf="sortering == 4 &amp;&amp; reversert"></span></th>
                        <th (click)="sort(5)" [class.sortrev]="sortering == 5 &amp;&amp; reversert"><span i18n="@@app02ProsjektadminOversikt6">Opprettet</span> <span class="glyphicon glyphicon-triangle-top" *ngIf="sortering == 5 &amp;&amp; !reversert"></span><span class="glyphicon glyphicon-triangle-bottom" *ngIf="sortering == 5 &amp;&amp; reversert"></span></th>
                        <th class="tight"></th>
                    </tr>
                </thead>
                <tbody *ngIf="prosjekter.length == 0">
                    <tr>
                        <td colspan="6"><span i18n="@@app02ProsjektadminOversikt7">(ingen)</span></td>
                    </tr>
                </tbody>
                <tbody *ngIf="prosjekter.length > 0">
                    <tr *ngFor="let p of prosjekter" tabindex="0" (dblclick)="rediger(p.Id)">
                        <td>{{p.Id}}</td>
                        <td>{{p.Prosjektnr}}</td>
                        <td>{{p.Navn}}</td>
                        <td>{{p.Hovedkontrakter}}</td>
                        <td>{{p.Opprettet | datetime}}</td>
                        <td class="nopadd tight"><button class="btn btn-default" (click)="rediger(p.Id)"><span class="glyphicon glyphicon-pencil"></span></button></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div style="display:none">
        <div #Tittel="" i18n="@@app02ProsjektadminOversikt8">BIM2Share - Prosjekter</div>
    </div>
</div>