<div autocomplete="off">

    <div class="container" [ng-busy]="busy">
        <div class="pull-right">
            <button id="LagreBtn" class="btn btn-success" (click)="lagre()" *ngIf="firma != null">
                <span class="glyphicon glyphicon-ok"></span>
                <span i18n="@@app01Admin01FirmaRediger1">Lagre</span>
            </button>
            &nbsp;
            <a onclick="return navback();" class="btn btn-default" tabindex="-1">
                <span class="glyphicon glyphicon-menu-left"></span>
                <span i18n="@@app01Admin01FirmaRediger2">Gå tilbake</span>
            </a>
        </div>
        <div *ngIf="firma != null">
            <h1 *ngIf="firma.Id == 0" i18n="@@app01Admin01FirmaRediger3">Opprett firma</h1>
            <h1 *ngIf="firma.Id != 0" i18n="@@app01Admin01FirmaRediger4">Rediger firma</h1>

            <div class="form-group">
                <label i18n="@@app01Admin01FirmaRediger5">Navn</label>
                <input class="form-control" name="Navn" autocomplete="off" style="max-width:24em" [(ngModel)]="firma.Navn" />
            </div>
            <div class="form-group">
                <label>
                    <input name="Admin" type="checkbox" [(ngModel)]="firma.Admin" />&nbsp;<span i18n="@@app01Admin01FirmaRediger6">Admin</span>
                </label>
            </div>
            <div *ngIf="firma.Id != 0">
                <hr />
                <h2 i18n="@@AdminAktorRediger5">Firmaets brukerkontoer</h2>
                <table class="table table-condensed table-bordered table-striped table-hover" style="width:auto">
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th i18n="@@app01Admin01FirmaRediger7">Brukernavn</th>
                            <th i18n="@@app01Admin01FirmaRediger8">Passord</th>
                            <th i18n="@@app01Admin01FirmaRediger9">Navn</th>
                            <th i18n="@@app01Admin01FirmaRediger10">E-post</th>
                            <th i18n="@@app01Admin01FirmaRediger13">Bruk byggeweb-bruker</th>
                            <th i18n="@@app01Admin01FirmaRediger11" title="Motta varsling om meldinger pr. e-post" i18n-title="@@AdminAktorRediger12">Varsel om ny oppføring</th>
                            <th i18n="@@app01Admin01FirmaRediger12">E-post kvittering</th>
                            <th i18n="@@app01Admin01FirmaRediger14">Motta daglig samlerapport</th>
                            <th i18n="@@app01Admin01FirmaRediger16">Motta daglig varsling om ubehandlede oppføringer</th>
                            <th i18n="@@app01Admin01FirmaRediger15">Opprettet</th>
                            <th i18n="@@app01Admin01FirmaRediger18" title="Krev at passord må byttes ved neste login" i18n-title="@@app01Admin01FirmaRediger19" class="nobr">*Pass</th>
                            <th class="tight" i18n="@@app01Admin01FirmaRediger20">Slett</th>
                            <th class="tight" i18n="@@app01Admin01FirmaRediger21">Rediger</th>
                            <th i18n="@@app01Admin01FirmaRediger22">Logg inn som</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="ingenBrukere()">
                        <tr>
                            <td colspan="100" i18n="@@app01Admin01FirmaRediger23">Ingen brukerkontoer</td>
                        </tr>
                    </tbody>

                    <tbody *ngIf="firma.Brukerkontoer.length > 0">
                        <tr *ngFor="let b of firma.Brukerkontoer; let i = index" tabindex="0" (dblclick)="redigerBruker(b.Id)" [class.hidden]="visSlettede ? false : b.Slettet" [class.text-danger]="b.Slettet">
                            <td class="padd">{{b.Id}}</td>
                            <td class="input"><div contenteditable="true" [(ngModel)]="b.Login" i18n-data-placeholder="@@app01Admin01FirmaRediger24" data-placeholder="(brukernavn)" [disabled]="b.Slettet"></div></td>
                            <td class="input"><input autocomplete="off" class="form-control" name="Passord" type="password" [(ngModel)]="b.Passord" i18n-placeholder="@@app01Admin01FirmaRediger25" placeholder="(passord)" [disabled]="b.Slettet" /></td>
                            <td class="input"><div contenteditable="true" [(ngModel)]="b.Navn" i18n-data-placeholder="@@app01Admin01FirmaRediger26" data-placeholder="(navn)" [disabled]="b.Slettet"></div></td>
                            <td class="input"><div contenteditable="true" [(ngModel)]="b.Epost" i18n-data-placeholder="@@app01Admin01FirmaRediger27" data-placeholder="(e-post)" [disabled]="b.Slettet"></div></td>
                            <td class="text-center"><input type="checkbox" [(ngModel)]="b.LoggPaViaByggeweblogin" [disabled]="b.Slettet" /></td>
                            <td class="text-center"><input type="checkbox" [(ngModel)]="b.Epostvarsel" [disabled]="b.Slettet" /></td>
                            <td class="text-center"><input type="checkbox" [(ngModel)]="b.MottaEpostkvittering" [disabled]="b.Slettet" /></td>
                            <td class="text-center"><input type="checkbox" [(ngModel)]="b.DagligEpostSamlerapport" [disabled]="b.Slettet" /></td>
                            <td class="text-center"><input type="checkbox" [(ngModel)]="b.DagligVarslingUbehandlede" [disabled]="b.Slettet" /></td>
                            <td class="padd">{{b.Opprettet | datetime}}</td>
                            <td class="text-center"><input [(ngModel)]="b.KrevNyttPassord" i18n-title="@@app01Admin01FirmaRediger28" title="Krev at passord må byttes ved neste login" type="checkbox" [disabled]="b.Slettet" /></td>
                            <td class="nopadd">
                                <button class="btn btn-default" (click)="slettBruker(i)" [disabled]="b.InnloggetBruker || b.Slettet" [attr.title]="b.InnloggetBruker ? EgenBrukerMelding : &#39;&#39;"><span class="glyphicon glyphicon-trash"></span></button>
                            </td>
                            <td class="nopadd">
                                <button class="btn btn-default" (click)="redigerBruker(b.Id)"><span class="glyphicon glyphicon-pencil"></span></button>
                            </td>
                            <td class="nopadd">
                                <button class="btn btn-default" (click)="loggInnSom(b.Id)"><span class="glyphicon glyphicon-log-in"></span></button>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <button class="btn btn-primary" (click)="redigerBruker(0)" i18n="@@app01Admin01FirmaRediger29">Legg til bruker</button>
                <br />
                <br />
                <p i18n="@@AdminAktorRediger27">*Pass - Kryss av for å kreve at passordet til brukeren må endres ved neste login.</p>
                <label>
                    <input type="checkbox" [(ngModel)]="visSlettede" />&nbsp;<span i18n="@@app01Admin01FirmaRediger30">Vis slettede brukerkontoer</span>
                </label>
            </div>

        </div>
    </div>

    <div style="display:none">
        <div #Tittel="" i18n="@@app01Admin01FirmaRediger31">BIM2Share - Rediger firma</div>
        <div #EgenBrukerMeldingTekst="" i18n="@@app01Admin01FirmaRediger32">Du kan ikke slette din egen bruker</div>
        <div #SlettBrukerConfirmTekst="" i18n="@@app01Admin01FirmaRediger33">Vil du slette valgt bruker?</div>
    </div>
</div>