﻿import { Component, ViewChild, ElementRef } from '@angular/core';

import { ModalsService } from '../../80 Modals/Service';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AdminBrukergruppeOversiktService, BrukergruppeDto, BrukerkontoDto, HovedprosjektDto } from '../../99 Services/AdminBrukergruppeOversikt.service';
import { BrukergruppeKopierComponent } from './KopierModal';
import { BsModalService } from 'ngx-bootstrap/modal';

declare let $: any;

@Component({
    selector: "brukergruppe-oversikt",
    templateUrl: "./Oversikt.html",
    styleUrls: ["./Oversikt.css"]
})
export class BrukergruppeOversiktComponent {

    constructor(private service: AdminBrukergruppeOversiktService, private modalService: ModalsService, private titleService: Title, private router: Router, private bsModalService: BsModalService) { }

    busy: any;
    slettede: boolean = false;
    visSlettedeBrukere: boolean = false;
    sortering: number = 2;
    reversert: boolean = false;
    brukergrupper: BrukergruppeDto[] = null;
    brukerkontoer: BrukerkontoDto[] = null;
    hovedprosjekter: HovedprosjektDto[] = null;

    select2options: Select2Options = {
        multiple: true,
        escapeMarkup: (markup: string) => { return markup },
        templateSelection: (object: Select2SelectionObject) => { return $("<span/>").html(object.text)},
        templateResult: (object: Select2SelectionObject) => { return $("<span/>").html(object.text) },
    };

    HentTilbakeMelding: string;
    SletteMelding: string;
        
    @ViewChild("Tittel") TittelRef: ElementRef;
    @ViewChild("HentTilbakeMeldingTekst") HentTilbakeMeldingRef: ElementRef;
    @ViewChild("SletteMelding") SletteMeldingRef: ElementRef;
        
    ngAfterViewInit() {
        this.titleService.setTitle($(this.TittelRef.nativeElement).text());
        this.HentTilbakeMelding = $(this.HentTilbakeMeldingRef.nativeElement).text();
        this.SletteMelding = $(this.SletteMeldingRef.nativeElement).text();
    }

    ngOnInit() {
        this.oppdater();
    }

    oppdater() {
        var that = this;
        this.busy = this.service.Hent(that.slettede, that.sortering, that.reversert)
            .then(res => {
                that.brukergrupper = res.Brukergrupper;
                that.brukerkontoer = res.Brukerkontoer;
                that.hovedprosjekter = res.Hovedprosjekter;
            }, fail => {
                that.modalService.Alert(fail.error.Message);
            });
    }

    sort(id: number) {
        if (this.sortering == id)
            this.reversert = !this.reversert;

        this.sortering = id;
        this.oppdater();
    }

    lagre() {
        var that = this;
        this.busy = this.service.Lagre(that.brukergrupper).then(res => {
            that.oppdater();
        }, fail => {
            that.modalService.Alert(fail.error.Message);
        });
    }

    hentBrukerkontoer(index: number) {
        return this.brukerkontoer.concat(this.brukergrupper[index].SlettedeBrukerkontoer);
    }

    nyGruppe() {
        this.router.navigate(["Admin/Brukergruppe/Rediger", 0]);
    }

    slettGruppe(index: number) {
        var that = this;
        this.modalService.Confirm(that.SletteMelding, function () {
            that.brukergrupper.splice(index, 1);
        });
    }

    hentTilbakeGruppe(index: number) {
        var that = this;
        this.modalService.Confirm(that.HentTilbakeMelding, function () {
            that.brukergrupper[index].Slettet = null;
        });
    }

    redigerGruppe(id: number) {
        this.router.navigate(["Admin/Brukergruppe/Rediger", id]);
    }

    kopierGruppe(id: number) {
        var that = this;
        this.bsModalService.show(BrukergruppeKopierComponent, {
            //class: "modal-xl",
            initialState: {
                id: id,
                okfunc: function (source) {
                    that.oppdater();
                }
            }
        });
    }

}
