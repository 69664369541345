import * as tslib_1 from "tslib";
import { PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
var DatetimeinputPipe = /** @class */ (function (_super) {
    tslib_1.__extends(DatetimeinputPipe, _super);
    function DatetimeinputPipe() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DatetimeinputPipe.prototype.transform = function (value, args) {
        if (value == null)
            return "";
        return _super.prototype.transform.call(this, value, "yyyy-MM-ddTHH:mm");
    };
    return DatetimeinputPipe;
}(DatePipe));
export { DatetimeinputPipe };
