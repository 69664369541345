﻿import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { from, Observable, throwError } from 'rxjs';
import { catchError, mergeMap, map } from 'rxjs/operators';
import { LoginComponent } from "../90 Login/Login.component";
import { RetryComponent } from "../90 Login/Retry.component";
import { VersionComponent } from "../90 Login/Version.component";
import { LanguageService } from './Language.service';

declare let window: any;

@Injectable()
export class SafeInterceptor implements HttpInterceptor {

    VERSION_ERROR_CODE = 444;
    VERSION_HEADER_NAME = "ngVersionstamp";
    VERSION_VARIABLE_KEY = "__versionstamp";

    constructor(private modalService: BsModalService, private languageService: LanguageService) { }

    public intercept(req: HttpRequest<any>, handler: HttpHandler): Observable<HttpEvent<any>> {

        if (!window[this.VERSION_VARIABLE_KEY]) {
            throw ("Versjonshåndtering er ikke konfigurert riktig. index.html mangler " +
                "<script>window.__versionstamp=\"versionstamp\";</script>");
        }

        req = req.clone({ headers: req.headers.set(this.VERSION_HEADER_NAME, window[this.VERSION_VARIABLE_KEY]) });

        //send the newly created request
        return handler.handle(req).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    const lang = event.headers.get("x-current-user-language");
                    this.languageService.updateLang(lang);
                }
                return event;
            }),

            catchError((error, caught) => {

            if (error instanceof HttpErrorResponse && error.status === this.VERSION_ERROR_CODE) {
                console.info("Nyeste versjon av Arbeidsflyt påkrevd!");
                return this.handleUpgrade(req, handler);
            } else if (error instanceof HttpErrorResponse && error.status === 401) {
                // Autentiseringsfeil -> login
                return this.handleLogin(req, handler);
            } else if (!(error instanceof HttpErrorResponse) || error.status !== 403) {
                // IKKE valideringsfeil -> retry
                return this.handleRetry(req, handler);
            } else {
                // Valideringsfeil
                return throwError(error);
            }
        }));
    }

    private handleLogin(req: HttpRequest<any>, handler: HttpHandler): Observable<HttpEvent<any>> {

        // Show login
        if (!window.LoginModal) {
            window.LoginModal = new Promise<any>((ok, fail) => {
                this.modalService.show(LoginComponent, {
                    backdrop: "static",
                    keyboard: false,
                    class: "logindialog",
                    animated: false,
                    initialState: {
                        OKFunc: ok,
                        CancelFunc: fail
                    }
                });
            });
        }

        window.LoginModal.then(close => {
            window.LoginModal = null;
        }, dismiss => {
            window.LoginModal = null;
        });

        return from(window.LoginModal).pipe(mergeMap(() => {
            return this.intercept(req, handler); // OK
        }), catchError((err) => {
            return throwError("Login dismissed by user");  // Cancel
        }));
    }

    private handleRetry(req: HttpRequest<any>, handler: HttpHandler): Observable<HttpEvent<any>> {

        // Show error / retry
        if (!window.RetryModal) {
            window.RetryModal = new Promise<any>((ok, fail) => {
                this.modalService.show(RetryComponent, {
                    backdrop: "static",
                    keyboard: false,
                    class: "retrydialog",
                    animated: false,
                    initialState: {
                        OKFunc: ok,
                        CancelFunc: fail
                    }
                });
            });
        }

        window.RetryModal.then(close => {
            window.RetryModal = null;
        }, dismiss => {
            window.RetryModal = null;
        });

        return from(window.RetryModal).pipe(mergeMap(() => {
            return this.intercept(req, handler); // OK
        }), catchError(() => {
            return throwError("Retry dismissed by user"); // Cancel
        }));
    }

    private handleUpgrade(req: HttpRequest<any>, handler: HttpHandler): Observable<HttpEvent<any>> {

        // Show oppgrader modal
        if (!window.UpdateModal) {
            window.UpdateModal = new Promise<any>((ok, fail) => {
                this.modalService.show(VersionComponent, {
                    backdrop: "static",
                    keyboard: false,
                    class: "versionmodal",
                    animated: false,
                    initialState: {
                        OKFunc: ok,
                        CancelFunc: fail
                    }
                });
            });
        }

        window.UpdateModal.then(close => {
            window.UpdateModal = null;
        }, dismiss => {
            window.UpdateModal = null;
        });

        return from(window.UpdateModal).pipe(mergeMap(() => {
            location.reload(true); // OK
            return throwError("Reloading");
        }), catchError(error => {
            return throwError("Update dismissed by user"); // Cancel
        }));
    }
}
