﻿import { Component, Input } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { ModalsService } from '../../80 Modals/Service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AdminBrukergruppeKopierModalService, KopierBrukergruppeDto } from '../../99 Services/AdminBrukergruppeKopierModal.service';

@Component({
    selector: "modal-content",
    templateUrl: "./KopierModal.html"
})
export class BrukergruppeKopierComponent {
    constructor(public modalRef: BsModalRef, private service: AdminBrukergruppeKopierModalService, private modalService: ModalsService, private router: Router, private ngxModalService: BsModalService) { }

    busy: any;
    @Input() id: number;
    @Input() okfunc: Function = null;

    data: KopierBrukergruppeDto = null;
    
    ngOnInit() {
        var that = this;
        this.busy = this.service.Hent(that.id).then(res => {
            that.data = res;
        }, fail => {
            that.modalService.Alert(fail.error.Message);
        });
    }

    kopier() {
        var that = this;
        this.busy = this.service.Kopier(that.data).then(res => {
            if (this.okfunc) this.okfunc();
            this.modalRef.hide();
        }, fail => {
            that.modalService.Alert(fail.error.Message);
        });
    }

    

}

