import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var HentHovedprosjektDto = /** @class */ (function () {
    function HentHovedprosjektDto() {
        this.Brukergrupper = [];
    }
    return HentHovedprosjektDto;
}());
export { HentHovedprosjektDto };
var HovedprosjektDto = /** @class */ (function () {
    function HovedprosjektDto() {
        this.Hovedkontrakter = [];
    }
    return HovedprosjektDto;
}());
export { HovedprosjektDto };
var HovedkontraktDto = /** @class */ (function () {
    function HovedkontraktDto() {
    }
    return HovedkontraktDto;
}());
export { HovedkontraktDto };
var BrukergruppeDto = /** @class */ (function () {
    function BrukergruppeDto() {
        this.Brukerkontoer = [];
    }
    return BrukergruppeDto;
}());
export { BrukergruppeDto };
var BrukerkontoDto = /** @class */ (function () {
    function BrukerkontoDto() {
        this.Hovedkontrakter = [];
    }
    return BrukerkontoDto;
}());
export { BrukerkontoDto };
var WorkingEncoder = /** @class */ (function () {
    function WorkingEncoder() {
    }
    WorkingEncoder.prototype.encodeKey = function (key) { return encodeURIComponent(key); };
    WorkingEncoder.prototype.encodeValue = function (value) { return encodeURIComponent(value); };
    WorkingEncoder.prototype.decodeKey = function (key) { return decodeURIComponent(key); };
    WorkingEncoder.prototype.decodeValue = function (value) { return decodeURIComponent(value); };
    return WorkingEncoder;
}());
export { WorkingEncoder };
var ProsjektadminRedigerService = /** @class */ (function () {
    function ProsjektadminRedigerService(http) {
        this.http = http;
        this.serviceUrl = environment.APIUrl + 'ProsjektadminRediger/';
    }
    ProsjektadminRedigerService.prototype.addArray = function (params, key, value) {
        if (typeof value === "undefined" || value === null)
            return params;
        if (Array.isArray(value) && value.length === 0)
            return params;
        for (var i = 0; i < value.length; i++)
            params = params.append(key + "[]", value[i].toString());
        return params;
    };
    ProsjektadminRedigerService.prototype.Hent = function (id) {
        var params = new HttpParams({ encoder: new WorkingEncoder() });
        params = params.set("id", id != null ? id.toString() : null);
        return this.http.get(this.serviceUrl + "Hent", { params: params, withCredentials: true }).toPromise();
    };
    ProsjektadminRedigerService.prototype.SlettBrukerkonto = function (id) {
        var params = new HttpParams({ encoder: new WorkingEncoder() });
        params = params.set("id", id != null ? id.toString() : null);
        return this.http.get(this.serviceUrl + "SlettBrukerkonto", { params: params, withCredentials: true }).toPromise();
    };
    ProsjektadminRedigerService.prototype.LastNedTilExcel = function (hovedprosjektId) {
        var params = new HttpParams({ encoder: new WorkingEncoder() });
        params = params.set("hovedprosjektId", hovedprosjektId.toString());
        window.open(this.serviceUrl + "LastNedTilExcel?" + params.toString());
    };
    ProsjektadminRedigerService.ngInjectableDef = i0.defineInjectable({ factory: function ProsjektadminRedigerService_Factory() { return new ProsjektadminRedigerService(i0.inject(i1.HttpClient)); }, token: ProsjektadminRedigerService, providedIn: "root" });
    return ProsjektadminRedigerService;
}());
export { ProsjektadminRedigerService };
