﻿import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';

import { ActivatedRoute, Router } from '@angular/router';
import { ModalsService } from '../../80 Modals/Service';
import { Title } from '@angular/platform-browser';
import { LoginService } from '../../99 Services/Login.service';
import { AdminBrukergruppeRedigerService, BrukergruppeDto, RolleDto, HovedkontraktDto, KontraktDto, BrukerkontoDto, HovedprosjektDto} from '../../99 Services/AdminBrukergruppeRediger.service';
import { forEach } from '@angular/router/src/utils/collection';
import { DateinputPipe } from '../../98 Shared/dateinput.pipe';

declare let $: any;

@Component({
    selector: "brukergruppe-rediger",
    templateUrl: "./Rediger.html",
    styleUrls: ["./Rediger.css"]
})
export class BrukergruppeRedigerComponent {

    constructor(
        private service: AdminBrukergruppeRedigerService,
        private route: ActivatedRoute,
        private modalService: ModalsService,
        private titleService: Title,
        private router: Router,
        private loginService: LoginService
    ) { }

    busy: any;
    sub: Subscription;
    brukergruppe: BrukergruppeDto = null
    hovedprosjekter: HovedprosjektDto[] = null;
    hovedkontrakter: HovedkontraktDto[] = null;
    roller: RolleDto[] = null
    visUtlopteKontrakter: boolean = false;
    brukerErAdmin: boolean = false;
    visSlettedeBrukere: boolean = false;

    brukergruppeId: number = null;
    firmaId: number = null;

    SletteKontraktMelding: string;
    @ViewChild("Tittel") TittelRef: ElementRef;
    @ViewChild("SletteKontraktMelding") SletteMeldingRef: ElementRef;

    ngAfterViewInit() {
        this.titleService.setTitle($(this.TittelRef.nativeElement).text());
        this.SletteKontraktMelding = $(this.SletteMeldingRef.nativeElement).text();
    }

    ngOnInit() {
        var that = this;
        this.sub = this.route.params.subscribe(params => {
            that.brukergruppeId = parseInt(params['brukergruppeId']);
            that.hent();
        });
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    hent() {
        var that = this;
        this.busy = this.service.Hent(that.brukergruppeId).then(res => {
            that.brukergruppe = res.Brukergruppe;
            that.hovedprosjekter = res.Hovedprosjekter;
            that.hovedkontrakter = res.Hovedkontrakter;
            that.brukerErAdmin = res.Admin;
        }, fail => {
            that.modalService.Alert(fail.error.Message);
        });
    }

    lagre() {
        var that = this;
        this.busy = this.service.Lagre(that.brukergruppe).then(res => {
            that.brukergruppeId = res.Brukergruppe.Id;
            that.brukergruppe = res.Brukergruppe;
            that.hovedprosjekter = res.Hovedprosjekter;
            that.hovedkontrakter = res.Hovedkontrakter;
            that.brukerErAdmin = res.Admin;
            if (res.Admin)
                this.router.navigate(["Admin/Brukergruppe/Rediger", res.Brukergruppe.Id], { replaceUrl: true });
        }, fail => {
            that.modalService.Alert(fail.error.Message);
        });
    }

    hentHovedprosjektDisabled() {
        return this.brukergruppe.Kontrakter.some(k => !k.Utlopt);
    }

    hovedkontraktChanged(index: number) {
        var kontrakt = this.brukergruppe.Kontrakter[index];
        var hovedkontrakt = this.hovedkontrakter.find(f => f.Id == kontrakt.HovedkontraktId);

        kontrakt.HovedkontraktKontraktsnr = hovedkontrakt.Kontraktsnr;
        kontrakt.HovedkontraktKontraktstittel = hovedkontrakt.Kontraktstittel;
        kontrakt.GjeldendeKontraktsnr = !kontrakt.Kontraktsnr ? kontrakt.HovedkontraktKontraktsnr : kontrakt.Kontraktsnr;
        kontrakt.GjeldendeTittel = !kontrakt.Tittel ? kontrakt.HovedkontraktKontraktstittel : kontrakt.Tittel;
        kontrakt.RolleId = null;
        kontrakt.Roller = [];
        hovedkontrakt.Roller.forEach(r => {
            var d = new RolleDto();
            d.Id = r.Id;
            d.Navn = r.Navn;
            kontrakt.Roller.push(d);
        });
    }

    nyKontrakt() {
        var kontrakt = new KontraktDto();
        kontrakt.Id = 0;
        //kontrakt.HovedprosjektId = this.hovedprosjekter[0].id;
        //kontrakt.Hovedkontrakter = this.hovedkontrakter.filter(k => k.HovedprosjektId == kontrakt.HovedprosjektId)
        //kontrakt.HovedkontraktKontraktsnr = kontrakt.Hovedkontrakter[0].Kontraktsnr;
        //kontrakt.HovedkontraktKontraktstittel = kontrakt.Hovedkontrakter[0].Kontraktstittel;
        //kontrakt.GjeldendeTittel = kontrakt.Hovedkontrakter[0].Kontraktstittel;
        //kontrakt.GjeldendeKontraktsnr = kontrakt.Hovedkontrakter[0].Kontraktsnr;
        //kontrakt.HovedkontraktId = kontrakt.Hovedkontrakter[0].id;
        kontrakt.HovedprosjektNavn = this.hovedprosjekter.find(f => f.Id == this.brukergruppe.HovedprosjektId).Navn;
        kontrakt.Hovedkontrakter = this.hovedkontrakter.filter(k => k.HovedprosjektId == this.brukergruppe.HovedprosjektId);
        kontrakt.Oppstart = new DateinputPipe("en-US").transform(new Date());
        //kontrakt.RolleId = this.roller[0].id;
        this.brukergruppe.Kontrakter.push(kontrakt);
    }

    ingenKontrakter() {
        return this.visUtlopteKontrakter && this.brukergruppe.Kontrakter.length == 0 || !this.visUtlopteKontrakter && this.brukergruppe.Kontrakter.filter(f => !f.Utlopt).length == 0
    }

    slettKontrakt(index: number) {
        var that = this;
        this.modalService.Confirm(that.SletteKontraktMelding, function () {
            var kontrakt = that.brukergruppe.Kontrakter[index];
            if (kontrakt.Id == 0) {
                that.brukergruppe.Kontrakter.splice(index, 1);
            } else {
                kontrakt.Utlopsdato = new DateinputPipe("en-US").transform(new Date());
                kontrakt.Utlopt = true;
            }
        });
    }
}