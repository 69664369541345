﻿// ********************************************************************
// ***                                                              ***
// ***            DO NOT MODIFY THIS FILE MANUALLY!                 ***
// *** This file was generated by the API2ANGULAR Service Generator ***
// ***                                                              ***
// ********************************************************************

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpParameterCodec } from '@angular/common/http';
import { environment } from '../../environments/environment';


export class DriftsmeldingDto {
    public Id: number;
    public Melding: string;
    public Opprettet: string;
    public VisFra: string;
    public VisTil: string;
    public Slettet: boolean;
    public Forhandsvisning: boolean;
}

export class WorkingEncoder implements HttpParameterCodec {
    encodeKey(key: string): string { return encodeURIComponent(key); }
    encodeValue(value: string): string { return encodeURIComponent(value); }
    decodeKey(key: string): string { return decodeURIComponent(key); }
    decodeValue(value: string): string { return decodeURIComponent(value); }
}

@Injectable({
   providedIn: "root"
})
export class AdminDriftsmeldingOversiktService {
    private serviceUrl = environment.APIUrl + 'AdminDriftsmeldingOversikt/';

    constructor(private http: HttpClient) { } 

    private addArray(params: HttpParams, key: string, value: any): HttpParams {
        if (typeof value === "undefined" || value === null) return params;
        if (Array.isArray(value) && value.length === 0) return params;
        for (let i = 0; i < value.length; i++) params = params.append(key + "[]", value[i].toString());
        return params;
    }

    public Hent(): Promise<DriftsmeldingDto[]> {
        return <Promise<DriftsmeldingDto[]>>this.http.get(this.serviceUrl + "Hent", { withCredentials: true }).toPromise();
    }

    public Lagre(data: DriftsmeldingDto[]): Promise<DriftsmeldingDto[]> {
        return <Promise<DriftsmeldingDto[]>>this.http.post(this.serviceUrl + "Lagre", data, { withCredentials: true }).toPromise();
    }
}
