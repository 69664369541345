import * as __NgCli_locale_1 from "@angular/common/locales/nb";
import * as __NgCli_locale_2 from "@angular/common";
__NgCli_locale_2.registerLocaleData(__NgCli_locale_1.default);
import { enableProdMode, LOCALE_ID, TRANSLATIONS, TRANSLATIONS_FORMAT } from '@angular/core';
import { environment } from './environments/environment';
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
var providers = [];
if (environment.production) {
    enableProdMode();
}
else {
    var selectedLanguage = localStorage.getItem("userLang");
    if (selectedLanguage) {
        console.log("Angular boot: Selected language:" + selectedLanguage);
        try {
            var translations = require("raw-loader!../Angular/messages." + selectedLanguage + ".xlf");
            console.log("Angular boot: Loaded translation file with length: ", translations.length);
            providers = [
                { provide: TRANSLATIONS, useValue: translations },
                { provide: TRANSLATIONS_FORMAT, useValue: 'xlf' },
                { provide: LOCALE_ID, useValue: selectedLanguage }
            ];
        }
        catch (_a) {
            console.error("Angular boot: Failed to load translations!");
        }
    }
}
__NgCli_bootstrap_2.platformBrowser().bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory, {
    providers: providers
}).catch(function (err) { return console.log(err); });
