import { ElementRef, OnDestroy, Renderer2 } from '@angular/core';
import { from, Subscription } from 'rxjs';
var NgBusyDirective = /** @class */ (function () {
    function NgBusyDirective(renderer, elementRef) {
        this.renderer = renderer;
        this.elementRef = elementRef;
        this.internalSubs = [];
    }
    Object.defineProperty(NgBusyDirective.prototype, "ngBusy", {
        set: function (input) {
            var _this = this;
            if (this.isPromise(input)) {
                input = from(input).subscribe();
            }
            if (input instanceof Subscription) {
                var sub_1 = input.add(function () {
                    var index = _this.internalSubs.indexOf(sub_1);
                    if (index !== -1)
                        _this.internalSubs.splice(index, 1);
                    _this.update();
                });
                this.internalSubs.push(sub_1);
            }
            this.update();
        },
        enumerable: true,
        configurable: true
    });
    NgBusyDirective.prototype.isPromise = function (value) {
        return value && typeof value.subscribe !== 'function' && typeof value.then === 'function';
    };
    NgBusyDirective.prototype.update = function () {
        if (!this.internalSubs.length)
            this.hide();
        else
            this.show();
    };
    NgBusyDirective.prototype.show = function () {
        this.renderer.addClass(this.elementRef.nativeElement, "busy-loader");
    };
    NgBusyDirective.prototype.hide = function () {
        this.renderer.removeClass(this.elementRef.nativeElement, "busy-loader");
    };
    NgBusyDirective.prototype.ngOnDestroy = function () {
        this.internalSubs.forEach(function (x) { return x.unsubscribe(); });
    };
    return NgBusyDirective;
}());
export { NgBusyDirective };
