<form (submit)="update()">
    <div class="modal-header">
        <h2 data-control="HeaderLbl" class="modal-title">
            Oppdatering
        </h2>
    </div>
    <div class="modal-body">
        <p style="font-size:16px;margin-bottom:0">
            En ny versjon av Arbeidsflyt er tilgjengelig <br /><br />
            For å forhindre uthenting/innsending av data i foreldede format er det påkrevd at man kjører siste versjon. <br /><br />
            Hvis du har endringer som ikke ble lagret kan åpne et nytt vindu med Arbeidsflyt, trykke 'Abryt' for denne meldingen, og kopiere over endringene til det nye vinduet. <br /><br />
        </p>
    </div>
    <div class="modal-footer">
        <button type="submit" class="btn btn-primary btn-lg btn-block" autofocus>
            <i class="fa fa-refresh"></i>
            <span>Hent nyeste version</span>
        </button>

        <button type="button" class="btn btn-default btn-lg btn-block" (click)="cancel()">
            <span class="glyphicon glyphicon-remove"></span>
            <span>Avbryt</span>
        </button>
    </div>
</form>