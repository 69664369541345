<div>

    <div class="container-fluid" [ng-busy]="busy">
        <div class="panel panel-default">
            <div class="panel-heading">
                <div class="pull-right">
                    <label style="margin-right:0.5em">
                        <input type="checkbox" [(ngModel)]="slettede" id="VisSlettede" (change)="oppdater()" />&nbsp;<span i18n="@@app01Admin02BrukergruppeOversikt1">Vis slettede brukergrupper</span>
                    </label>

                    <label style="margin-right:0.5em">
                        <input type="checkbox" [(ngModel)]="visSlettedeBrukere" />&nbsp;<span i18n="@@app01Admin02BrukergruppeOversikt25">Vis slettede gruppemedlemmer</span>
                    </label>

                    <button class="btn btn-primary" (click)="nyGruppe()" i18n="@@app01Admin02BrukergruppeOversikt11">Opprett ny</button>
                    &nbsp;
                    <button class="btn btn-success" (click)="lagre()">
                        <span i18n="@@app01Admin02BrukergruppeOversikt2">Lagre</span>
                    </button>
                    &nbsp;
                    <a onclick="return navback();" class="btn btn-default" tabindex="-1">
                        <span class="glyphicon glyphicon-menu-left"></span>
                        <span i18n="@@AdminAktorRediger1">Gå tilbake</span>
                    </a>
                </div>
                <h1 i18n="@@app01Admin02BrukergruppeOversikt3">Brukergrupper</h1>
            </div>
            <table class="table table-striped table-bordered" *ngIf="brukergrupper != null">
                <thead>
                    <tr class="sortable">
                        <th (click)="sort(1)" [class.sortrev]="sortering == 1 &amp;&amp; reversert"><span i18n="@@app01Admin02BrukergruppeOversikt4">Id</span> <span class="glyphicon glyphicon-triangle-top" *ngIf="sortering == 1 &amp;&amp; !reversert"></span><span class="glyphicon glyphicon-triangle-bottom" *ngIf="sortering == 1 &amp;&amp; reversert"></span></th>
                        <th (click)="sort(2)" [class.sortrev]="sortering == 2 &amp;&amp; reversert"><span i18n="@@app01Admin02BrukergruppeOversikt5">Navn</span> <span class="glyphicon glyphicon-triangle-top" *ngIf="sortering == 2 &amp;&amp; !reversert"></span><span class="glyphicon glyphicon-triangle-bottom" *ngIf="sortering == 2 &amp;&amp; reversert"></span></th>
                        <th (click)="sort(3)" [class.sortrev]="sortering == 3 &amp;&amp; reversert"><span i18n="@@app01Admin02BrukergruppeOversikt6">Opprettet</span> <span class="glyphicon glyphicon-triangle-top" *ngIf="sortering == 3 &amp;&amp; !reversert"></span><span class="glyphicon glyphicon-triangle-bottom" *ngIf="sortering == 3 &amp;&amp; reversert"></span></th>
                        <th *ngIf="slettede" (click)="sort(4)" [class.sortrev]="sortering == 4 &amp;&amp; reversert"><span i18n="@@app01Admin02BrukergruppeOversikt7">Slettet</span> <span class="glyphicon glyphicon-triangle-top" *ngIf="sortering == 4 &amp;&amp; !reversert"></span><span class="glyphicon glyphicon-triangle-bottom" *ngIf="sortering == 4 &amp;&amp; reversert"></span></th>

                        <th (click)="sort(6)" [class.sortrev]="sortering == 6 &amp;&amp; reversert"><span i18n="@@app01Admin02BrukergruppeOversikt15">Hovedprosjekt</span> <span class="glyphicon glyphicon-triangle-top" *ngIf="sortering == 6 &amp;&amp; !reversert"></span><span class="glyphicon glyphicon-triangle-bottom" *ngIf="sortering == 6 &amp;&amp; reversert"></span></th>
                        <th class="tight" i18n="@@app01Admin02BrukergruppeOversikt16">Hovedkontrakter</th>
                        <th (click)="sort(5)" [class.sortrev]="sortering == 5 &amp;&amp; reversert"><span i18n="@@app01Admin02BrukergruppeOversikt8">Kontrakter</span> <span class="glyphicon glyphicon-triangle-top" *ngIf="sortering == 5 &amp;&amp; !reversert"></span><span class="glyphicon glyphicon-triangle-bottom" *ngIf="sortering == 5 &amp;&amp; reversert"></span></th>

                        <th i18n="@@app01Admin02BrukergruppeOversikt9">Brukere</th>

                        <th></th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let gruppe of brukergrupper; let i = index" tabindex="0" [class.text-danger]="gruppe.Slettet != null" (dblclick)="redigerGruppe(gruppe.Id)">
                        <td>{{gruppe.Id}}</td>
                        <td class="input" style="max-width: 200px"><div contenteditable="true" [(ngModel)]="gruppe.Navn" i18n-data-placeholder="@@app01Admin02BrukergruppeOversikt10" data-placeholder="(navn)" [disabled]="gruppe.Slettet != null"></div></td>
                        <td>{{gruppe.Opprettet | datetime}}</td>
                        <td *ngIf="slettede">{{gruppe.Slettet | datetime}}</td>

                        <td><ng-select [items]="hovedprosjekter" bindLabel="Navn" bindValue="Id" [multiple]="false" [clearable]="false" i18n-placeholder="@@app01Admin02BrukergruppeOversikt17" placeholder="(velg hovedprosjekt)" [(ngModel)]="gruppe.HovedprosjektId" class="custom" [disabled]="!gruppe.KanBytteHovedprosjekt"></ng-select></td>
                        <td><span class="badge bg-default" *ngFor="let h of gruppe.Hovedkontrakter">{{h}}</span></td>
                        <td>
                            {{gruppe.Kontrakter}}
                            <label *ngIf="gruppe.Kontrakter > 0 &amp;&amp; gruppe.Brukerkontoer.length == 0" class="glyphicon glyphicon-alert" style="color: red" title="Ingen brukere i brukergruppen"></label>
                        </td>
                        
                        <td>
                            <ng-select #medlemer="" [items]="gruppe.BrukerkontoListe" bindLabel="Navn" bindValue="Id" class="gruppemedlemmer" [multiple]="true" [clearable]="true" i18n-placeholder="@@app01Admin02BrukergruppeOversikt18" placeholder="(velg brukere)" [(ngModel)]="gruppe.Brukerkontoer" [disabled]="gruppe.Slettet != null">
                                <ng-template ng-label-tmp="" let-item="item">
                                    <div *ngIf="item.Slettet == false || (item.Slettet &amp;&amp; visSlettedeBrukere)">
                                        <span aria-hidden="true" class="ng-value-icon left" (click)="medlemer.clearItem(item)">×</span>
                                        <span style="padding: 0 5px">
                                            <span *ngIf="item.Slettet" class="glyphicon glyphicon-warning-sign text-danger"></span>
                                            {{item.Navn}}
                                            <span *ngIf="item.Slettet" class="text-danger" i18n="@@app01Admin02BrukergruppeOversikt19">(slettet)</span>
                                        </span>
                                    </div>
                                </ng-template>
                                <ng-template ng-option-tmp="" let-item="item">

                                    <span *ngIf="item.Slettet" class="glyphicon glyphicon-warning-sign text-danger"></span>
                                    {{item.Navn}}
                                    <span *ngIf="item.Slettet" class="text-danger" i18n="@@app01Admin02BrukergruppeOversikt20">(slettet)</span>
                                </ng-template>

                            </ng-select>
                        </td>
                        
                        <td class="nopadd tight">
                            <button *ngIf="gruppe.Id != null" class="btn btn-default" (click)="kopierGruppe(gruppe.Id)" i18n-title="@@app01Admin02BrukergruppeOversikt21" title="Kopier brukergruppe til et annet hovedprosjekt"><span class="fa fa-files-o"></span></button>
                        </td>
                        
                        <td class="nopadd tight">
                            <button *ngIf="gruppe.Id != null" [disabled]="gruppe.Slettet != null" class="btn btn-default" (click)="redigerGruppe(gruppe.Id)" i18n-title="@@app01Admin02BrukergruppeOversikt22" title="Rediger brukergruppe"><span class="glyphicon glyphicon-pencil"></span></button>
                        </td>
                        <td class="nopadd tight">
                            <button *ngIf="gruppe.Slettet == null" class="btn btn-default" (click)="slettGruppe(i)"><span class="glyphicon glyphicon-trash" i18n-title="@@app01Admin02BrukergruppeOversikt23" title="Slett brukergruppe"></span></button>
                            <button *ngIf="gruppe.Slettet != null &amp;&amp; gruppe.Id != null" class="btn btn-default" (click)="hentTilbakeGruppe(i)" i18n-title="@@app01Admin02BrukergruppeOversikt24" title="Hent tilbake brukergruppe"><span class="fa fa-undo"></span></button>
                        </td>
                    </tr>

                </tbody>
            </table>
        </div>
        
    </div>

    <div style="display:none">
        <div #Tittel="" i18n="@@app01Admin02BrukergruppeOversikt12">BIM2Share - Brukergrupper</div>
        <div #HentTilbakeMeldingTekst="" i18n="@@app01Admin02BrukergruppeOversikt13">Vil du hente tilbake brukergruppen?</div>
        <div #SletteMelding="" i18n="@@app01Admin02BrukergruppeOversikt14">Vil du slette brukergruppen?</div>
    </div>
</div>