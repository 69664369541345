import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var WorkingEncoder = /** @class */ (function () {
    function WorkingEncoder() {
    }
    WorkingEncoder.prototype.encodeKey = function (key) { return encodeURIComponent(key); };
    WorkingEncoder.prototype.encodeValue = function (value) { return encodeURIComponent(value); };
    WorkingEncoder.prototype.decodeKey = function (key) { return decodeURIComponent(key); };
    WorkingEncoder.prototype.decodeValue = function (value) { return decodeURIComponent(value); };
    return WorkingEncoder;
}());
export { WorkingEncoder };
var LoginService = /** @class */ (function () {
    function LoginService(http) {
        this.http = http;
        this.serviceUrl = environment.APIUrl + 'Login/';
    }
    LoginService.prototype.addArray = function (params, key, value) {
        if (typeof value === "undefined" || value === null)
            return params;
        if (Array.isArray(value) && value.length === 0)
            return params;
        for (var i = 0; i < value.length; i++)
            params = params.append(key + "[]", value[i].toString());
        return params;
    };
    LoginService.prototype.Login = function (login) {
        var params = new HttpParams({ encoder: new WorkingEncoder() });
        params = params.set("login", login != null ? login.toString() : null);
        return this.http.get(this.serviceUrl + "Login", { params: params, withCredentials: true }).toPromise();
    };
    LoginService.prototype.Login2 = function (id) {
        var params = new HttpParams({ encoder: new WorkingEncoder() });
        params = params.set("id", id != null ? id.toString() : null);
        return this.http.get(this.serviceUrl + "Login2", { params: params, withCredentials: true }).toPromise();
    };
    LoginService.prototype.Ping = function () {
        return this.http.get(this.serviceUrl + "Ping", { withCredentials: true }).toPromise();
    };
    LoginService.prototype.OppdaterPassord = function (oldlogin, newlogin) {
        var params = new HttpParams({ encoder: new WorkingEncoder() });
        params = params.set("oldlogin", oldlogin != null ? oldlogin.toString() : null);
        params = params.set("newlogin", newlogin != null ? newlogin.toString() : null);
        return this.http.get(this.serviceUrl + "OppdaterPassord", { params: params, withCredentials: true }).toPromise();
    };
    LoginService.ngInjectableDef = i0.defineInjectable({ factory: function LoginService_Factory() { return new LoginService(i0.inject(i1.HttpClient)); }, token: LoginService, providedIn: "root" });
    return LoginService;
}());
export { LoginService };
