/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../98 Shared/datetime.pipe";
import * as i3 from "../../98 Shared/ng-busy.directive";
import * as i4 from "@angular/forms";
import * as i5 from "./Oversikt";
import * as i6 from "../../99 Services/AdminProsessflytgruppeOversikt.service";
import * as i7 from "../../80 Modals/Service";
import * as i8 from "@angular/router";
var styles_AdminProsessflytgruppeOversiktComponent = [];
var RenderType_AdminProsessflytgruppeOversiktComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AdminProsessflytgruppeOversiktComponent, data: {} });
export { RenderType_AdminProsessflytgruppeOversiktComponent as RenderType_AdminProsessflytgruppeOversiktComponent };
function View_AdminProsessflytgruppeOversiktComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "th", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.sort(4) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Slettet"])), (_l()(), i0.ɵeld(3, 0, null, null, 0, "span", [["class", "glyphicon glyphicon-triangle-top sortering"]], [[2, "show", null]], null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, "span", [["class", "glyphicon glyphicon-triangle-bottom sortering"]], [[2, "show", null]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.sortering == 4); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.sortering == (0 - 4)); _ck(_v, 4, 0, currVal_1); }); }
function View_AdminProsessflytgruppeOversiktComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""])), i0.ɵppd(2, 1)], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent.parent.parent.parent, 0), _v.parent.parent.context.$implicit.Slettet)); _ck(_v, 1, 0, currVal_0); }); }
function View_AdminProsessflytgruppeOversiktComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 14, "tr", [["tabindex", "0"]], [[2, "text-danger", null]], [[null, "dblclick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("dblclick" === en)) {
        var pd_0 = (_co.rediger(_v.parent.context.$implicit.Id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "td", [["class", "tight"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AdminProsessflytgruppeOversiktComponent_5)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(9, 0, null, null, 2, "td", [["class", "nopadd"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 1, "button", [["class", "btn btn-default"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.slett(_v.parent.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 0, "span", [["class", "glyphicon glyphicon-trash"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 2, "td", [["class", "nopadd"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 1, "button", [["class", "btn btn-default"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.rediger(_v.parent.context.$implicit.Id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 0, "span", [["class", "glyphicon glyphicon-pencil"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.visSlettede; _ck(_v, 8, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.Slettet; _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.Id; _ck(_v, 2, 0, currVal_1); var currVal_2 = _v.parent.context.$implicit.Navn; _ck(_v, 4, 0, currVal_2); var currVal_3 = _v.parent.context.$implicit.Prosessflyter; _ck(_v, 6, 0, currVal_3); }); }
function View_AdminProsessflytgruppeOversiktComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AdminProsessflytgruppeOversiktComponent_4)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_v.context.$implicit.Slettet || (_v.context.$implicit.Slettet && _co.visSlettede)); _ck(_v, 2, 0, currVal_0); }, null); }
function View_AdminProsessflytgruppeOversiktComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 24, "table", [["class", "table table-striped table-hover table-bordered"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 20, "thead", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 19, "tr", [["class", "sortable"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 4, "th", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.sort(1) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Id"])), (_l()(), i0.ɵeld(6, 0, null, null, 0, "span", [["class", "glyphicon glyphicon-triangle-top sortering"]], [[2, "show", null]], null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 0, "span", [["class", "glyphicon glyphicon-triangle-bottom sortering"]], [[2, "show", null]], null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 4, "th", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.sort(2) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Navn"])), (_l()(), i0.ɵeld(11, 0, null, null, 0, "span", [["class", "glyphicon glyphicon-triangle-top sortering"]], [[2, "show", null]], null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 0, "span", [["class", "glyphicon glyphicon-triangle-bottom sortering"]], [[2, "show", null]], null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 4, "th", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.sort(3) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Prosessflyter"])), (_l()(), i0.ɵeld(16, 0, null, null, 0, "span", [["class", "glyphicon glyphicon-triangle-top sortering"]], [[2, "show", null]], null, null, null, null)), (_l()(), i0.ɵeld(17, 0, null, null, 0, "span", [["class", "glyphicon glyphicon-triangle-bottom sortering"]], [[2, "show", null]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AdminProsessflytgruppeOversiktComponent_2)), i0.ɵdid(19, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(20, 0, null, null, 0, "th", [["class", "tight"]], null, null, null, null, null)), (_l()(), i0.ɵeld(21, 0, null, null, 0, "th", [["class", "tight"]], null, null, null, null, null)), (_l()(), i0.ɵeld(22, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AdminProsessflytgruppeOversiktComponent_3)), i0.ɵdid(24, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_6 = _co.visSlettede; _ck(_v, 19, 0, currVal_6); var currVal_7 = _co.grupper; _ck(_v, 24, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.sortering == 1); _ck(_v, 6, 0, currVal_0); var currVal_1 = (_co.sortering == (0 - 1)); _ck(_v, 7, 0, currVal_1); var currVal_2 = (_co.sortering == 2); _ck(_v, 11, 0, currVal_2); var currVal_3 = (_co.sortering == (0 - 2)); _ck(_v, 12, 0, currVal_3); var currVal_4 = (_co.sortering == 3); _ck(_v, 16, 0, currVal_4); var currVal_5 = (_co.sortering == (0 - 3)); _ck(_v, 17, 0, currVal_5); }); }
export function View_AdminProsessflytgruppeOversiktComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i2.DatetimePipe, [i0.LOCALE_ID]), i0.ɵqud(402653184, 1, { slettMeldingRef: 0 }), i0.ɵqud(402653184, 2, { hentTilbakeMeldingRef: 0 }), (_l()(), i0.ɵeld(3, 0, null, null, 28, "div", [["class", "container"]], null, null, null, null, null)), i0.ɵdid(4, 147456, null, 0, i3.NgBusyDirective, [i0.Renderer2, i0.ElementRef], { ngBusy: [0, "ngBusy"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 21, "div", [["class", "panel panel-default"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 18, "div", [["class", "panel-heading"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 15, "div", [["class", "pull-right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 9, "label", [["style", "margin-right:1em"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 5, "input", [["type", "checkbox"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i0.ɵnov(_v, 10).onChange($event.target.checked) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 10).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_co.visSlettede = $event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i0.ɵdid(10, 16384, null, 0, i4.CheckboxControlValueAccessor, [i0.Renderer2, i0.ElementRef], null, null), i0.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.CheckboxControlValueAccessor]), i0.ɵdid(12, 671744, null, 0, i4.NgModel, [[8, null], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i0.ɵdid(14, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null), (_l()(), i0.ɵted(-1, null, ["\u00A0"])), (_l()(), i0.ɵeld(16, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Vis slettede"])), (_l()(), i0.ɵeld(18, 0, null, null, 4, "button", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.rediger(0) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(19, 0, null, null, 0, "span", [["class", "glyphicon glyphicon-plus-sign"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00A0"])), (_l()(), i0.ɵeld(21, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Opprett ny"])), (_l()(), i0.ɵeld(23, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Prosessflytgrupper"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AdminProsessflytgruppeOversiktComponent_1)), i0.ɵdid(26, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(27, 0, null, null, 4, "div", [["style", "display:none"]], null, null, null, null, null)), (_l()(), i0.ɵeld(28, 0, [[1, 0], ["Text1", 1]], null, 1, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Vil du slette denne prosessflytgruppen?"])), (_l()(), i0.ɵeld(30, 0, [[2, 0], ["Text2", 1]], null, 1, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Vil du hente tilbake denne prosessflytgruppen?"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.busy; _ck(_v, 4, 0, currVal_0); var currVal_8 = _co.visSlettede; _ck(_v, 12, 0, currVal_8); var currVal_9 = _co.grupper; _ck(_v, 26, 0, currVal_9); }, function (_ck, _v) { var currVal_1 = i0.ɵnov(_v, 14).ngClassUntouched; var currVal_2 = i0.ɵnov(_v, 14).ngClassTouched; var currVal_3 = i0.ɵnov(_v, 14).ngClassPristine; var currVal_4 = i0.ɵnov(_v, 14).ngClassDirty; var currVal_5 = i0.ɵnov(_v, 14).ngClassValid; var currVal_6 = i0.ɵnov(_v, 14).ngClassInvalid; var currVal_7 = i0.ɵnov(_v, 14).ngClassPending; _ck(_v, 9, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_AdminProsessflytgruppeOversiktComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ProsessflytgruppeOversikt", [], null, null, null, View_AdminProsessflytgruppeOversiktComponent_0, RenderType_AdminProsessflytgruppeOversiktComponent)), i0.ɵdid(1, 4440064, null, 0, i5.AdminProsessflytgruppeOversiktComponent, [i6.AdminProsessflytgruppeOversiktService, i7.ModalsService, i8.Router, i8.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdminProsessflytgruppeOversiktComponentNgFactory = i0.ɵccf("ProsessflytgruppeOversikt", i5.AdminProsessflytgruppeOversiktComponent, View_AdminProsessflytgruppeOversiktComponent_Host_0, {}, {}, []);
export { AdminProsessflytgruppeOversiktComponentNgFactory as AdminProsessflytgruppeOversiktComponentNgFactory };
