import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var HentMenyDto = /** @class */ (function () {
    function HentMenyDto() {
        this.Hovedkontrakter = [];
    }
    return HentMenyDto;
}());
export { HentMenyDto };
var TellereDto = /** @class */ (function () {
    function TellereDto() {
        this.InnboksDelt = [];
        this.AlleDelt = [];
    }
    return TellereDto;
}());
export { TellereDto };
var LoginCommandResult = /** @class */ (function () {
    function LoginCommandResult() {
    }
    return LoginCommandResult;
}());
export { LoginCommandResult };
var HovedkontraktDto = /** @class */ (function () {
    function HovedkontraktDto() {
    }
    return HovedkontraktDto;
}());
export { HovedkontraktDto };
var WorkingEncoder = /** @class */ (function () {
    function WorkingEncoder() {
    }
    WorkingEncoder.prototype.encodeKey = function (key) { return encodeURIComponent(key); };
    WorkingEncoder.prototype.encodeValue = function (value) { return encodeURIComponent(value); };
    WorkingEncoder.prototype.decodeKey = function (key) { return decodeURIComponent(key); };
    WorkingEncoder.prototype.decodeValue = function (value) { return decodeURIComponent(value); };
    return WorkingEncoder;
}());
export { WorkingEncoder };
var MenuService = /** @class */ (function () {
    function MenuService(http) {
        this.http = http;
        this.serviceUrl = environment.APIUrl + 'Menu/';
    }
    MenuService.prototype.addArray = function (params, key, value) {
        if (typeof value === "undefined" || value === null)
            return params;
        if (Array.isArray(value) && value.length === 0)
            return params;
        for (var i = 0; i < value.length; i++)
            params = params.append(key + "[]", value[i].toString());
        return params;
    };
    MenuService.prototype.ChangeLanguage = function (lang) {
        var params = new HttpParams({ encoder: new WorkingEncoder() });
        params = params.set("lang", lang != null ? lang.toString() : null);
        return this.http.get(this.serviceUrl + "ChangeLanguage", { params: params, withCredentials: true }).toPromise();
    };
    MenuService.prototype.Hent = function (filterHovedkontraktId) {
        var params = new HttpParams({ encoder: new WorkingEncoder() });
        params = params.set("filterHovedkontraktId", filterHovedkontraktId != null ? filterHovedkontraktId.toString() : null);
        return this.http.get(this.serviceUrl + "Hent", { params: params, withCredentials: true }).toPromise();
    };
    MenuService.prototype.LogOut2 = function () {
        return this.http.get(this.serviceUrl + "LogOut2", { withCredentials: true }).toPromise();
    };
    MenuService.ngInjectableDef = i0.defineInjectable({ factory: function MenuService_Factory() { return new MenuService(i0.inject(i1.HttpClient)); }, token: MenuService, providedIn: "root" });
    return MenuService;
}());
export { MenuService };
