import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var HentBrukergruppeDto = /** @class */ (function () {
    function HentBrukergruppeDto() {
        this.Hovedkontrakter = [];
        this.Hovedprosjekter = [];
    }
    return HentBrukergruppeDto;
}());
export { HentBrukergruppeDto };
var BrukergruppeDto = /** @class */ (function () {
    function BrukergruppeDto() {
        this.Brukerkontoer = [];
        this.Kontrakter = [];
        this.BrukerkontoListe = [];
    }
    return BrukergruppeDto;
}());
export { BrukergruppeDto };
var HovedkontraktDto = /** @class */ (function () {
    function HovedkontraktDto() {
        this.Roller = [];
    }
    return HovedkontraktDto;
}());
export { HovedkontraktDto };
var RolleDto = /** @class */ (function () {
    function RolleDto() {
    }
    return RolleDto;
}());
export { RolleDto };
var HovedprosjektDto = /** @class */ (function () {
    function HovedprosjektDto() {
    }
    return HovedprosjektDto;
}());
export { HovedprosjektDto };
var KontraktDto = /** @class */ (function () {
    function KontraktDto() {
        this.Hovedkontrakter = [];
        this.Roller = [];
    }
    return KontraktDto;
}());
export { KontraktDto };
var BrukerkontoDto = /** @class */ (function () {
    function BrukerkontoDto() {
    }
    return BrukerkontoDto;
}());
export { BrukerkontoDto };
var WorkingEncoder = /** @class */ (function () {
    function WorkingEncoder() {
    }
    WorkingEncoder.prototype.encodeKey = function (key) { return encodeURIComponent(key); };
    WorkingEncoder.prototype.encodeValue = function (value) { return encodeURIComponent(value); };
    WorkingEncoder.prototype.decodeKey = function (key) { return decodeURIComponent(key); };
    WorkingEncoder.prototype.decodeValue = function (value) { return decodeURIComponent(value); };
    return WorkingEncoder;
}());
export { WorkingEncoder };
var AdminBrukergruppeRedigerService = /** @class */ (function () {
    function AdminBrukergruppeRedigerService(http) {
        this.http = http;
        this.serviceUrl = environment.APIUrl + 'AdminBrukergruppeRediger/';
    }
    AdminBrukergruppeRedigerService.prototype.addArray = function (params, key, value) {
        if (typeof value === "undefined" || value === null)
            return params;
        if (Array.isArray(value) && value.length === 0)
            return params;
        for (var i = 0; i < value.length; i++)
            params = params.append(key + "[]", value[i].toString());
        return params;
    };
    AdminBrukergruppeRedigerService.prototype.Hent = function (brukergruppeId) {
        var params = new HttpParams({ encoder: new WorkingEncoder() });
        params = params.set("brukergruppeId", brukergruppeId != null ? brukergruppeId.toString() : null);
        return this.http.get(this.serviceUrl + "Hent", { params: params, withCredentials: true }).toPromise();
    };
    AdminBrukergruppeRedigerService.prototype.Lagre = function (brukergruppe) {
        return this.http.post(this.serviceUrl + "Lagre", brukergruppe, { withCredentials: true }).toPromise();
    };
    AdminBrukergruppeRedigerService.ngInjectableDef = i0.defineInjectable({ factory: function AdminBrukergruppeRedigerService_Factory() { return new AdminBrukergruppeRedigerService(i0.inject(i1.HttpClient)); }, token: AdminBrukergruppeRedigerService, providedIn: "root" });
    return AdminBrukergruppeRedigerService;
}());
export { AdminBrukergruppeRedigerService };
