﻿import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'Version',
    templateUrl: "./Version.component.html"
})
export class VersionComponent {

    public OKFunc: () => void;
    public CancelFunc: () => void;

    constructor(private ModalRef: BsModalRef) { }

    public update() {
        if (this.OKFunc) this.OKFunc();
        this.ModalRef.hide();
        return false;
    }

    public cancel() {
        if (this.CancelFunc) this.CancelFunc();
        this.ModalRef.hide();
    }
}
