﻿import { Component, ViewChild, ElementRef } from '@angular/core';
import { ProsjektadminOversiktService, ProsjektDto } from '../99 Services/ProsjektadminOversikt.service';
import { ModalsService } from '../80 Modals/Service';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

declare let $: any;

@Component({
    selector: "prosjektadmin-oversikt",
    templateUrl: "./Oversikt.html",
    styleUrls: ["./Oversikt.css"]
})
export class ProsjektadminOversiktComponent {

    constructor(private service: ProsjektadminOversiktService, private modalService: ModalsService, private titleService: Title, private router: Router) { }

    busy: any;
    sortering: number = 3;
    reversert: boolean = false;
    prosjekter: ProsjektDto[] = null;

    @ViewChild("Tittel") TittelRef: ElementRef;
    
    ngAfterViewInit() {
        this.titleService.setTitle($(this.TittelRef.nativeElement).text());
    }

    ngOnInit() {
        this.oppdater();
    }

    oppdater() {
        var that = this;
        this.busy = this.service.Hent(that.sortering, that.reversert)
            .then(res => {
                that.prosjekter = res;
            }, fail => {
                that.modalService.Alert(fail.error.Message);
            });
    }

    sort(id: number) {
        if (this.sortering == id)
            this.reversert = !this.reversert;

        this.sortering = id;
        this.oppdater();
    }

    rediger(id: number) {
        var that = this;
        this.router.navigate(["Prosjektadmin/Rediger", id]);
    }
}
