﻿import { Component, NgZone, ViewChild, ElementRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LoginCommandResult, MenuService, HentMenyDto, HovedkontraktDto, TellereDto } from '../99 Services/Menu.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { PassordComponent } from './Passord';
import { LanguageService } from '../99 Services/Language.service';

declare let $: any;

@Component({
    selector: 'app-meny',
    templateUrl: './Meny.html',
})
export class MenyComponent {

    isTest: boolean;
    isLocal: boolean;
    tellere: TellereDto;
    bruker: LoginCommandResult;
    hovedkontrakter: HovedkontraktDto[];
    filterHovedkontraktId: number = 0;
    filtrerHovedkontraktKey: string;
    
    timer: any;
    loginListener: any;

    
    select2optionsSingle: Select2Options = {
        multiple: false,
        escapeMarkup: (markup: string) => { return markup },
        templateSelection: (object: Select2SelectionObject) => { return $("<span/>").html(object.text) },
        templateResult: (object: Select2SelectionObject) => { return $("<span/>").html(object.text) },
        theme: "bootstrap",
        dropdownAutoWidth: true,
        width: "auto"
    };

    constructor(private service: MenuService, private zone: NgZone, private router: Router, private modalService: BsModalService, private languageService: LanguageService) {
        this.isTest = window.location.href.indexOf("ikx.no") > 0 || window.location.href.indexOf("localhost") > 0;
    }


    ngOnInit() {

        let that = this;
        this.zone.runOutsideAngular(() => {

            let loginListener = function () {
                that.zone.run(() => {
                    that.oppdater();
                });
            };

            $(window).on("login", loginListener);

            that.loginListener = loginListener
        });

        

        this.oppdater();

        this.router.events.forEach((event) => {
            if (event instanceof NavigationEnd) {
                this.oppdater();
            }
        });
    }

    ngOnDestroy() {
        if (this.timer) clearTimeout(this.timer);
        if (this.loginListener) $(window).off("login", this.loginListener);
    }

    oppdater() {
        this.service.Hent(this.filterHovedkontraktId).then(res => {
            this.tellere = res.Tellere;
            this.bruker = res.Profil;
            window.localStorage.setItem("username", res.Profil.Brukernavn);

            this.filtrerHovedkontraktKey = res.Profil.Brukernavn + "filtrerHovedkontrakt";
            this.hovedkontrakter = res.Hovedkontrakter;

            if (res.Hovedkontrakter.length == 1) {
                this.filterHovedkontraktId = 0;
                window.localStorage.removeItem(this.filtrerHovedkontraktKey);
            } else {
                var filtrerHovedkontrakt = window.localStorage.getItem(this.filtrerHovedkontraktKey);
                if (!isNaN(+filtrerHovedkontrakt))
                    this.filterHovedkontraktId = +filtrerHovedkontrakt;
                else
                    this.filterHovedkontraktId = 0;
            }

            this.timer = setTimeout(() => {
                this.oppdater();
            }, 2 * 60 * 1000);

        }, fail => {
            if (this.timer) clearTimeout(this.timer);
            this.tellere = null;
            this.bruker = null;
        });
    }

    logut() {
        var that = this;
        if (this.bruker && this.bruker.Impersonating) {
            this.service.LogOut2().then(res => {
                this.oppdater();
                window.location.href = "#/";
            }, fail => {
                console.log(fail);
                });
        } else {
            window.location.replace("#/LogOut");
        }
    }

    selectLanguage(lang: string) {
        console.log("select language: " + lang);

        this.service.ChangeLanguage(lang).then(res => {
            this.languageService.updateLang(lang);
           

        }, fail => { });
    }

    changePassword() {
        this.modalService.show(PassordComponent);
    }

    hovedkontrakterChanged($event) {
        if (this.filterHovedkontraktId != $event.value) {
            this.filterHovedkontraktId = $event.value;
            window.localStorage.setItem(this.filtrerHovedkontraktKey, $event.value);
            this.oppdater();
            $(window).trigger("filterHovedkontraktChanged");
        }
    }
}
