import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var SokeresultatDto = /** @class */ (function () {
    function SokeresultatDto() {
        this.Firmaer = [];
        this.Brukerkontoer = [];
        this.Brukergrupper = [];
        this.Kontrakter = [];
        this.Hovedkontrakter = [];
    }
    return SokeresultatDto;
}());
export { SokeresultatDto };
var FirmaDto = /** @class */ (function () {
    function FirmaDto() {
    }
    return FirmaDto;
}());
export { FirmaDto };
var BrukerkontoDto = /** @class */ (function () {
    function BrukerkontoDto() {
    }
    return BrukerkontoDto;
}());
export { BrukerkontoDto };
var BrukergruppeDto = /** @class */ (function () {
    function BrukergruppeDto() {
    }
    return BrukergruppeDto;
}());
export { BrukergruppeDto };
var KontraktDto = /** @class */ (function () {
    function KontraktDto() {
    }
    return KontraktDto;
}());
export { KontraktDto };
var HovedkontraktDto = /** @class */ (function () {
    function HovedkontraktDto() {
    }
    return HovedkontraktDto;
}());
export { HovedkontraktDto };
var WorkingEncoder = /** @class */ (function () {
    function WorkingEncoder() {
    }
    WorkingEncoder.prototype.encodeKey = function (key) { return encodeURIComponent(key); };
    WorkingEncoder.prototype.encodeValue = function (value) { return encodeURIComponent(value); };
    WorkingEncoder.prototype.decodeKey = function (key) { return decodeURIComponent(key); };
    WorkingEncoder.prototype.decodeValue = function (value) { return decodeURIComponent(value); };
    return WorkingEncoder;
}());
export { WorkingEncoder };
var AdminFirmaSokemodalService = /** @class */ (function () {
    function AdminFirmaSokemodalService(http) {
        this.http = http;
        this.serviceUrl = environment.APIUrl + 'AdminFirmaSokemodal/';
    }
    AdminFirmaSokemodalService.prototype.addArray = function (params, key, value) {
        if (typeof value === "undefined" || value === null)
            return params;
        if (Array.isArray(value) && value.length === 0)
            return params;
        for (var i = 0; i < value.length; i++)
            params = params.append(key + "[]", value[i].toString());
        return params;
    };
    AdminFirmaSokemodalService.prototype.GetSearchResult = function (soketekst) {
        var params = new HttpParams({ encoder: new WorkingEncoder() });
        params = params.set("soketekst", soketekst != null ? soketekst.toString() : null);
        return this.http.get(this.serviceUrl + "GetSearchResult", { params: params, withCredentials: true }).toPromise();
    };
    AdminFirmaSokemodalService.ngInjectableDef = i0.defineInjectable({ factory: function AdminFirmaSokemodalService_Factory() { return new AdminFirmaSokemodalService(i0.inject(i1.HttpClient)); }, token: AdminFirmaSokemodalService, providedIn: "root" });
    return AdminFirmaSokemodalService;
}());
export { AdminFirmaSokemodalService };
