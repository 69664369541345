import { BsModalService } from 'ngx-bootstrap/modal';
import { AlertComponent } from './Alert';
import { ConfirmComponent } from './Confirm';
import * as i0 from "@angular/core";
import * as i1 from "ngx-bootstrap/modal/bs-modal.service";
var ModalsService = /** @class */ (function () {
    function ModalsService(service) {
        this.service = service;
    }
    ModalsService.prototype.Alert = function (msg, options) {
        var opts = Object.assign({}, AlertComponent.DefaultAlertOptions, options);
        this.service.show(AlertComponent, {
            initialState: {
                message: msg,
                options: opts
            }
        });
    };
    ModalsService.prototype.Confirm = function (msg, OKFunc, CancelFunc, options) {
        var opts = Object.assign({}, ConfirmComponent.DefaultConfirmOptions, options);
        this.service.show(ConfirmComponent, {
            initialState: {
                message: msg,
                okfunc: OKFunc,
                cancelfunc: CancelFunc,
                options: opts
            }
        });
    };
    ModalsService.ngInjectableDef = i0.defineInjectable({ factory: function ModalsService_Factory() { return new ModalsService(i0.inject(i1.BsModalService)); }, token: ModalsService, providedIn: "root" });
    return ModalsService;
}());
export { ModalsService };
