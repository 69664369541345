<div>

    <div class="container" [ng-busy]="busy">
        <div *ngFor="let melding of driftsmeldinger">
            <div class="alert alert-warning" *ngIf="melding.Forhandsvisning">
                <button type="button" class="close"><span aria-hidden="true" (click)="melding.Forhandsvisning = false">&times;</span></button>
                <strong>Driftsmelding: </strong><span [innerHTML]="melding.Melding | safeHtml"></span>
            </div>
        </div>

        <div class="panel panel-default">
            <div class="panel-heading">
                <div class="pull-right">
                    <label style="margin-right:0.5em">
                        <input type="checkbox" [(ngModel)]="visSlettede" />&nbsp;<span i18n="@@app01Admin05DriftsmeldingOversikt1">Vis utløpte driftsmeldinger</span>
                    </label>

                    <button class="btn btn-success" (click)="lagre()">
                        <span i18n="@@app01Admin05DriftsmeldingOversikt2">Lagre</span>
                    </button>
                    &nbsp;
                    <a onclick="return navback();" class="btn btn-default" tabindex="-1">
                        <span class="glyphicon glyphicon-menu-left"></span>
                        <span i18n="@@app01Admin05DriftsmeldingOversikt3">Gå tilbake</span>
                    </a>
                </div>
                <h1 i18n="@@app01Admin05DriftsmeldingOversikt4">Driftsmeldinger</h1>
            </div>
            <table class="table table-striped table-bordered" *ngIf="driftsmeldinger != null">
                <thead>
                    <tr>
                        <th i18n="@@app01Admin05DriftsmeldingOversikt5">Id</th>
                        <th i18n="@@app01Admin05DriftsmeldingOversikt6">Opprettet</th>
                        <th i18n="@@app01Admin05DriftsmeldingOversikt7">Melding</th>
                        <th i18n="@@app01Admin05DriftsmeldingOversikt8">Vis fra</th>
                        <th i18n="@@app01Admin05DriftsmeldingOversikt9">Vis til</th>
                        <th i18n="@@app01Admin05DriftsmeldingOversikt10">Forhåndsvisning</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let melding of driftsmeldinger; let i = index">
                        <tr *ngIf="!melding.Slettet || (visSlettede &amp;&amp; melding.Slettet)" [class.text-danger]="melding.Slettet">
                            <td class="tight">{{melding.Id}}</td>
                            <td class="tight">{{melding.Opprettet | datetime}}</td>
                            <td class="input" style="max-width: 200px"><div contenteditable="true" [(ngModel)]="melding.Melding" i18n-data-placeholder="@@app01Admin05DriftsmeldingOversikt11" data-placeholder="(melding)" [disabled]="melding.Slettet"></div></td>

                            <td class="tight"><input type="datetime-local" [(ngModel)]="melding.VisFra" [disabled]="melding.Slettet" /></td>
                            <td class="tight"><input type="datetime-local" [(ngModel)]="melding.VisTil" [disabled]="melding.Slettet" /></td>

                            <td class="tight">
                                <input type="checkbox" [(ngModel)]="melding.Forhandsvisning" (change)="preview(i)" title="Forhåndsvisning" i18n-title="@@app01Admin05DriftsmeldingOversikt12" [disabled]="melding.Slettet" />
                            </td>

                            <td class="nopadd tight">
                                <button *ngIf="!melding.Slettet" class="btn btn-default" (click)="slettMelding(i)"><span class="glyphicon glyphicon-trash" i18n-title="@@app01Admin05DriftsmeldingOversikt13" title="Slett driftsmelding"></span></button>
                            </td>

                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
        <button class="btn btn-primary" (click)="nyMelding()" i18n="@@app01Admin05DriftsmeldingOversikt14">Opprett ny driftsmelding</button>
    </div>

    <div style="display:none">
        <div #Tittel="" i18n="@@app01Admin05DriftsmeldingOversikt15">BIM2Share - Driftsmeldinger</div>
        <div #SletteMelding="" i18n="@@app01Admin05DriftsmeldingOversikt16">Vil du slette driftsmeldingen?</div>
    </div>
</div>