﻿// ********************************************************************
// ***                                                              ***
// ***            DO NOT MODIFY THIS FILE MANUALLY!                 ***
// *** This file was generated by the API2ANGULAR Service Generator ***
// ***                                                              ***
// ********************************************************************

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpParameterCodec } from '@angular/common/http';
import { environment } from '../../environments/environment';


export class CommandResult {
    public Message: string;
    public Success: boolean;
    public ResultCode: number;
}

export class WorkingEncoder implements HttpParameterCodec {
    encodeKey(key: string): string { return encodeURIComponent(key); }
    encodeValue(value: string): string { return encodeURIComponent(value); }
    decodeKey(key: string): string { return decodeURIComponent(key); }
    decodeValue(value: string): string { return decodeURIComponent(value); }
}

@Injectable({
   providedIn: "root"
})
export class BrukerPassordService {
    private serviceUrl = environment.APIUrl + 'BrukerPassord/';

    constructor(private http: HttpClient) { } 

    private addArray(params: HttpParams, key: string, value: any): HttpParams {
        if (typeof value === "undefined" || value === null) return params;
        if (Array.isArray(value) && value.length === 0) return params;
        for (let i = 0; i < value.length; i++) params = params.append(key + "[]", value[i].toString());
        return params;
    }

    public Hent(): Promise<CommandResult> {
        return <Promise<CommandResult>>this.http.get(this.serviceUrl + "Hent", { withCredentials: true }).toPromise();
    }

    public LagrePassord(gammeltpass: string, nyttpass: string, nyttpass2: string): Promise<CommandResult> {
        let params = new HttpParams({ encoder: new WorkingEncoder() })
        params = params.set("gammeltpass",gammeltpass != null ? gammeltpass.toString() : null);
        params = params.set("nyttpass",nyttpass != null ? nyttpass.toString() : null);
        params = params.set("nyttpass2",nyttpass2 != null ? nyttpass2.toString() : null);
        return <Promise<CommandResult>>this.http.get(this.serviceUrl + "LagrePassord", { params, withCredentials: true }).toPromise();
    }
}
