﻿import { Component, NgZone, ElementRef, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { environment } from "../environments/environment";

declare let $: any;

@Component({
    selector: 'smartapp',
    templateUrl: './smartapp.html',
})
export class SmartappComponent implements AfterViewInit, OnDestroy {

    @ViewChild('app') app: ElementRef;
    isDestroying: boolean = false;
    isInit: boolean = false;

    constructor(private zone: NgZone) { }

    ngAfterViewInit() {
        const that = this;
        this.zone.runOutsideAngular(() => {
            if (that.app.nativeElement && !that.isDestroying) {
                that.isInit = true;
                $(that.app.nativeElement).Edialog(environment.GoogleMapsAPIKey);
            }
        });
    }

    ngOnDestroy() {
        this.isDestroying = true;
        if (this.app.nativeElement && this.isInit)
            $(this.app.nativeElement).remove();
    }
}
