import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ProsessflytgruppeDto = /** @class */ (function () {
    function ProsessflytgruppeDto() {
        this.Prosessflyter = [];
    }
    return ProsessflytgruppeDto;
}());
export { ProsessflytgruppeDto };
var ProsessflytDto = /** @class */ (function () {
    function ProsessflytDto() {
        this.Kontraktstyper = [];
        this.Skjemaer = [];
    }
    return ProsessflytDto;
}());
export { ProsessflytDto };
var KontraktstypeDto = /** @class */ (function () {
    function KontraktstypeDto() {
    }
    return KontraktstypeDto;
}());
export { KontraktstypeDto };
var SkjemaDto = /** @class */ (function () {
    function SkjemaDto() {
    }
    return SkjemaDto;
}());
export { SkjemaDto };
var WorkingEncoder = /** @class */ (function () {
    function WorkingEncoder() {
    }
    WorkingEncoder.prototype.encodeKey = function (key) { return encodeURIComponent(key); };
    WorkingEncoder.prototype.encodeValue = function (value) { return encodeURIComponent(value); };
    WorkingEncoder.prototype.decodeKey = function (key) { return decodeURIComponent(key); };
    WorkingEncoder.prototype.decodeValue = function (value) { return decodeURIComponent(value); };
    return WorkingEncoder;
}());
export { WorkingEncoder };
var AdminProsessflytgruppeRedigerService = /** @class */ (function () {
    function AdminProsessflytgruppeRedigerService(http) {
        this.http = http;
        this.serviceUrl = environment.APIUrl + 'AdminProsessflytgruppeRediger/';
    }
    AdminProsessflytgruppeRedigerService.prototype.addArray = function (params, key, value) {
        if (typeof value === "undefined" || value === null)
            return params;
        if (Array.isArray(value) && value.length === 0)
            return params;
        for (var i = 0; i < value.length; i++)
            params = params.append(key + "[]", value[i].toString());
        return params;
    };
    AdminProsessflytgruppeRedigerService.prototype.Hent = function (id, sortering) {
        var params = new HttpParams({ encoder: new WorkingEncoder() });
        params = params.set("id", id != null ? id.toString() : null);
        params = params.set("sortering", sortering != null ? sortering.toString() : null);
        return this.http.get(this.serviceUrl + "Hent", { params: params, withCredentials: true }).toPromise();
    };
    AdminProsessflytgruppeRedigerService.prototype.Lagre = function (data) {
        return this.http.post(this.serviceUrl + "Lagre", data, { withCredentials: true }).toPromise();
    };
    AdminProsessflytgruppeRedigerService.prototype.SlettProsessflyt = function (id, sortering) {
        var params = new HttpParams({ encoder: new WorkingEncoder() });
        params = params.set("id", id != null ? id.toString() : null);
        params = params.set("sortering", sortering != null ? sortering.toString() : null);
        return this.http.get(this.serviceUrl + "SlettProsessflyt", { params: params, withCredentials: true }).toPromise();
    };
    AdminProsessflytgruppeRedigerService.ngInjectableDef = i0.defineInjectable({ factory: function AdminProsessflytgruppeRedigerService_Factory() { return new AdminProsessflytgruppeRedigerService(i0.inject(i1.HttpClient)); }, token: AdminProsessflytgruppeRedigerService, providedIn: "root" });
    return AdminProsessflytgruppeRedigerService;
}());
export { AdminProsessflytgruppeRedigerService };
