﻿import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AlertOptions, AlertComponent } from './Alert';
import { ConfirmOptions, ConfirmComponent } from './Confirm';

@Injectable({
    providedIn: "root"
})
export class ModalsService {

    constructor(private service: BsModalService) { }

    public Alert(msg: string, options?: AlertOptions) {

        let opts = Object.assign({}, AlertComponent.DefaultAlertOptions, options);

        this.service.show(AlertComponent, {
            initialState: {
                message: msg,
                options: opts
            }
        });
    }

    public Confirm(msg: string, OKFunc?: Function, CancelFunc?: Function, options?: ConfirmOptions) {

        let opts = Object.assign({}, ConfirmComponent.DefaultConfirmOptions, options);

        this.service.show(ConfirmComponent, {
            initialState: {
                message: msg,
                okfunc: OKFunc,
                cancelfunc: CancelFunc,
                options: opts
            }
        });
    }
}
