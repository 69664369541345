<div autocomplete="off">

    <div class="container" [ng-busy]="busy">
        <div class="pull-right">
            <button id="LagreBtn" class="btn btn-success" (click)="lagre()" *ngIf="brukergruppe != null">
                <span class="glyphicon glyphicon-ok"></span>
                <span i18n="@@app01Admin02BrukergruppeRediger1">Lagre</span>
            </button>
            &nbsp;
            <a onclick="return navback();" class="btn btn-default" tabindex="-1">
                <span class="glyphicon glyphicon-menu-left"></span>
                <span i18n="@@app01Admin02BrukergruppeRediger2">Gå tilbake</span>
            </a>
        </div>

        <div *ngIf="brukergruppe != null" autocomplete="off">
            <h1 i18n="@@app01Admin02BrukergruppeRediger3">Rediger brukergruppe</h1>

            <div class="row">
                <div class="col-lg-6 col-xs-12">
                    <div class="form-group">
                        <label i18n="@@app01Admin02BrukergruppeRediger4">Navn</label>
                        <input class="form-control" [(ngModel)]="brukergruppe.Navn" />
                    </div>
                </div>
                <div class="col-lg-6 col-xs-12" *ngIf="brukerErAdmin">
                    <div class="form-group">
                        <label i18n="@@app01Admin02BrukergruppeRediger20">Hovedprosjekt</label>
                        <ng-select [items]="hovedprosjekter" bindLabel="Navn" bindValue="Id" [multiple]="false" [clearable]="false" i18n-placeholder="@@app01Admin02BrukergruppeRediger21" placeholder="(velg hovedprosjekt)" [(ngModel)]="brukergruppe.HovedprosjektId" [disabled]="hentHovedprosjektDisabled()"></ng-select>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-xs-12">
                    <label i18n="@@app01Admin02BrukergruppeRediger5">Brukere</label>
                    <ng-select #medlemer="" [items]="brukergruppe.BrukerkontoListe" class="gruppemedlemmer" bindLabel="Navn" bindValue="Id" [multiple]="true" [clearable]="true" i18n-placeholder="@@app01Admin02BrukergruppeRediger22" placeholder="(velg brukere)" [(ngModel)]="brukergruppe.Brukerkontoer">
                        <ng-template ng-label-tmp="" let-item="item">
                            <div *ngIf="item.Slettet == false || (item.Slettet &amp;&amp; visSlettedeBrukere)">
                                <span aria-hidden="true" class="ng-value-icon left" (click)="medlemer.clearItem(item)">×</span>
                                <span style="padding: 0 5px">
                                    <span *ngIf="item.Slettet" class="glyphicon glyphicon-warning-sign text-danger"></span>
                                    {{item.Navn}}
                                    <span *ngIf="item.Slettet" class="text-danger" i18n="@@app01Admin02BrukergruppeRediger23">(slettet)</span>
                                </span>
                            </div>
                        </ng-template>
                        <ng-template ng-option-tmp="" let-item="item">

                            <span *ngIf="item.Slettet" class="glyphicon glyphicon-warning-sign text-danger"></span>
                            {{item.Navn}}
                            <span *ngIf="item.Slettet" class="text-danger" i18n="@@app01Admin02BrukergruppeRediger24">(slettet)</span>
                        </ng-template>

                    </ng-select>
                    <label style="margin-right:0.5em">
                        <input type="checkbox" [(ngModel)]="visSlettedeBrukere" />&nbsp;<span i18n="@@app01Admin02BrukergruppeRediger27">Vis slettede gruppemedlemmer</span>
                    </label>
                </div>
            </div>
            <div *ngIf="brukergruppe.Kontrakter != null &amp;&amp; brukergruppe.HovedprosjektId != null">
                <hr />
                <h2 i18n="@@app01Admin02BrukergruppeRediger6">Kontrakter</h2>
                <table class="table table-condensed table-bordered table-striped table-hover" style="width:auto">
                    <thead>
                        <tr>
                            <th i18n="@@app01Admin02BrukergruppeRediger7">Tittel</th>
                            <th i18n="@@app01Admin02BrukergruppeRediger8">Kontraktsnr</th>
                            <th i18n="@@app01Admin02BrukergruppeRediger9">Hovedprosjekt</th>
                            <th i18n="@@app01Admin02BrukergruppeRediger10">Hovedkontrakt</th>
                            <th i18n="@@app01Admin02BrukergruppeRediger11">Rolle</th>
                            <th i18n="@@app01Admin02BrukergruppeRediger12">Oppstart</th>
                            <th i18n="@@app01Admin02BrukergruppeRediger13">Utløpsdato</th>
                            <th i18n="@@app01Admin02BrukergruppeRediger14">Innboks</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody *ngIf="ingenKontrakter()">
                        <tr>
                            <td colspan="100" i18n="@@app01Admin02BrukergruppeRediger15">Ingen kontrakter</td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="brukergruppe.Kontrakter.length > 0">
                        <tr *ngFor="let k of brukergruppe.Kontrakter; let i = index" [class.text-danger]="k.Utlopt" [class.hidden]="k.Utlopt &amp;&amp; !visUtlopteKontrakter || (k.BrukergruppeId != null &amp;&amp; (brukergruppe.Brukergrupper == null || brukergruppe.Brukergrupper.indexOf(k.BrukergruppeId) == -1))">
                            <td class="input" [class.hidden]="!brukerErAdmin"><div contenteditable="true" [(ngModel)]="k.Tittel" [attr.data-placeholder]="k.HovedkontraktKontraktstittel" [disabled]="k.Utlopt"></div></td>
                            <td class="padd" [class.hidden]="brukerErAdmin">{{k.GjeldendeTittel}}</td>
                            <td class="input" [class.hidden]="!brukerErAdmin"><div contenteditable="true" [(ngModel)]="k.Kontraktsnr" [attr.data-placeholder]="k.HovedkontraktKontraktsnr" [disabled]="k.Utlopt"></div></td>
                            <td class="padd" [class.hidden]="brukerErAdmin">{{k.GjeldendeKontraktsnr}}</td>
                            <td class="padd">{{k.HovedprosjektNavn}}</td>
                            <td><ng-select [items]="k.Hovedkontrakter" bindLabel="Navn" bindValue="Id" [multiple]="false" [clearable]="false" i18n-placeholder="@@app01Admin02BrukergruppeRediger25" placeholder="(velg hovedkontrakt)" [(ngModel)]="k.HovedkontraktId" class="custom" (change)="hovedkontraktChanged(i)" [disabled]="k.Utlopt"></ng-select></td>
                            <td><ng-select [items]="k.Roller" bindLabel="Navn" bindValue="Id" [multiple]="false" [clearable]="false" i18n-placeholder="@@app01Admin02BrukergruppeRediger26" placeholder="(velg rolle)" [(ngModel)]="k.RolleId" class="custom" [disabled]="k.Utlopt"></ng-select></td>
                            <td><input class="form-control input-sm" type="date" [(ngModel)]="k.Oppstart" [disabled]="k.Utlopt" /></td>
                            <td><input class="form-control input-sm" type="date" [(ngModel)]="k.Utlopsdato" [disabled]="k.Utlopt" /></td>
                            <td class="padd">{{k.Innboks}}</td>
                            <td class="nopadd"><button *ngIf="!k.Utlopt" class="btn btn-default" (click)="slettKontrakt(i)"><span class="glyphicon glyphicon-trash"></span></button></td>
                        </tr>
                    </tbody>
                </table>
                <label>
                    <input type="checkbox" [(ngModel)]="visUtlopteKontrakter" />&nbsp;<span i18n="@@app01Admin02BrukergruppeRediger16">Vis utløpte kontrakter</span>
                </label>
                <br />
                <button class="btn btn-primary" (click)="nyKontrakt()"><span i18n="@@app01Admin02BrukergruppeRediger17">Legg til kontrakt</span></button>


            </div>

        </div>

    </div>

    <div style="display:none">
        <div #Tittel="" i18n="@@app01Admin02BrukergruppeRediger18">BIM2Share - Rediger brukergruppe</div>
        <div #SletteKontraktMelding="" i18n="@@app01Admin02BrukergruppeRediger19">Vil du slette kontrakten?</div>
    </div>
</div>