﻿// ********************************************************************
// ***                                                              ***
// ***            DO NOT MODIFY THIS FILE MANUALLY!                 ***
// *** This file was generated by the API2ANGULAR Service Generator ***
// ***                                                              ***
// ********************************************************************

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpParameterCodec } from '@angular/common/http';
import { environment } from '../../environments/environment';


export class FirmaDto {
    public Id: number;
    public Navn: string;
    public Admin: string;
    public Brukere: number;
    public Slettet: string;
    public InnloggetBrukersFirma: boolean;
}

export class WorkingEncoder implements HttpParameterCodec {
    encodeKey(key: string): string { return encodeURIComponent(key); }
    encodeValue(value: string): string { return encodeURIComponent(value); }
    decodeKey(key: string): string { return decodeURIComponent(key); }
    decodeValue(value: string): string { return decodeURIComponent(value); }
}

@Injectable({
   providedIn: "root"
})
export class AdminFirmaOversiktService {
    private serviceUrl = environment.APIUrl + 'AdminFirmaOversikt/';

    constructor(private http: HttpClient) { } 

    private addArray(params: HttpParams, key: string, value: any): HttpParams {
        if (typeof value === "undefined" || value === null) return params;
        if (Array.isArray(value) && value.length === 0) return params;
        for (let i = 0; i < value.length; i++) params = params.append(key + "[]", value[i].toString());
        return params;
    }

    public HentOversikt(slettede: boolean, sortering: number, reversert: boolean): Promise<FirmaDto[]> {
        let params = new HttpParams({ encoder: new WorkingEncoder() })
        params = params.set("slettede",slettede != null ? slettede.toString() : null);
        params = params.set("sortering",sortering != null ? sortering.toString() : null);
        params = params.set("reversert",reversert != null ? reversert.toString() : null);
        return <Promise<FirmaDto[]>>this.http.get(this.serviceUrl + "HentOversikt", { params, withCredentials: true }).toPromise();
    }

    public Slett(id: number): Promise<boolean> {
        let params = new HttpParams({ encoder: new WorkingEncoder() })
        params = params.set("id",id != null ? id.toString() : null);
        return <Promise<boolean>>this.http.get(this.serviceUrl + "Slett", { params, withCredentials: true }).toPromise();
    }
}
